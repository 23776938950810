import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { SplitButton } from "@onlinesales-ai/button-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { EmptyMessage } from "@onlinesales-ai/message-card-v2";

import Comment from "./comment";

import "./index.less";

const CommentWidget = ({
  onCommentSubmit,
  hasMarkdownSupport,
  userInfo,
  comments,
  containerClass,
  wobbleClass,
  showConfirmationModal,
  resetConfirmationModal,
  buttonToShow,
  buttonConfig,
  title,
  labelConfig,
  emptyMessage,
}) => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState(() =>
    buttonToShow.find((b) => buttonConfig[b]?.isDefault),
  );
  const [textAreaText, setTextAreaText] = useState("");
  const [showError, setShowError] = useState(false);
  const [isCommentPost, setIsCommnetPost] = useState(false);
  const wrapperRef = useRef();

  const hasError = useMemo(() => {
    return !textAreaText;
  }, [textAreaText]);

  const setSelectedOptionFromOutside = ({ selectedOption: selectedOptionNew }) => {
    setSelectedOption(selectedOptionNew);
  };

  useEffect(() => {
    PlatformEventManager.on("SET_COMMENT_OPTION", setSelectedOptionFromOutside);
    return () => {
      PlatformEventManager.off("SET_COMMENT_OPTION", setSelectedOptionFromOutside);
    };
  }, []);

  const scrollToLast = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToLast();
  }, [comments]);

  const onTextAreaTextChange = (event) => {
    setTextAreaText(event.target.value);
  };

  const submit = async (selected) => {
    setIsCommnetPost(true);
    try {
      await onCommentSubmit({
        selected,
        commentDetails: {
          message: textAreaText,
          attachments: [],
        },
      });
      setShowError(false);
      setTextAreaText("");
      setShowError(false);
      if (selected !== "COMMENT") {
        setSelectedOption("COMMENT");
      }
    } catch (err) {}
    setIsCommnetPost(false);
  };

  const onSubmit = async (selected) => {
    if (hasError) {
      setShowError(true);
      return false;
    }

    if (buttonConfig[selected]?.confirmation) {
      showConfirmationModal({
        isShow: true,
        title: buttonConfig[selected]?.configrmationMessage,
        rightBtnText: "Cancel",
        actionBtnText: "Yes",
        actionBtnCallback: () => {
          resetConfirmationModal();
          submit(selected);
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      submit(selected);
    }
  };

  return (
    <div className={`ott-comments-widget-wrapper ${containerClass}`}>
      {title && <div className="title">
        <Trans>
          {title}
        </Trans>
        </div>}
      <div ref={wrapperRef} className="comment-info-wrapper">
        {comments.length ? (
          comments?.map?.((props) => (
            <Comment
              {...props}
              userInfo={userInfo}
              hasMarkdownSupport={hasMarkdownSupport}
              labelConfig={labelConfig}
            />
          ))
        ) : (
          <EmptyMessage>
            <div>
              <Trans>{emptyMessage}</Trans>
            </div>
          </EmptyMessage>
        )}
      </div>
      <div className="input-container cta-container text-center is-sticky-footer">
        <div className="submit-input-wrapper">
          <textarea
            rows={2}
            disabled={isCommentPost}
            placeholder={t("Enter comment here")}
            className={`form-control ${showError && hasError ? "error-control" : ""}`}
            value={textAreaText}
            onChange={onTextAreaTextChange}
          />
          <SplitButton
            onClick={onSubmit}
            containerClass={wobbleClass}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isLoading={isCommentPost}
            disabled={isCommentPost}
            optionsToShow={buttonToShow}
            config={buttonConfig}
          />
        </div>
      </div>
    </div>
  );
};

CommentWidget.defaultProps = {
  onCommentSubmit: () => {},
  userInfo: {},
  comments: [],
  ctaText: "Submit",
  intercomEventPrefix: "",
  emptyMessage: "No Comments yet",
};

export default OSHOCWithUtilities(CommentWidget);
