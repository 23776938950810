import React, { useEffect, useMemo, useState } from "react";
import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";

import WithTooltip from "@onlinesales-ai/tooltip-v2";

import { Trans } from "react-i18next";

import "./SplitButton.less";

const SplitButtonWrapper = React.forwardRef(({
  selectedOption,
  onClick,
  optionsToShow,
  config,
  onItemClicked,
  isLoading,
  setSelectedOption,
  disabled,
  containerClass,
}, ref) => {
  const { cta, className } = useMemo(() => {
    return config[selectedOption];
  }, [selectedOption]);

  const onClickItem = (newValue) => {
    onItemClicked(newValue);
    setSelectedOption(newValue);
  };

  return (
    <SplitButton
      disabled={disabled}
      title={<span>{isLoading && <i className="icon icon-spinner icon-spin mr-2" /> }<Trans>{cta}</Trans></span>}
      className={`${containerClass} split-button-group ${className} ${optionsToShow.length === 1 ? "no-options" : ""}`}
      onClick={() => { onClick(selectedOption); }}
      noOptionClassName
      ref={ref}
    >
      {optionsToShow.map((option) => {
        const { label, value, description } = config[option];
        return (
          <Dropdown.Item eventKey={value} onClick={() => onClickItem(value)} className={`${value === selectedOption ? "selected" : ""}`}>
            <div className="left-content">
              <div className="label"><Trans>{label}</Trans></div>
              <WithTooltip title={description}><div className="description ellipsis-text"><Trans>{description}</Trans></div></WithTooltip>
            </div>
            {value === selectedOption && (
              <div className="selected-status">
                <span className="icon icon-check" />
              </div>
            )}
          </Dropdown.Item>
        );
      })}
    </SplitButton>
  );
});

SplitButtonWrapper.defaultProps = {
  defaultValue: "",
  optionsToShow: ["OPTION_1", "OPTION_2"],
  config: {
    OPTION_1: {
      value: "OPTION_1",
      label: "Option 1",
      description: "some description for option 1",
      className: "option1",
      cta: "Option 1",
    },
    OPTION_2: {
      value: "OPTION_2",
      label: "Option 2",
      description: "some description for option 2",
      className: "option2",
      cta: "Option 2",
    },
  },
  onClick: () => { },
  onItemClicked: () => {},
};

export default SplitButtonWrapper;
