import React, { useState } from "react";
import { Trans } from "react-i18next";
import classnames from "classnames";

import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Button } from "@onlinesales-ai/button-v2";
import "./index.less";

const NoteCandy = ({
  linkLabel,
  linkCTACallback,
  text,
  subText,
  useIconImg,
  IconImgUrl,
  iconClass,
  label,
  wrapperClass,
  borderType,
  shape,
  enableBorder,
  modalContentStr,
  clickableCandy,
  variant,
  type,
  linkLabelProps,
  styling,
  rightSectionClass,
}) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const showModalDom = () => {
    return (
      <ModalWindow
        containerClass="suggestion-candy-modal-container"
        isShow={showModal}
        onModalDestroy={closeModal}
      >
        <div className="suggestion-modal-body text-center">
          <div className="suggestion-modal-body-content">
            <ModalWindow.Body
              dangerouslySetInnerHTML={{
                __html: modalContentStr,
              }}
            />
          </div>
          <ModalWindow.Footer>
            <Button onClick={closeModal}>
              <Trans>Done</Trans>
            </Button>
          </ModalWindow.Footer>
        </div>
      </ModalWindow>
    );
  };

  const ctaClickHandler = (event) => {
    event.stopPropagation();
    if (linkCTACallback) {
      linkCTACallback();
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <div
        className={`suggestion-candy-wrapper ${wrapperClass} ${shape && `shape-${shape}`}
          ${borderType && `border-${borderType}`} ${clickableCandy ? "cursor-pointer" : ""} ${variant} ${type} ${styling}`}
        onClick={clickableCandy ? ctaClickHandler : null}
      >
        <div className="suggestion-candy-container">
          <div className="suggestion-candy-left-content">
            {useIconImg && IconImgUrl && <img src={IconImgUrl}></img>}
            {iconClass && <span className={`icon ${iconClass}`} />}
            {label && <span className="left-text">{label}</span>}
            {(text || subText) && (
              <div className="suggestion-candy-description">
                {text && (
                  <span className="suggestion-candy-text">
                    {typeof text === "string" ? <Trans>{text}</Trans> : text}
                  </span>
                )}
                {subText && (
                  <span className={classnames("suggestion-candy-sub-text", rightSectionClass)}>
                    <Trans>{subText}</Trans>
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="suggestion-candy-right-content">
            {linkLabel && (
              <Button onClick={ctaClickHandler} type="primary" link {...linkLabelProps}>
                {linkLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
      {showModalDom()}
    </>
  );
};

NoteCandy.defaultProps = {
  iconClass: null,
  label: null,
  linkLabel: null,
  text: null,
  subText: null,
  useIconImg: true,
  clickableCandy: false,
  IconImgUrl: "https://res.cloudinary.com/rahuls97/image/upload/h_20/2019-11-26_1_edlawt.png",
  borderType: "dashed", // solid, none, dashed
  shape: "", // rounded. normal
  variant: "default", // large,
  type: "default", // warning, error, success
  styling: "", // new
};

export default NoteCandy;
