import React from "react";
import { FetchBanner } from "@onlinesales-ai/message-card-v2";

import "./index.less";

const GreyBoxWithSeperator = ({
  containerClass,
  renderTopSection,
  renderBottomSection,
  showBottom,
  renderTitleSection,
  renderSubmitButton,
  isFormViewOnly,
  isLoading,
  errorMsg,
}) => {
  return (
    <div
      className={`im-grey-box-with-seperator-container ${containerClass} ${
        isFormViewOnly ? "form-view" : "without-form-view"
      }`}
    >
      {!isFormViewOnly && renderTitleSection && (
        <div className="title-section">{renderTitleSection()}</div>
      )}
      <FetchBanner errorTitle="Error occured" isLoading={isLoading} errorMsg={errorMsg}>
        {renderTopSection && <div className="top-section">{renderTopSection()}</div>}
        {showBottom && (
          <>
            {renderBottomSection && <hr />}
            <div className="bottom-section">{renderBottomSection()}</div>
          </>
        )}
        {renderSubmitButton && <div className="right-submit-btn">{renderSubmitButton()}</div>}
      </FetchBanner>
    </div>
  );
};

GreyBoxWithSeperator.defaultProps = {
  containerClass: "",
  renderTopSection: () => {},
  renderBottomSection: () => {},
  showBottom: true,
};

export default GreyBoxWithSeperator;
