import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { BrandCreation } from "@onlinesales-ai/ott-common-v2";

import {
  fetchEntityMetaData as fetchEntityMetaDataAction,
  postClientData as postClientDataAction,
} from "src/store/application";

const BrandCreationWrapper = ({ ...props }) => {
  return <BrandCreation {...props} />;
};

const mapStateToProps = (state) => {
  const { agencyUrl, timezone, marketplaceCurrency } = state.DomainConfig || {};
  const { entityInfo, entityMetadata } = state.Application || {};

  return {
    entityInfo,
    entityMetadata,
    agencyUrl,
    timezone,
    marketplaceCurrency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchEntityMetaData: fetchEntityMetaDataAction,
      postClientData: postClientDataAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandCreationWrapper);
