import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";

import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { BaseClient, BrandAdsService } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { configHoc } from "@onlinesales-ai/util-methods-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import CommentWidget from "./commentWidget";

const ReviewComment = ({
  userInfo,
  hasMarkdownSupport,
  wobbleClass,
  buttonToShow,
  buttonConfig,
  postStatusChange,
  hadesEntityId,
  hadesEntityType,
  entityId,
  entityType,
  entityLevelId,
  entityLevelType,
  title,
  entityData,
  className,
  labelConfig,
}) => {
  const { showToastMessage } = useContext(GlobalContext);
  const [commentsData, setCommentsData] = useState([]);
  const [commentsFetchState, setCommentsFetchState] = useState({
    isLoading: false,
    errorMsg: null,
  });

  const fetchCampaignComments = async (firstTimeFetch) => {
    try {
      if (firstTimeFetch) {
        setCommentsFetchState({
          isLoading: true,
          errorMsg: null,
        });
      }

      const payload = {
        limit: 1000,
        offset: 0,
        hadesEntityId: `${hadesEntityId}`,
        hadesEntityType,
        entityId: `${entityId}`,
        entityType,
        entityLevelId: `${entityLevelId}`,
        entityLevelType,
        passMarketId: false,
      };

      const response = await BrandAdsService.fetchEntityComments(payload);
      setCommentsData(response.comments);
      setCommentsFetchState({
        isLoading: false,
      });
    } catch (error) {
      setCommentsFetchState({
        isLoading: false,
        errorMsg: error?.errorMsg || BaseClient.getErrorMessage(),
      });
    }
  };

  useEffect(() => {
    if (
      hadesEntityId &&
      hadesEntityType &&
      entityId &&
      entityType &&
      entityLevelId &&
      entityLevelType
    ) {
      fetchCampaignComments(true);
    }
  }, [hadesEntityId, hadesEntityType, entityId, entityType, entityLevelId, entityLevelType]);

  useEffect(() => {
    PlatformEventManager.on("REFRESH_CAMPAIGN_COMMENTS", fetchCampaignComments);
    return () => {
      PlatformEventManager.off("REFRESH_CAMPAIGN_COMMENTS", fetchCampaignComments);
    };
  }, [hadesEntityId, hadesEntityType, entityId, entityType, entityLevelId, entityLevelType]);

  const onCommentSubmit = async ({ selected, commentDetails }) => {
    try {
      if (buttonConfig[selected]?.postUsingStatus) {
        await postStatusChange(buttonConfig[selected]?.statusToPost, entityData, {
          comment: {
            commentDetails,
          },
        });
        PlatformEventManager.emit("REFRESH_CAMPAIGN_COMMENTS");
      } else {
        const payload = {
          hadesEntityId: `${hadesEntityId}`,
          hadesEntityType,
          entityId: `${entityId}`,
          entityType,
          comments: [
            {
              timestampUtc: new Date().valueOf(),
              timestamp: new Date().valueOf(),
              commentDetails,
              user: {
                userId: userInfo.id,
                name: userInfo.name,
                email: userInfo.email,
                isAdmin: true,
              },
              entityLevelId: `${entityLevelId}`,
              entityLevelType,
            },
          ],
        };

        const response = await BrandAdsService.postEntityComment(payload);
        const { comments } = response;

        if (comments.length) {
          const setCommentsArr = [...commentsData, comments[0]];
          setCommentsData(setCommentsArr);
        }
      }
    } catch (error) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: error?.errorMsg || BaseClient.getErrorMessage(),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
    }
  };

  return (
    <div className={`review-comments-wrapper ${className || ""}`}>
      <FetchBanner
        isLoading={commentsFetchState?.isLoading}
        errorTitle="Error while fetching data"
        errorMsg={commentsFetchState?.errorMsg}
      >
        <CommentWidget
          comments={commentsData}
          userInfo={userInfo}
          hasMarkdownSupport={hasMarkdownSupport}
          wobbleClass={wobbleClass}
          onCommentSubmit={onCommentSubmit}
          buttonToShow={buttonToShow}
          buttonConfig={buttonConfig}
          title={title}
          labelConfig={labelConfig}
          emptyMessage="No Comments yet"
        />
      </FetchBanner>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.Application.userInfo,
    agencyId: state.Application.agencyId,
  };
};

const ReviewCommentWrapper = configHoc(connect(mapStateToProps)(ReviewComment));

ReviewCommentWrapper.defaultProps = {
  defaultConfigProps: {
    hasMarkdownSupport: true,
    buttonToShow: ["COMMENT"],
    buttonConfig: {
      COMMENT: {
        isDefault: true,
        value: "COMMENT",
        label: "Comment Only",
        description: "Comment regarding ad",
        className: "primary pendo_feature_review_comment_only_button",
        cta: "Comment Only",
      },
      COMMENT_WITH_REJECT: {
        confirmation: true,
        configrmationMessage: "Are you sure you want to comment & reject this ad?",
        postUsingStatus: true,
        statusToPost: "REJECT",
        value: "COMMENT_WITH_REJECT",
        label: "Comment & Reject",
        description: "Comment to reject ad",
        className: "danger pendo_feature_review_comment_and_reject_button",
        cta: "Comment & Reject",
      },
    },
  },
};

export default ReviewCommentWrapper;
