import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { Form } from "@onlinesales-ai/form-components-v2";
import { configHoc } from "@onlinesales-ai/util-methods-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { NoteCandy } from "@onlinesales-ai/note-candy-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import formComponents from "../formComponents";

import GreyBoxWithSeperator from "../../utils/greyBoxWithSeperator";
import { getEntityInfo, getEntityMetadata } from "../../utilities/methods";
import { entityTypeEnum } from "../../utilities/constants";

import "./index.less";

const BrandCreation = ({
  title,
  description,
  imgUrl,
  componentToShow,
  componentConfig,
  jobDoneCallback,
  postClientData,
  isReadOnly = false,
  isShowSuccessMsg,
  agencyUrl,
  timezone,
  marketplaceCurrency,
  entityInfo,
  entityMetadata,
  isSkippable,
  isExpanded,
  suggestionInfoText,
  businessDefinition,
  isBillingEntity,
  fetchEntityMetaData,
  id: clientId,
  parentMCCId,
  componentRef,
  labelColumns,
  isFormViewOnly,
  ctaText,
  shouldFetchData,
  isNewCreation,
}) => {
  const { showToastMessage } = useContext(GlobalContext);

  const [isSkipInProgess, setIsSkipInProgess] = useState(false);
  const [isSaveInProgess, setIsSaveInProgess] = useState(false);
  const [isFetchInProgess, setIsFetchInProgess] = useState(false);
  const [entityMetadataFetchError, setEntityMetadataFetchError] = useState(false);

  const formRef = useRef();

  const clientIdWithType = useMemo(() => {
    return `${entityTypeEnum.CLIENT}_${clientId}`;
  }, [clientId]);

  const fetchEntityMetaDataFromAPI = async () => {
    setIsFetchInProgess(true);
    setEntityMetadataFetchError(false);
    try {
      const config = {
        entityId: clientId,
        entityType: entityTypeEnum.CLIENT,
        keys: ["entityId", "entityType", "entityAlias", "metadata"],
      };

      await fetchEntityMetaData(config, { parentMCCId });
    } catch (err) {
      const errorMsg = err?.errorMsg || "Error occured while fetching details.";
      setEntityMetadataFetchError(errorMsg);
    }
    setIsFetchInProgess(false);
  };

  const values = useMemo(() => {
    const entityInfoOfClient = getEntityInfo(entityInfo, clientIdWithType);
    const entityMetadataOfClient = getEntityMetadata(entityMetadata, clientIdWithType);

    if (!_isEmpty(entityInfoOfClient) && !_isEmpty(entityMetadataOfClient)) {
      return {
        ...entityInfoOfClient,
        metadata: entityMetadataOfClient,
      };
    }

    return {};
  }, [entityInfo, entityMetadata, clientIdWithType]);

  const [formValues, setFormValues] = useState(values || {});

  const fetchData = () => {
    const entityInfoOfClient = getEntityInfo(entityInfo, clientIdWithType);
    const entityMetadataOfClient = getEntityMetadata(entityMetadata, clientIdWithType);

    if (
      shouldFetchData ||
      (clientId && (_isEmpty(entityInfoOfClient) || _isEmpty(entityMetadataOfClient)))
    ) {
      fetchEntityMetaDataFromAPI();
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchData();
    }
  }, [clientId]);

  const getPayload = (data) => {
    const metadata = {
      businessDefinition: data.businessDefinition || businessDefinition,
      isBillingEntity: data.hasOwnProperty("isBillingEntity")
        ? data.isBillingEntity
        : isBillingEntity,
      currency: marketplaceCurrency,
      timezone,
      billingMetadata: { supportingDocumentStatus: "REQUIRED" },
      ...(data?.metadata || {}),
    };

    let entityData = {
      ...data,
      metadata,
    };

    entityData = _omit(entityData, [
      "statusType",
      "depth",
      "parentMCCId",
      "originalEntityId",
      "hierarchy",
      "breadCrumbText",
      "parentDepth",
    ]);

    const payload = {
      parentId: parentMCCId,
      parentEntityType: entityTypeEnum.MCC,
      entity: {
        ...entityData,
        entityId: clientId,
        entityType: entityTypeEnum.CLIENT,
        metadata,
      },
    };

    return payload;
  };

  const onSubmit = async (valuesToSubmit) => {
    setIsSaveInProgess(true);
    try {
      const config = getPayload(valuesToSubmit);

      const response = await postClientData(config);

      if (!config?.entity.entityId && response?.entity) {
        PlatformEventManager.emit("BRAND_CREATION", response?.entity);
      }

      jobDoneCallback({
        isSkipped: false,
        creationResponse: {
          ...(response?.entity || {}),
          entityType: entityTypeEnum.CLIENT,
        },
      });
      if (isShowSuccessMsg) {
        showToastMessage({
          type: "SUCCESS",
          messageToDisplay: "Details saved successfully.",
          actionButtonLabel: null,
          toastDuration: 5000,
        });
      }
    } catch (err) {
      uiAPIMonitor("SEV2", "OTT_ONBOARDING_BRAND_CREATION_FAILED", {
        error: err,
      });
      showToastMessage({
        type: "ERROR",
        messageToDisplay: err?.errorMsg || "Error occured while saving details.",
        actionButtonLabel: null,
        toastDuration: 5000,
      });
      return Promise.reject(err);
    }
    setIsSaveInProgess(false);
  };

  const onClickSkip = async () => {
    setIsSkipInProgess(true);
    try {
      jobDoneCallback({ isSkipped: true });
    } catch (err) {}
    setIsSkipInProgess(false);
  };

  const renderForm = ({ renderFormComponent, renderCTAButton, bodyWrapperProps }) => {
    const renderFormElements = () => {
      return (
        <>
          {renderFormComponent("name")}
          {renderFormComponent("logo")}
          {renderFormComponent("brandWebsite")}
          {renderFormComponent("industry")}
          {renderFormComponent("description")}
        </>
      );
    };

    const renderFormButtons = () => {
      return (
        <>
          {isSkippable ? (
            <Button
              isLoading={isSkipInProgess}
              disabled={isSkipInProgess || isSaveInProgess}
              onClick={onClickSkip}
              link
            >
              Skip for now
            </Button>
          ) : null}
          {renderCTAButton()}
        </>
      );
    };

    return (
      <div {...bodyWrapperProps}>
        <GreyBoxWithSeperator
          isFormViewOnly={isFormViewOnly}
          renderTitleSection={() => (
            <>
              <div className="title">
                <AsyncImage imgSrc={imgUrl} />
                <span>{title}</span>
              </div>
              <div className="sub-title">{description}</div>
            </>
          )}
          showBottom={false}
          renderTopSection={isExpanded ? renderFormElements : null}
          renderSubmitButton={isExpanded ? renderFormButtons : null}
          isLoading={isFetchInProgess}
          errorMsg={entityMetadataFetchError}
        />
        {isExpanded && suggestionInfoText && !clientId ? (
          <div className="suggestion-info-text">{suggestionInfoText}</div>
        ) : null}
        {isExpanded && isNewCreation ? (
          <NoteCandy
            text={
              <div>
                <div className="mb-3">Please note that for running campaigns for certain brands, we may require you to provide authorization proof. If applicable, our helpdesk team will contact you for further details.</div>
                <div>Additionally, please be aware that we strictly adhere to applicable laws, and campaigns promoting goods/services in violation of these laws are not allowed. Consequently, select categories such as tobacco, chit funds, gambling, legal services, etc., are banned from advertising on our platform. For more information, please refer to our Terms and Conditions (T&Cs).</div>
              </div>
            }
            useIconImg={false}
            wrapperClass="mt-3"
          />
        ) : null}
      </div>
    );
  };

  const hasDirtyData = () => {
    return !_isEmpty(formValues);
  };

  useEffect(() => {
    if (componentRef) {
      componentRef.current = {
        hasDirtyData,
      };

      return () => {
        componentRef.current = {};
      };
    }
  }, [formValues]);

  const ctaTextToShow = useMemo(() => {
    if (clientId) {
      return "Save";
    }
    if (ctaText) {
      return ctaText;
    }
  }, [ctaText, clientId]);

  return (
    <div className="brand-wrapper">
      <Form
        componentToShow={componentToShow}
        componentConfig={componentConfig}
        formComponents={formComponents}
        values={values}
        isEditable={!isReadOnly}
        onSubmit={onSubmit}
        customFormRenderer={renderForm}
        formRef={formRef}
        labelColumns={labelColumns}
        ctaText={ctaTextToShow}
        onFieldChange={setFormValues}
        ctaButtonProps={{
          outline: !isFormViewOnly,
          className: "pendo_feature_ott_save_business_details",
        }}
      />
    </div>
  );
};

BrandCreation.defaultProps = {
  jobDoneCallback: () => Promise.resolve(),
  isShowSuccessMsg: false,
  ctaText: "Save",
};

export default OSHOCWithUtilities(configHoc(BrandCreation));
