import React, { useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Navbar, Nav } from "react-bootstrap";
import _sortBy from "lodash/sortBy";
import _isEmpty from "lodash/isEmpty";

import { DropdownVirtualized, createFilter } from "@onlinesales-ai/dropdown-v2";
import { CLIENT_AGGREGATOR_CONFIG } from "@onlinesales-ai/constants-v2";
import { outletTextReplacer } from "@onlinesales-ai/util-methods-v2";
import { PWAInstallButton } from "@onlinesales-ai/addons-handler-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import NavMenu from "./navMenu";
import "./index.less";

const getId = (option) => {
  return `header-menu-${option.id || option?.name?.replace?.(" ", "-")?.toLowerCase?.()}`;
};

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: (option) => `${option.label} ${option.value} ${option.data?.clientName || ""}`,
  trim: true,
};

const Header = ({
  clientId,
  clients,
  onClientChange,
  isAllClientsFetched,
  brandingLogo,
  mobileBrandingLogo,
  brandingClass,
  isAllClientsSelected,
  showClientList,
  isMobile,
  navLinks,
  showNavigation,
  redirectUrl,
  customComponents,
  onClickNavTypes,
  shouldRendertNavItem,
  homePageUrl,
  loginUrl,
  containerClass,
  customEntityRender,
  showHeaderWithText,
}) => {
  const { t } = useTranslation();
  const [showCollaps, setShowCollaps] = useState(false);
  const dropdownFilter = useMemo(() => createFilter(filterConfig), []);
  const { changeClientId } = useContext(GlobalContext);

  const clientOptions = useMemo(() => {
    const list = clients.map((c) => ({
      value: c.clientId,
      label: c.name,
      clientName: c.clientName,
    }));

    return list;
  }, [clients]);

  const shouldRender = (props) => {
    const propToCheck = isMobile ? "shouldBeRenderOnMobile" : "shouldBeRenderOnDesktop";

    const isRenderFromProps = shouldRendertNavItem ? shouldRendertNavItem(props) : true;

    return (
      isRenderFromProps &&
      props[propToCheck] !== false &&
      (!isAllClientsSelected || props.isShowForAllClients)
    );
  };

  const onClickNav = (event, app) => {
    if (showCollaps) {
      setShowCollaps(false);
    }
    if (app.type) {
      if (app.type === "LOGOUT") {
        redirectUrl(loginUrl);
      } else if (onClickNavTypes) {
        onClickNavTypes(app);
      }
    } else {
      if (app.changeToAllOutlets) {
        changeClientId("CLIENT_AGGREGATOR");
      }
      redirectUrl(app.href);
    }
  };

  const onClickMobileBar = (isOpen) => {
    setShowCollaps(isOpen);
  };

  const onChangeClient = (client) => {
    if (showCollaps) {
      setShowCollaps(false);
    }
    if (clientId != client) {
      onClientChange(client);
    }
  };

  const renderDropDownOptions = (obj) => {
    let options = obj?.dropdownOptions;
    if (!Array.isArray(options)) {
      options = _sortBy(Object.values(options), ["order"]).filter((op) => op?.isShow !== false);
    }

    return options
      .map((option, index) => {
        if (shouldRender(option)) {
          const navClick = (e) => onClickNav(e, option);
          return (
            <Dropdown.Item
              id={getId(option)}
              key={index}
              eventKey={index}
              active={false}
              onClick={option.onClick || navClick}
            >
              {option?.icon && <span className={`icon ${option?.icon}`} />}
              {t(outletTextReplacer(option.name))}
            </Dropdown.Item>
          );
        }
        return null;
      })
      .filter((l) => !!l);
  };

  const renderMenuWithDropdown = (app) => {
    return (
      <Dropdown
        className={`${app?.isShowDropdownIndicator ? "show-indicator" : ""} nav-item`}
        alignRight={app.hasOwnProperty("alignRight") ? app.alignRight : true}
        key={app.href}
      >
        <Dropdown.Toggle as={NavMenu} app={app} />
        <Dropdown.Menu right className="super-colors">
          {renderDropDownOptions(app)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderNavLinks = () => {
    const itemsToShow = [];

    navLinks.forEach((app) => {
      if (shouldRender(app)) {
        if (Array.isArray(app.dropdownOptions)) {
          if (app.dropdownOptions.length) {
            itemsToShow.push(renderMenuWithDropdown(app));
          }
        } else if (app?.processDynamicOptions && !_isEmpty(app.dropdownOptions)) {
          itemsToShow.push(renderMenuWithDropdown(app));
        } else {
          itemsToShow.push(<NavMenu key={app.href} app={app} onClick={onClickNav} />);
        }
      }
    });

    return itemsToShow;
  };

  const logoUrl = useMemo(() => {
    return isMobile ? (mobileBrandingLogo || brandingLogo) : brandingLogo;
  }, [isMobile, mobileBrandingLogo, brandingLogo]);

  return (
    <div id="app-header" className={`${containerClass} ${showHeaderWithText ? "show-header-with-text" : ""}`}>
      <Navbar
        collapseOnSelect
        expanded={showCollaps}
        onToggle={onClickMobileBar}
        expand="md"
        variant="dark"
      >
        <div className="navbar-logo__toggle">
          <Navbar.Brand href={homePageUrl}>
            {logoUrl && (
              <img src={logoUrl} className={brandingClass} alt="OnlineSales.ai logo" />
            )}
          </Navbar.Brand>
          {isMobile ? <PWAInstallButton isMobile /> : null}
          {isMobile ? customComponents : null}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto nav-bar-left">
            {
              customEntityRender ? (
                customEntityRender()
              ) : (
                <>
                  {showClientList && clientOptions.length > 1 ? (
                    <DropdownVirtualized
                      value={isAllClientsSelected ? CLIENT_AGGREGATOR_CONFIG.value : clientId}
                      className="client-list pendo-track-client-list-dropdown"
                      options={clientOptions}
                      isTranslateOptions={false}
                      isClearable={false}
                      onChange={onChangeClient}
                      isLoading={!isAllClientsFetched}
                      styles={{
                        option: (base) => ({
                          ...base,
                          height: 37,
                        }),
                      }}
                      filterOption={dropdownFilter}
                    />
                  ) : null}
                </>
              )
            }
          </Nav>
          <Nav className="nav-bar-right ml-auto">
            {!isMobile ? <PWAInstallButton /> : null}
            {!isMobile ? customComponents : null}
            {showNavigation ? renderNavLinks() : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

Header.defaultProps = {
  brandingLogo:
    "https://res.cloudinary.com/onlinesales/image/upload/w_155,h_25/logos/72htWhiteLogo.png",
  brandingClass: "onlinesales",
  containerClass: "",
  isShowAllClientsOption: false,
  isInternalUser: false,
  showClientList: true,
  showNavigation: true,
  navLinks: [],
  clients: [],
  customComponents: null,
  loginUrl: "/login",
  showHeaderWithText: false,
};

export default Header;
