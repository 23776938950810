import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { HelpSection, DownloaderBar, UserDetailsValidator, OfflineToast } from "@onlinesales-ai/addons-handler-v2";

import { redirectUrl } from "src/store/application";

import AddOnsHandler from "./AddOnsHandler";

import "./addOns.less";

const AddOns = ({ redirect, location, helpSection }) => {
  return (
    <AddOnsHandler
      showToastMessage
      location={location}
      redirectUrl={redirect}
    >
      <div className="addons-floating-btn-wrapper">
        <div className="addons-floating-btn-inner">
          {!!helpSection?.isShow && (
            <HelpSection redirectUrl={redirect} {...(helpSection?.props || {})} />
          )}
        </div>
      </div>
      <DownloaderBar isEntityLevel />
      <OfflineToast />
      <UserDetailsValidator />
    </AddOnsHandler>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirect: redirectUrl,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  const { helpSection } = state.DomainConfig || {};

  return {
    location: state.router.location,
    helpSection,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOns);
