import _isEmpty from "lodash/isEmpty";
import { getCookie, AppStore } from "@onlinesales-ai/util-methods-v2";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";

export const defaultShopInfo = {
  id: 1331,
  clientId: 20699,
  name: "onlinesales.io",
  statusType: "ACTIVE",
  url: "http://onlinesales.io",
  timezone: "Asia/Calcutta",
  currencyCode: "INR",
  creationDate: 1492673487000,
  storeType: "custom",
  installationType: "DIRECT",
  feedSource: "ftpfeed",
  contactNumber: "9800000000",
};

export const defaultUserInfo = {
  organizationName: null,
  isSocialUser: true,
  status: "ACTIVE",
  id: 12097,
  organizationId: 547,
  organizationTypeId: 1,
  contactNo: "9876543210",
  email: "screenshotapp@onlinesales.ai",
  name: "screenshotapp",
};

export const getUserInfo = (shouldFallback = false) => {
  let storeState = {};
  try {
    storeState = AppStore.getState();
  } catch (error) {
    triggerMonitor("SEV3", "REDUX_RACE_CONDITION", { error });
  }

  let retnData = storeState?.Application?.userInfo || {};

  if (_isEmpty(retnData)) {
    // retnData = getCookie("user");
    if (!retnData) {
      retnData = {};
    }
  }

  if (shouldFallback && _isEmpty(retnData)) {
    retnData = defaultUserInfo;
  }

  return retnData;
};

export const getShopInfo = (shouldFallback = false) => {
  let storeState = {};
  try {
    storeState = AppStore.getState();
  } catch (error) {
    triggerMonitor("SEV3", "REDUX_RACE_CONDITION", { error });
  }

  let retnData = storeState?.Application?.shopInfo || {};

  if (_isEmpty(retnData)) {
    retnData = getCookie("shop");
    if (!retnData) {
      retnData = {};
    }
  }

  if (shouldFallback && _isEmpty(retnData)) {
    retnData = defaultShopInfo;
  }

  return retnData;
};

export const populateEvent = (eventFrom, eventName, metadata, columns) => {
  try {
    if (
      typeof window.sokrati !== "undefined" &&
      typeof window.sokrati.beaconParams == "function"
    ) {
      const storeState = AppStore.getState();
      let shopInfo = getShopInfo(true),
        userInfo = getUserInfo(true),
        onboardingDetails = storeState?.OnBoarding?.data,
        domainConfig = storeState?.DomainConfig || {};


      let eventObj = {
        eventFrom,
        metadata:
          typeof metadata === "object" ? JSON.stringify(metadata) : metadata,
        lead_step: eventName,
        cust_name: userInfo.id,
        cust_email: userInfo.email,
        cust_phone: userInfo.contactNo,
        tracking_id: shopInfo.clientId,
        sort_order: shopInfo.statusType,
        product_names: domainConfig.storeType,
        sok_sku: onboardingDetails?.appConfigs?.apps || `${domainConfig.marketplaceClientId}.json`
      };

      if (columns && typeof columns === "object") {
        Object.keys(columns).forEach(function (column) {
          eventObj[column] = columns[column];
        });
      }

      window.sokrati.beaconParams("default", eventName, eventObj);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "trackingDetails": eventObj,
        'event': "TRACKING_EVENT"
      });
    }
  } catch (e) {}
};
