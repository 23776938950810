import React, { useEffect, useMemo, useState } from "react";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { connect } from "react-redux";

import { ONBOARDING_CARD_STATUS, ONBOARDINGSTATUS } from "@onlinesales-ai/constants-v2";
import {
  advertiserDefaultConfig,
  agencyDefaultConfig,
  brandDefaultConfig,
  getEntityInfo,
  entityTypeEnum,
  businessDefinitionEnum,
} from "@onlinesales-ai/ott-common-v2";

//TODO: Do not use hotstar specific onboarding here, fix this later
import Onboarding from "src/pages/onboarding/components/hotstarOnboarding";

import "./index.less";

const NewEntityCreation = ({
  isOpen,
  onHide,
  title,
  parentMCCId,
  entityInfo,
  entityMetadata,
  agencyId,
  onNewEntityCreation,
  jobDoneCallback,
  stepsConfigByOption,
}) => {
  const parentMCCInfo = useMemo(() => {
    const currentEntityInfo = getEntityInfo(entityInfo, `${entityTypeEnum.MCC}_${parentMCCId}`);
    const currentEntityMetadata = getEntityInfo(entityMetadata, `${entityTypeEnum.MCC}_${parentMCCId}`);

    return {
      ...currentEntityInfo,
      ...currentEntityMetadata,
    };
  }, [isOpen, entityInfo, entityMetadata, parentMCCId]);

  const businessOptions = useMemo(() => {
    if (!parentMCCId) {
      return undefined;
    }

    if (parentMCCInfo?.businessDefinition === businessDefinitionEnum.AGENCY) {
      return [
        {
          value: businessDefinitionEnum.AGENCY,
          label: "Agency",
        },
      ];
    }

    if (parentMCCInfo?.businessDefinition === businessDefinitionEnum.ADVERTISER) {
      return [
        {
          value: businessDefinitionEnum.ADVERTISER,
          label: "Agency",
        },
      ];
    }
  }, [isOpen, parentMCCInfo]);

  const overrideByOption = useMemo(() => {
    if (!parentMCCId) {
      return {
        AGENCY: {
          stepsConfig: {
            AGENCY: {
              shouldBeRendered: true,
            },
            ADVERTISER: {
              shouldBeRendered: true,
              title: "Add advertiser under agency",
            },
            BRAND: {
              shouldBeRendered: true,
            },
          },
        },
        ADVERTISER: {
          stepsConfig: {
            ADVERTISER: {
              title: "Add advertiser",
              shouldBeRendered: true,
            },
            BRAND: {
              shouldBeRendered: true,
            },
          },
        },
      };
    }

    if (parentMCCInfo?.businessDefinition === businessDefinitionEnum.AGENCY) {
      return {
        AGENCY: {
          stepsConfig: {
            AGENCY: {
              shouldBeRendered: false,
            },
            ADVERTISER: {
              title: "Add advertiser under agency",
              shouldBeRendered: true,
              isSkippable: false,
            },
            BRAND: {
              shouldBeRendered: true,
            },
          },
        },
      };
    }

    if (parentMCCInfo?.businessDefinition === businessDefinitionEnum.ADVERTISER) {
      return {
        ADVERTISER: {
          stepsConfig: {
            ADVERTISER: {
              shouldBeRendered: false,
            },
            BRAND: {
              shouldBeRendered: true,
              isSkippable: false,
              title: "Brand Details",
              ctaText: "Create",
            },
          },
        },
      };
    }
  }, [isOpen, parentMCCInfo]);

  const [stepsState, setStepsState] = useState({});

  const onStepJobDone = (data = {}, ignoredParam, extraDetails = {}) => {
    if (data?.entity?.metadata?.onboardingData) {
      const newStepsState = data?.entity?.metadata?.onboardingData || {};
      setStepsState(newStepsState);

      if (extraDetails.newEntityId && extraDetails.newEntityType) {
        onNewEntityCreation(extraDetails);
      }

      if (newStepsState?.finalStatus === ONBOARDINGSTATUS.ACTIVE) {
        const retnData = {};
        Object.keys(newStepsState?.steps || []).forEach((key) => {
          retnData[key] = newStepsState?.steps[key].id;
        });
        jobDoneCallback(retnData);
      }
    }

    return Promise.resolve();
  };

  useEffect(() => {
    const stepsConfig = {
      finalStatus: ONBOARDINGSTATUS.IN_PROGRESS,
      steps: {},
    };

    if (parentMCCInfo?.businessDefinition === businessDefinitionEnum.AGENCY) {
      stepsConfig.steps = {
        AGENCY: {
          status: ONBOARDING_CARD_STATUS.COMPLETED,
          id: parentMCCId,
        },
        ADVERTISER: {
          status: ONBOARDING_CARD_STATUS.DRAFT,
        },
        BRAND: {
          status: ONBOARDING_CARD_STATUS.DRAFT,
        },
      };
    } else if (parentMCCInfo?.businessDefinition === businessDefinitionEnum.ADVERTISER) {
      stepsConfig.steps = {
        ADVERTISER: {
          status: ONBOARDING_CARD_STATUS.COMPLETED,
          id: parentMCCId,
        },
        BRAND: {
          status: ONBOARDING_CARD_STATUS.DRAFT,
        },
      };
    }

    setStepsState(stepsConfig);
  }, [isOpen, parentMCCInfo.businessDefinition]);

  return (
    <Drawer
      containerClass="entity-creation-drawer"
      isNoPadding
      header={title}
      isOpen={isOpen}
      onClickClose={onHide}
    >
      {isOpen &&
        <Drawer.Body>
          <Onboarding
            containerClass=""
            isSetNewEntityIdAsActive={false}
            configProps={{
              overrideByOption,
              ...(businessOptions ? { businessOptions } : {}),
              stepsConfigByOption,
            }}
            componentProps={{
              isNewCreation: true,
            }}
            storeOnboardingDetails={stepsState}
            postMccData={onStepJobDone}
            fallbackMCCId={parentMCCId}
          />
        </Drawer.Body>
      }
    </Drawer>
  );
};

NewEntityCreation.defaultProps = {
  isOpen: false,
  onHide: () => {},
  title: "Create New Brand",
  jobDoneCallback: () => {},
  onNewEntityCreation: () => {},
  stepsConfigByOption: {
    AGENCY: {
      steps: ["AGENCY", "ADVERTISER", "BRAND"],
      stepsConfig: {
        AGENCY: {
          ...agencyDefaultConfig,
        },
        ADVERTISER: {
          ...advertiserDefaultConfig,
        },
        BRAND: {
          ...brandDefaultConfig,
        },
      },
    },
    ADVERTISER: {
      steps: ["ADVERTISER", "BRAND"],
      stepsConfig: {
        ADVERTISER: {
          ...advertiserDefaultConfig,
        },
        BRAND: {
          ...brandDefaultConfig,
        },
      },
    },
  },
};

const mapStateToProps = (state, ownProps) => {
  const {
    entityInfo,
    entityMetadata,
    agencyId,
  } = state.Application || {};

  return {
    entityInfo,
    entityMetadata,
    agencyId,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(NewEntityCreation);
