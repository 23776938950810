import React from "react";
import { Trans, withTranslation } from "react-i18next";

import { logoConfigExtract } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const CallbackPageUIComp = ({
  message,
  secondaryMessage,
  logoSrc,
  loaderSrc,
  isError,
  errorMsg,
  wrapperClassName,
  logoClassName,
  loaderClassName,
  showRetry,
  errorMsgDomString,
}) => {
  const configLogoSrc = logoConfigExtract("logo_width_52");
  const configLoaderSrc = logoConfigExtract("callbackPageLoader");

  const onRetry = () => {
    try {
      window.location.reload();
    } catch (e) {}
  };

  return (
    <div className={`callback-wrapper ${wrapperClassName}`}>
      {isError ? (
        <img
          src={logoSrc || configLogoSrc}
          className={`callback-logo ${logoClassName}`}
          alt="Error"
        />
      ) : (
        <img
          src={configLoaderSrc || loaderSrc}
          className={`callback-loader ${loaderClassName}`}
          alt="Redirecting"
        />
      )}
      {isError ? (
        <div className="callback-message-wrapper">
          <Trans>{errorMsg}</Trans>
          {errorMsgDomString ? (
            <div dangerouslySetInnerHTML={{ __html: errorMsgDomString }} />
          ) : null}
          <br />
          {showRetry ? (
            <Trans>
              Please{" "}
              <a href="javascript:void(0)" onClick={onRetry}>
                retry
              </a>{" "}
              again!
            </Trans>
          ) : null}
        </div>
      ) : (
        <p className="callback-message-wrapper">
          <Trans>{message}</Trans>
          <br />
          <Trans>{secondaryMessage}</Trans>
        </p>
      )}
    </div>
  );
};

CallbackPageUIComp.defaultProps = {
  isError: false,
  errorMsg: null,
  errorMsgDomString: null,
  showRetry: true,
  logoSrc: "",
  loaderSrc: "",
  message: "Hang tight! We're connecting your store to the OnlineSales.ai",
  secondaryMessage: "This might take a couple of minutes.",
  wrapperClassName: "",
  logoClassName: "",
  loaderClassName: "",
};

export const CallbackPageUI = withTranslation()(CallbackPageUIComp);
