export const FIREBASE_CONFIG_TEST = {
    apiKey: "AIzaSyD0AI-vmGwJ6My5J0E2e-dmRygQjSBtbiQ",
    authDomain: "os-login-656b5.firebaseapp.com",
    databaseURL: "https://os-login-656b5.firebaseio.com",
    projectId: "os-login-656b5",
    storageBucket: "os-login-656b5.appspot.com",
    messagingSenderId: "38377103409",
    appId: "1:38377103409:web:ca1e3ba84d0ddb917eb510"
};

export const FIREBASE_CONFIG_PROD = {
    apiKey: "AIzaSyD0AI-vmGwJ6My5J0E2e-dmRygQjSBtbiQ",
    authDomain: "os-login-656b5.firebaseapp.com",
    databaseURL: "https://os-login-656b5.firebaseio.com",
    projectId: "os-login-656b5",
    storageBucket: "os-login-656b5.appspot.com",
    messagingSenderId: "38377103409",
    appId: "1:38377103409:web:ca1e3ba84d0ddb917eb510"
};
