import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { FirebasePhoneAuth } from "@onlinesales-ai/firebase-phone-auth-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import {
  fetchUpdatedUser as fetchUpdatedUserAction,
  profileUpdate,
} from "@onlinesales-ai/app-v2/application";
import { populateEvent, fireGoogleAnalyticsEvent } from "@onlinesales-ai/util-methods-v2";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";

const fireIntercomEvents = (action, metaData) => {
  populateEvent("SETTING||SETTING_USER_PROFILE", metaData);
};

const ContactUpdate = ({
  countryCode,
  fetchUpdatedUser,
  showToastMessage,
  setAsPreferredAuthValue,
  userInfo,
  value,
  jobDoneCallback,
  isPostLoading,
  setIsPostLoading,
  modalInfoText,
  doNotCloseOnError,
  fromOnboardingStep,
}) => {
  const currentValue = value || userInfo?.contactNo;
  const userEmail = userInfo?.email;

  const onAuthDone = async (authData) => {
    const newValue = authData.countryCode + authData.phoneNumber;
    fireIntercomEvents("MOBILE||AUTH_DONE");
    setIsPostLoading(true);
    try {
      await profileUpdate({
        authProviderType: "MOBILE",
        authProviderValue: newValue,
        action: "ADD",
        authProviderData: { idToken: authData.token },
        setAsPreferredAuthValue,
      });
      if (currentValue && currentValue !== newValue) {
        await profileUpdate({
          authProviderType: "MOBILE",
          authProviderValue: `${currentValue}`,
          action: "REMOVE",
        });
      }
      await fetchUpdatedUser();
      showToastMessage({
        type: "SUCCESS",
        messageToDisplay: "Contact Number updated successfully",
        actionButtonLabel: null,
        toastDuration: 5000,
      });

      if (fromOnboardingStep) {
        fireGoogleAnalyticsEvent("User OTP Verified", {
          email: userEmail,
        });
      }

      jobDoneCallback();
    } catch (err) {
      triggerMonitor("SEV2", "CONTACT_UPDATE_FAILED", {
        error: err,
      });
      showToastMessage({
        type: "ERROR",
        messageToDisplay: err?.errorMsg,
        actionButtonLabel: null,
        toastDuration: 5000,
      });
      fireIntercomEvents("MOBILE||UPDATE_ERROR", err);
      setIsPostLoading(false);
      if (!doNotCloseOnError) {
        jobDoneCallback();
      }
      return false;
    }
    setIsPostLoading(false);
    return true;
  };

  return (
    <FirebasePhoneAuth
      signInButtonText="Verify with OTP"
      otpSentActionText="change contact number"
      isSubmitInProgress={isPostLoading}
      onAuthDone={onAuthDone}
      countryCode={countryCode}
      modalInfoText={modalInfoText}
      doNotCloseOnError={doNotCloseOnError}
    />
  );
};

ContactUpdate.defaultProps = {
  modalInfoText: "",
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchUpdatedUser: fetchUpdatedUserAction }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
  const domainConfig = state.DomainConfig || {};
  const contactUpdateConfig = domainConfig?.commonConfigs?.contactUpdateConfig;

  return {
    userInfo: state.Application.userInfo,
    ...contactUpdateConfig,
    ...ownProps,
  };
};

export default OSHOCWithUtilities(connect(mapStateToProps, mapDispatchToProps)(ContactUpdate));
