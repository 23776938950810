import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { fetchUpdatedUser } from "@onlinesales-ai/app-v2/application";

import ContactUpdate from "@onlinesales-ai/acquire-common-v2/userProfile/contactUpdate";

import "./index.less";

const UserDetailsValidator = ({
  userInfo,
  enableContactNoValidation,
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(true);
  const [isPostLoading, setIsPostLoading] = useState(false);

  const jobDoneCallback = () => {
    setShowUpdateModal(false);
  };

  const validateContact = () => {
    if (userInfo && !userInfo?.contactNo) {
      return (
        <ModalWindow
          headerTitle={<div className="header-title">Add Contact Number</div>}
          containerClass="universal-contact-modal"
          backdropClassName="universal-contact-modal-backdrop"
          mainClassName="universal-contact-modal-main"
          isShow={showUpdateModal}
          closeButton={false}
          backdrop="static"
        >
          <ModalWindow.Body>
            <ContactUpdate
              modalInfoText="Please enter and verify your contact details, so we can keep you informed about any updates."
              jobDoneCallback={jobDoneCallback}
              isPostLoading={isPostLoading}
              setIsPostLoading={setIsPostLoading}
              fromOnboardingStep
              doNotCloseOnError
            />
          </ModalWindow.Body>
        </ModalWindow>
      );
    }
  };

  return <>{enableContactNoValidation ? validateContact() : null}</>;
};

UserDetailsValidator.defaultProps = {
  enableContactNoValidation: false,
};

const mapStateToProps = (state) => {
  // const { enableContactNoValidation } = state.DomainConfig || {};

  return {
    userInfo: state.Application.userInfo,
    // enableContactNoValidation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUpdatedUser,
    },
    dispatch,
  );
};

export default OSHOCWithUtilities(
  connect(mapStateToProps, mapDispatchToProps)(UserDetailsValidator),
);
