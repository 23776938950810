import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _get from "lodash/get";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { Form } from "@onlinesales-ai/form-components-v2";
import {
  configHoc,
  pendoTrackEvent,
  fireGoogleAnalyticsEvent,
} from "@onlinesales-ai/util-methods-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { GlobalContext, SideCollapse } from "@onlinesales-ai/utils-components-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { NoteCandy } from "@onlinesales-ai/note-candy-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import formComponents from "../formComponents";

import { getEntityInfo, getEntityMetadata } from "../../utilities/methods";
import { entityTypeEnum } from "../../utilities/constants";
import GreyBoxWithSeperator from "../../utils/greyBoxWithSeperator";
import ReviewComment from "../reviewComment";

import "./index.less";

export const actionTypeMapping = {
  APPROVE: "APPROVE",
  APPROVE_NEXT: "APPROVE_NEXT",
  REJECT: "REJECT",
  UNDO_REJECT: "UNDO_REJECT",
  OPEN_CHAT: "OPEN_CHAT",
  UNDO_APPROVE: "UNDO_APPROVE",
};

export const actionTypeToStatusMapping = {
  [actionTypeMapping.APPROVE]: "APPROVED",
  [actionTypeMapping.APPROVE_NEXT]: "APPROVED",
  [actionTypeMapping.REJECT]: "REJECTED",
  [actionTypeMapping.UNDO_REJECT]: "UNDER_REVIEW",
  [actionTypeMapping.UNDO_APPROVE]: "UNDER_REVIEW",
};

const MccCreation = ({
  //props from wrapper
  jobDoneCallback,
  isSkippable,
  isExpanded,
  suggestionInfoText,
  imgUrl,
  title,
  description,
  componentToShow,
  componentConfig,
  businessDefinition,
  isBillingEntity,
  id: mccId,
  parentMCCId,
  isReadOnly: pIsReadOnly,
  isShowSuccessMsg,
  candyConfig,
  //props for API
  agencyId,
  postMccData,
  fetchEntityMetaData,
  entityInfo,
  entityMetadata,
  componentRef,
  labelColumns,
  isFormViewOnly,
  ctaText,
  shouldFetchData,
  showCTA,
  titleInfo,
  enableComment,
  entityInfoToShow,
  entityMetadataToShow,
  commentProps,
  successMsg,
  primaryCta,
  showHeader,
}) => {
  const { showToastMessage } = useContext(GlobalContext);

  const [isSkipInProgess, setIsSkipInProgess] = useState(false);
  const [isSaveInProgess, setIsSaveInProgess] = useState(false);
  const [isFetchInProgess, setIsFetchInProgess] = useState(false);
  const [entityMetadataFetchError, setEntityMetadataFetchError] = useState(false);
  const [openSideBarKey, setOpenSideBarKey] = useState(true);
  const formRef = useRef();

  const mccIdWithType = useMemo(() => {
    return `${entityTypeEnum.MCC}_${mccId}`;
  }, [mccId]);

  const fetchEntityMetaDataFromAPI = async () => {
    setIsFetchInProgess(true);
    setEntityMetadataFetchError(false);
    try {
      const config = {
        entityId: mccId,
        entityType: entityTypeEnum.MCC,
        keys: [
          "entityId",
          "entityType",
          "entityAlias",
          "metadata",
        ],
      };

      await fetchEntityMetaData(config, { parentMCCId });
    } catch (err) {
      const errorMsg = err?.errorMsg || "Error occured while fetching details.";
      setEntityMetadataFetchError(errorMsg);
    }
    setIsFetchInProgess(false);
  };

  const values = useMemo(() => {
    const entityInfoOfMCC = entityInfoToShow || getEntityInfo(entityInfo, mccIdWithType);
    const entityMetadataOfMCC =
      entityMetadataToShow || getEntityMetadata(entityMetadata, mccIdWithType);

    if (!_isEmpty(entityInfoOfMCC) && !_isEmpty(entityMetadataOfMCC)) {
      return {
        ...entityInfoOfMCC,
        metadata: entityMetadataOfMCC,
      };
    }

    return {};
  }, [entityInfo, entityMetadata, entityInfoToShow, entityMetadataToShow, mccIdWithType]);

  const [formValues, setFormValues] = useState(values || {});

  const isReadOnly = useMemo(() => {
    return (
      pIsReadOnly ||
      ((["AGENCY", "ADVERTISER"].includes(values?.metadata?.businessDefinition)) &&
      (["APPROVED", "UNDER_REVIEW"].includes(values?.metadata?.approvalStatus)))
    );
  }, [pIsReadOnly, values]);

  const fetchData = () => {
    const entityInfoOfMCC = entityInfoToShow || getEntityInfo(entityInfo, mccIdWithType);
    const entityMetadataOfMCC = entityMetadataToShow || getEntityMetadata(entityMetadata, mccIdWithType);

    if (
      shouldFetchData ||
      (mccId && (_isEmpty(entityInfoOfMCC) || _isEmpty(entityMetadataOfMCC)))
    ) {
      fetchEntityMetaDataFromAPI();
    }
  };

  useEffect(() => {
    if (mccId) {
      fetchData();
    }
  }, [mccId]);

  const getPayload = (pFormValues) => {
    const metadata = {
      businessDefinition: pFormValues?.businessDefinition || businessDefinition,
      isBillingEntity: pFormValues?.hasOwnProperty("isBillingEntity") ? pFormValues.isBillingEntity : isBillingEntity,
      businessLegalInfo: pFormValues?.businessLegalInfo,
      ...(pFormValues?.metadata || {}),
    };

    let entityData = {
      ...pFormValues,
      metadata,
    };

    entityData = _omit(entityData, [
      "statusType",
      "depth",
      "parentMCCId",
      "originalEntityId",
      "hierarchy",
      "breadCrumbText",
      "parentDepth",
    ]);

    const payload = {
      parentId: parentMCCId || agencyId,
      parentEntityType: !!parentMCCId ? entityTypeEnum.MCC : entityTypeEnum.AGENCY,
      entity: {
        ...entityData,
        entityId: mccId,
        entityType: entityTypeEnum.MCC,
        metadata,
      },
    };

    return payload;
  };

  const triggerGoogleAnalytics = (valuesToSubmit, response) => {
    const entityName = response?.entity?.entityAlias;
    const businessDefinitionInfo = response?.entity?.metadata?.businessDefinition;
    const onboardingStatus = response?.entity?.metadata?.approvalStatus;
    const billingEntity = response?.entity?.metadata?.isBillingEntity;
    let eventMessage = "";

    if (!valuesToSubmit?.entityId && response?.entity?.entityId) {
      if (businessDefinitionInfo === "AGENCY") {
        eventMessage = "Agency Created";
      } else if (businessDefinitionInfo === "ADVERTISER" && billingEntity) {
        eventMessage = "Direct Advertiser Created";
      } else {
        eventMessage = "Agency > Advertiser Created";
      }
    } else if (onboardingStatus === "PENDING") {
      if (businessDefinition === "AGENCY") {
        eventMessage = "Agency Edited";
      } else if (businessDefinitionInfo === "ADVERTISER" && billingEntity) {
        eventMessage = "Direct Advertiser Edited";
      } else {
        eventMessage = "Agency > Advertiser Edited";
      }
    } else if (onboardingStatus === "UNDER_REVIEW") {
      if (businessDefinition === "AGENCY") {
        eventMessage = "Agency Submitted for review";
      } else if (businessDefinitionInfo === "ADVERTISER" && billingEntity) {
        eventMessage = "Direct Advertiser Submitted for review";
      } else {
        eventMessage = "Agency > Advertiser Submitted for review";
      }
    }

    fireGoogleAnalyticsEvent(eventMessage, {
      name: entityName,
    });
  };

  const triggerMonitors = (err) => {
    if (businessDefinition === "AGENCY") {
      uiAPIMonitor("SEV2", "OTT_ONBOARDING_AGENCY_CREATION_FAILED", {
        error: err,
      });
    } else if (businessDefinition === "ADVERTISER" && isBillingEntity) {
      uiAPIMonitor("SEV2", "OTT_ONBOARDING_DIRECT_ADVERTISER_CREATION_FAILED", {
        error: err,
      });
    } else {
      uiAPIMonitor("SEV2", "OTT_ONBOARDING_INDIRECT_ADVERTISER_CREATION_FAILED", {
        error: err,
      });
    }
  };

  const onSubmit = async (valuesToSubmit) => {
    setIsSaveInProgess(true);
    try {
      const config = getPayload(valuesToSubmit);

      const response = await postMccData(config);

      if (!config?.entity.entityId && response?.entity) {
        PlatformEventManager.emit("MCC_CREATION", response?.entity);
      }

      await jobDoneCallback({
        isSkipped: false,
        creationResponse: {
          ...(response?.entity || {}),
          entityType: entityTypeEnum.MCC,
        },
      });

      if (response?.entity) {
        triggerGoogleAnalytics(valuesToSubmit, response);
      }

      if (isShowSuccessMsg) {
        showToastMessage({
          type: "SUCCESS",
          messageToDisplay: successMsg,
          actionButtonLabel: null,
          toastDuration: 5000,
        });
      }
    } catch (err) {
      triggerMonitors( err);
      showToastMessage({
        type: "ERROR",
        messageToDisplay: err?.errorMsg || "Error occured while saving details.",
        actionButtonLabel: null,
        toastDuration: 5000,
      });
      return Promise.reject(err);
    }
    setIsSaveInProgess(false);
  };

  const onClickSkip = async () => {
    setIsSkipInProgess(true);
    try {
      await jobDoneCallback({ isSkipped: true });
    } catch (err) {}
    setIsSkipInProgess(false);
  };

  const renderMainComponent = () => {
    return (
      <ReviewComment
        displayAsWidget
        title="Comments"
        entityData={values}
        hasMarkdownSupport
        adCreativeId={mccId}
        entityId={agencyId}
        entityLevelId={mccId}
        entityLevelType="MCC"
        hadesEntityId={mccId}
        hadesEntityType="MCC"
        entityType="AGENCY"
        className="pendo_feature_mcc_creation_block"
        {...commentProps}
      />
    );
  };

  const renderEntityCommentView = () => {
    return (
      <SideCollapse
        withBg={false}
        direction="right"
        isShowCloseButton
        maincontent={renderMainComponent}
        collapseIcon={
          <AsyncImage imgSrc="https://osads.gumlet.io/image/upload/v1679575576/product/commentIcon.svg" />
        }
        isOpen={openSideBarKey}
        setIsOpen={(isOpen) => setOpenSideBarKey(isOpen)}
        controlled
        hideLeftSectionOnOpen
      />
    );
  };

  const renderForm = ({ renderFormComponent, renderCTAButton, bodyWrapperProps, shouldRender }) => {
    const renderFormElements = () => {
      return (
        <>
          <div className="fields-wrapper">
            {shouldRender("agencyName") && renderFormComponent("agencyName")}
            {shouldRender("advertiserName") && renderFormComponent("advertiserName")}
            {shouldRender("advertiserLogo") && renderFormComponent("advertiserLogo")}
            {shouldRender("email") && renderFormComponent("email")}
            {shouldRender("gstNumber") && renderFormComponent("gstNumber")}
            {shouldRender("gstCertificate") && renderFormComponent("gstCertificate")}
            {shouldRender("panNumber") && renderFormComponent("panNumber")}
            {shouldRender("panCard") && renderFormComponent("panCard")}
            {shouldRender("cinNumber") && renderFormComponent("cinNumber")}
          </div>
          <div>{enableComment && renderEntityCommentView()}</div>
        </>
      );
    };

    const renderFormButtons = () => {
      return (
        <>
          {isSkippable && !mccId ? (
            <Button
              isLoading={isSkipInProgess}
              disabled={isSkipInProgess || isSaveInProgess}
              onClick={onClickSkip}
              link
            >
              Skip for now
            </Button>
          ) : null}
          {showCTA ? renderCTAButton() : null}
        </>
      );
    };

    return (
      <div {...bodyWrapperProps}>
        {(titleInfo?.image || titleInfo?.title) && (
          <div className="title-info d-align-center mt-3">
            <AsyncImage imgClassName="mr-2" imgSrc={titleInfo?.image} />
            {titleInfo?.title}
          </div>
        )}
        <GreyBoxWithSeperator
          isFormViewOnly={isFormViewOnly}
          renderTitleSection={() => (
            <>
              {showHeader && (
                <div className="title">
                  <AsyncImage imgSrc={imgUrl} />
                  <span>{title}</span>
                </div>
              )}
              <div className="sub-title">{description}</div>
            </>
          )}
          showBottom={false}
          renderTopSection={isExpanded ? renderFormElements : null}
          renderSubmitButton={isExpanded && !isReadOnly ? renderFormButtons : null}
          isLoading={isFetchInProgess}
          errorMsg={entityMetadataFetchError}
        />
        {isExpanded && suggestionInfoText && !mccId ? (
          <div className="suggestion-info-text">{suggestionInfoText}</div>
        ) : null}
        {
          candyConfig?.isShow && (
            <NoteCandy
              variant="medium"
              text={candyConfig?.candyDescription}
              useIconImg={false}
              wrapperClass="mb-3"
            />
          )
        }
      </div>
    );
  };

  const hasDirtyData = () => {
    return !_isEmpty(formValues);
  };

  useEffect(() => {
    if (componentRef) {
      componentRef.current = {
        hasDirtyData,
      };

      return () => {
        componentRef.current = {};
      };
    }
  }, [formValues]);

  const ctaTextToShow = useMemo(() => {
    if (mccId) {
      return "Save";
    }
    if (ctaText) {
      return ctaText;
    }
  }, [ctaText, mccId]);

  return (
    <>
      <div className="mcc-wrapper">
        <Form
          componentToShow={componentToShow}
          componentConfig={componentConfig}
          formComponents={formComponents}
          values={values}
          onSubmit={onSubmit}
          customFormRenderer={renderForm}
          formRef={formRef}
          labelColumns={labelColumns}
          isEditable={!isReadOnly}
          doNotShowCta={isReadOnly}
          onFieldChange={setFormValues}
          ctaText={ctaTextToShow}
          ctaButtonProps={{
            outline: !isFormViewOnly && !primaryCta,
            className: "pendo_feature_ott_save_business_details",
          }}
        />
      </div>
    </>
  );
};

MccCreation.defaultProps = {
  jobDoneCallback: () => Promise.resolve(),
  isShowSuccessMsg: false,
  successMsg: "Details saved successfully.",
  ctaText: "Save",
  shouldFetchData: false,
  showCTA: true,
  isReadOnly: false,
  showHeader: true,
};

export default (OSHOCWithUtilities(configHoc(MccCreation)));
