import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";

import { RadioGroupTab } from "@onlinesales-ai/radio-v2";
import { BaseClient } from "@onlinesales-ai/services-v2";
import { CallbackPageUI } from "@onlinesales-ai/callback-page-v2";
import { Steps, Step } from "@onlinesales-ai/steps-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { customMergeOS, configHoc } from "@onlinesales-ai/util-methods-v2";
import { ONBOARDING_CARD_STATUS, ONBOARDINGSTATUS } from "@onlinesales-ai/constants-v2";

import {
  // advertiserDefaultConfig,
  // agencyDefaultConfig,
  // brandDefaultConfig,
  overrideByOption as defaultOverrideByOption,
  entityTypeEnum,
  businessTypeEnum,
  newAgencyDefaultConfig,
  newAdvertiserDefaultConfig,
} from "@onlinesales-ai/ott-common-v2";

import { routes } from "src/utilities/constants";
import {
  redirectUrl as redirectUrlAction,
  postMccData as postMccDataAction,
  changeEntityId as changeEntityIdAction,
} from "src/store/application";

import MccCreation from "./mccCreation";
import BrandCreation from "./brandCreation";
import { useOnboardingWidget } from "../utils";

import "./index.less";

const COMPONENTS = {
  AGENCY: MccCreation,
  ADVERTISER: MccCreation,
  BRAND: BrandCreation,
};

const Hotstar = ({
  showConfirmationModal,
  resetConfirmationModal,
  fallbackMCCId,
  businessOptions,
  stepsConfigByOption,
  overrideByOption,
  showToastMessage,
  onboardingPostErrorMsg,
  storeOnboardingDetails,
  agencyId,
  postMccDataFromRedux,
  postMccData,
  redirectUrl,
  isSetNewEntityIdAsActive,
  onEntityChange,
  componentProps,
  jobDoneCTA,
  containerClass,
}) => {
  const postMccDataToUse = postMccData || postMccDataFromRedux;
  const [businessType, setBusinessType] = useState(
    storeOnboardingDetails?.option || businessOptions[0].value,
  );
  const { t } = useTranslation();
  const { stepsConfig, stepsToShow } = useMemo(() => {
    const preparedConfig = customMergeOS({}, { ...stepsConfigByOption }, overrideByOption || {});
    return {
      stepsConfig: preparedConfig?.[businessType]?.stepsConfig,
      stepsToShow: preparedConfig?.[businessType]?.steps,
    };
  }, [businessType, stepsConfigByOption]);

  const { cardsToRender, initialCardsState } = useOnboardingWidget({
    cardsConfig: stepsConfig,
    cards: stepsToShow,
  });
  const [cardsState, setCardsState] = useState(initialCardsState || {});
  const [cardToOpen, setCardToOpen] = useState({
    currentCardToOpen: null,
    currentCardIndex: null,
  });
  const [firstCardMccId, setFirstCardMccId] = useState(null);
  const agencyComponentRef = useRef({});
  const advertiserComponentRef = useRef({});

  useEffect(() => {
    const onboardingStates = storeOnboardingDetails || {};
    const updatedCardsState = {
      ...cardsState,
      ...onboardingStates.steps,
    };

    let currentCardToOpen = null;
    let currentCardIndex = null;

    for (let i = 0; i < cardsToRender.length; i++) {
      const cardKey = cardsToRender[i].key;
      if (
        !currentCardToOpen &&
        updatedCardsState[cardKey]?.status !== ONBOARDING_CARD_STATUS.COMPLETED
      ) {
        currentCardToOpen = cardKey;
        currentCardIndex = i + 1;
      }

      if (i === 0) {
        setFirstCardMccId(updatedCardsState?.[cardsToRender?.[i]?.key]?.id);
      }
    }

    setCardsState(updatedCardsState);
    setCardToOpen({
      currentCardToOpen: currentCardToOpen || cardsToRender?.[cardsToRender.length - 1]?.key,
      currentCardIndex: currentCardIndex || cardsToRender.length,
    });
  }, [storeOnboardingDetails, businessType]);

  const getComponentRef = () => {
    let refToUse = null;

    if (businessType === businessTypeEnum.AGENCY) {
      refToUse = agencyComponentRef;
    } else {
      refToUse = advertiserComponentRef;
    }

    return refToUse;
  };

  const onChangeBusiness = (val) => {
    const ref = getComponentRef();
    if (typeof ref?.current?.hasDirtyData === "function") {
      const hasDirtyData = ref?.current?.hasDirtyData();
      if (hasDirtyData) {
        showConfirmationModal({
          isShow: true,
          title: t("Your data is not saved yet. Are you sure want to change this?"),
          actionBtnText: "Yes",
          rightBtnText: "Cancel",
          actionBtnCallback: () => {
            resetConfirmationModal();
            setBusinessType(val);
          },
          rightBtnCallback: () => {
            resetConfirmationModal();
          },
        });
      } else {
        setBusinessType(val);
      }
    }
  };

  const onCardTobDone = async ({
    cardKey,
    stepJobdoneCallBack,
    nextScrollId,
    cardIndex,
    isSkipped,
    creationResponse,
  }) => {
    const vOnboardingDetails = _cloneDeep(storeOnboardingDetails) || {};
    const vCardsState = _cloneDeep(cardsState) || {};
    const isFirstCard = cardsToRender[0].key === cardKey;
    let newEntityId = null;
    let newEntityType = null;
    let extraDetails = {};

    const isLastCard = cardsToRender?.[cardsToRender.length - 1].key === cardKey;

    if (!vCardsState?.[cardKey]?.id && !!creationResponse?.entityId && !!creationResponse?.entityType) {
      if (isSetNewEntityIdAsActive) {
        newEntityId = creationResponse.entityId;
        newEntityType = creationResponse.entityType;
      }

      extraDetails.stepKey = cardKey;
      extraDetails.newEntityId = creationResponse.entityId;
      extraDetails.newEntityType = creationResponse.entityType;
    } else if (
      isSkipped &&
      cardIndex > 0 &&
      cardsToRender?.[cardIndex - 1]?.key &&
      cardsState?.[cardsToRender?.[cardIndex - 1]?.key]?.id
    ) {
      if (isSetNewEntityIdAsActive) {
        newEntityId = cardsState?.[cardsToRender?.[cardIndex - 1]?.key]?.id;
        newEntityType = entityTypeEnum.MCC;
      }
    }

    if (vCardsState?.[cardKey]?.status !== ONBOARDING_CARD_STATUS.COMPLETED || isLastCard) {
      if (isSkipped) {
        vCardsState[cardKey] = {
          status: ONBOARDING_CARD_STATUS.SKIPPED,
        };
      } else {
        vCardsState[cardKey] = {
          id: creationResponse?.entityId,
          status: ONBOARDING_CARD_STATUS.COMPLETED,
        };
      }
      vOnboardingDetails.steps = vCardsState;

      vOnboardingDetails.option = businessType;

      if (isLastCard || isSkipped) {
        vOnboardingDetails.finalStatus = ONBOARDINGSTATUS.ACTIVE;
      } else {
        vOnboardingDetails.finalStatus = ONBOARDINGSTATUS.IN_PROGRESS;
      }

      const payload = {
        entity: {
          entityType: entityTypeEnum.MCC,
          metadata: {
            onboardingData: vOnboardingDetails,
          },
        },
      };

      if (isFirstCard) {
        payload.entity.entityId = creationResponse?.entityId;
      } else {
        payload.entity.entityId = firstCardMccId;
      }

      try {
        await postMccDataToUse(payload, true, extraDetails);
        if (!isSkipped && !isLastCard) {
          stepJobdoneCallBack();
          const $scrollTo = document.getElementById(nextScrollId);
          setTimeout(() => {
            $scrollTo?.scrollIntoView({
              behavior: "smooth",
            });
          }, 500);
        } else {
          if (newEntityId && newEntityType) {
            onEntityChange(newEntityId, newEntityType);
          }
        }
      } catch (e) {
        showToastMessage({
          messageToDisplay: onboardingPostErrorMsg,
          type: "ERROR",
          toastDuration: 5000,
        });
      }
    } else {
      const $scrollTo = document.getElementById(nextScrollId);
      setTimeout(() => {
        $scrollTo?.scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    }
  };

  if (!cardsToRender.length) {
    return <CallbackPageUI isError errorMsg={BaseClient.getErrorMessage()} />;
  }

  return (
    <div className={`hotstar-onboarding-component ${containerClass}`}>
      {businessOptions?.length > 1 && (
        <RadioGroupTab
          name="Business Type"
          groupValue={businessType}
          data={businessOptions}
          onChange={onChangeBusiness}
          layout="horizontal"
          mainLabel="Which best describes your business?"
          tabsWrapperClass="agency-advertiser-tabs-wrapper"
          onlyBorder
          disabled={!_isEmpty(storeOnboardingDetails?.option)}
        />
      )}
      <Steps totalSteps={cardsToRender?.length} activeIndex={cardToOpen?.currentCardIndex} className="onboarding-step-wrapper">
        {cardsToRender?.map(({ key, props }, index) => {
          const Comp = COMPONENTS?.[key];
          return (
            <Step
              id={props?.id}
              showStepNumber={cardsToRender?.length > 1}
            >
              {({ jobDone }) => {
                return (
                  <Comp
                    ctaText={(cardsToRender.length === 1 || index === cardsToRender.length - 1) ? props?.jobDoneCTA || jobDoneCTA : "Next"}
                    {...props}
                    {...(componentProps || {})}
                    businessType={businessType}
                    componentRef={
                      businessType === businessTypeEnum.AGENCY ?
                        agencyComponentRef :
                        advertiserComponentRef
                    }
                    controlled
                    isExpanded={
                      cardToOpen.currentCardToOpen === key ||
                      cardsState[key]?.status === ONBOARDING_CARD_STATUS.COMPLETED
                    }
                    jobDoneCallback={({ isSkipped, creationResponse, ...rest }) => onCardTobDone(
                      {
                        cardKey: key,
                        stepJobdoneCallBack: jobDone,
                        nextScrollId: props?.nextScrollId,
                        cardIndex: index,
                        isSkipped,
                        creationResponse,
                        ...rest,
                      },
                    )}
                    parentMCCId={cardsState?.[cardsToRender?.[index - 1]?.key]?.id || fallbackMCCId}
                    key={key}
                    id={cardsState?.[key]?.id}
                  />
                );
              }}
            </Step>
          );
        })}
      </Steps>
    </div>
  );
};

const HotstarWrapper = configHoc(Hotstar);

HotstarWrapper.defaultProps = {
  isSetNewEntityIdAsActive: true,
  jobDoneCTA: "Done",
  defaultConfigProps: {
    businessOptions: [
      {
        value: "AGENCY",
        label: "Agency",
        description: "We’re an agency buying ads on behalf of an advertiser",
        iconUrl:
          "https://osads.gumlet.io/image/upload/v1679461173/product/office-building.svg",
      },
      {
        value: "ADVERTISER",
        label: "Advertiser",
        description: "We’re buying ads for our own business",
        iconUrl:
          "https://osads.gumlet.io/image/upload/v1679461173/product/businessman.svg",
      },
    ],
    // stepsConfigByOption: {
    //   AGENCY: {
    //     steps: ["AGENCY", "ADVERTISER", "BRAND"],
    //     stepsConfig: {
    //       AGENCY: {
    //         ...agencyDefaultConfig,
    //       },
    //       ADVERTISER: {
    //         ...advertiserDefaultConfig,
    //       },
    //       BRAND: {
    //         ...brandDefaultConfig,
    //       },
    //     },
    //   },
    //   ADVERTISER: {
    //     steps: ["ADVERTISER", "BRAND"],
    //     stepsConfig: {
    //       ADVERTISER: {
    //         ...advertiserDefaultConfig,
    //       },
    //       BRAND: {
    //         ...brandDefaultConfig,
    //       },
    //     },
    //   },
    // },
    overrideByOption: defaultOverrideByOption,
    stepsConfigByOption: {
      AGENCY: {
        steps: ["AGENCY"],
        stepsConfig: {
          AGENCY: {
            ...customMergeOS(
              {},
              newAgencyDefaultConfig.defaultConfig || {},
              newAgencyDefaultConfig.overideConfig || {},
            ),
          },
        },
      },
      ADVERTISER: {
        steps: ["ADVERTISER"],
        stepsConfig: {
          ADVERTISER: {
            ...customMergeOS(
              {},
              newAdvertiserDefaultConfig.defaultConfig || {},
              newAdvertiserDefaultConfig.overideConfig || {},
            ),
          },
        },
      },
    },
    onboardingPostErrorMsg:
      "Something is not right. Please, retry after sometime or get in touch with our team",
  },
  containerClass: "new-onboarding",
};

const mapStateToProps = (state, ownProps) => {
  const domainConfig = state.DomainConfig || {};
  const { isMobile, agencyId } = state.Application || {};
  const { data: storeOnboardingDetails = {} } = state.OnBoarding || {};

  const onboardingDomainConfig = domainConfig[routes.ONBOARDING.path] || {};

  return {
    isMobile,
    storeOnboardingDetails,
    agencyId,
    ...onboardingDomainConfig,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postMccDataFromRedux: postMccDataAction,
      redirectUrl: redirectUrlAction,
      onEntityChange: changeEntityIdAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OSHOCWithUtilities(HotstarWrapper));
