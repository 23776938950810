import React, {
  useState, useRef, useEffect, useMemo,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import Firebase from "firebase/app";
import "firebase/auth";

import { Button } from "@onlinesales-ai/button-v2";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { NoteCandy } from "@onlinesales-ai/note-candy-v2";
import { Text } from "@onlinesales-ai/label-v2";

import { FIREBASE_CONFIG_TEST, FIREBASE_CONFIG_PROD } from "./config";
import "./index.less";

Firebase.initializeApp(NODE_ENV === "development" ? FIREBASE_CONFIG_TEST : FIREBASE_CONFIG_PROD);
const auth = Firebase.auth();

const fireIntercomEvents = (action, metaData) => {
  populateEvent("FIREBASE", `FIREBASE||${action}`, metaData);
};

const FirebasePhoneLogin = ({
  isSubmitInProgress = false,
  showToastMessage = () => {},
  onAuthDone = () => {},
  countryCode = "+91",
  signInButtonText = "Sign in with OTP",
  otpSentActionText = "get started with a different number",
  modalInfoText,
  doNotCloseOnError,
}) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [isOTPSendInProgress, setIsOTPSendInProgress] = useState(false);
  const [otp, setOTP] = useState("");
  const [isOTPVerificationInProgress, setIsOTPVerificationInProgress] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const recaptchaVerifier = useRef(null);
  const recaptchaVerifierDOM = useRef(null);

  useEffect(() => {
    if (recaptchaVerifierDOM?.current && !recaptchaVerifier?.current) {
      recaptchaVerifier.current = new Firebase.auth.RecaptchaVerifier(
        recaptchaVerifierDOM.current,
        {
          size: "invisible",
          callback: () => {},
        },
      );

      try {
        recaptchaVerifier.current.render().then(() => {});
      } catch (e) {}
    }
  });

  const isPhoneNumberValid = () => {
    const pattern = /^\+[0-9\s\-()]+$/;
    return phoneNumber && `${countryCode}${phoneNumber}`.search(pattern) !== -1;
  };

  const isOtpValid = () => {
    const pattern = /^[0-9]{4,6}$/;
    return otp.search(pattern) !== -1;
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
  };

  const onConfirmationResultRemove = () => {
    setConfirmationResult(null);
  };

  const onKeyDown = (e) => {
    const invalidNumberChars = ["-", "+", "e", "E"];

    if (invalidNumberChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const getErrorCodeToMessage = (code) => {
    const errorMap = {
      "auth/too-many-requests": "Too many attempts, Please try again later",
      "auth/code-expired": "OTP has expired, Please request OTP again",
      "auth/invalid-verification-code": "Invalid OTP, Please enter correct OTP",
      "auth/network-request-failed":
        "We encountered network error,  please check your network or try after sometime",
    };

    return code ? errorMap[code] : null;
  };

  const onSendOTP = () => {
    setIsShowError(true);
    if (isPhoneNumberValid()) {
      setIsOTPSendInProgress(true);
      setOTP("");
      auth
        .signInWithPhoneNumber(`${countryCode}${phoneNumber}`, recaptchaVerifier?.current)
        .then((pConfirmationResult) => {
          fireIntercomEvents("SEND_OTP_SUCCESS", {
            phone: `${countryCode}${phoneNumber}`,
          });
          setIsOTPSendInProgress(false);
          setConfirmationResult(pConfirmationResult);
          showToastMessage({
            type: "SUCCESS",
            messageToDisplay: t(`OTP has been sent to {{phoneNumber}}`, {
              phoneNumber: `${countryCode}${phoneNumber}`,
            }),
            actionButtonLabel: null,
            toastDuration: 8000,
          });
        })
        .catch((error) => {
          fireIntercomEvents("SEND_OTP_ERROR", { phone: `${countryCode}${phoneNumber}`, error });
          setIsOTPSendInProgress(false);
          showToastMessage({
            type: "ERROR",
            messageToDisplay: t(
              getErrorCodeToMessage(error?.code) || `Error while sending OTP to {{phoneNumber}}`,
              {
                phoneNumber: `${countryCode}${phoneNumber}`,
              },
            ),
            actionButtonLabel: null,
            toastDuration: 8000,
          });
        });
    }
  };

  const onSubmitOTP = () => {
    if (!isOtpValid()) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: t("Please enter valid OTP"),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
      return;
    }

    setIsOTPVerificationInProgress(true);
    confirmationResult
      .confirm(otp)
      .then(async (result) => {
        // User signed in successfully.
        fireIntercomEvents("OTP_SUBMIT_SUCCESS", {
          phone: `${countryCode}${phoneNumber}`,
          otp,
        });
        const idToken = await result.user.getIdToken(true);
        setIsOTPVerificationInProgress(false);

        const response = await onAuthDone({
          token: idToken,
          countryCode,
          phoneNumber,
        });

        if (doNotCloseOnError && !response) {
          setPhoneNumber("");
          setIsShowError(false);
          onConfirmationResultRemove();
        }
      })
      .catch((error) => {
        fireIntercomEvents("OTP_SUBMIT_ERROR", {
          phone: `${countryCode}${phoneNumber}`,
          otp,
          error: typeof error === "object" ? JSON.stringify(error) : error,
        });
        setIsOTPVerificationInProgress(false);
        showToastMessage({
          type: "ERROR",
          messageToDisplay:
            t(getErrorCodeToMessage(error.code)) ||
            `OTP verification has failed, Please try again!`,
          actionButtonLabel: null,
          toastDuration: 8000,
        });
      });
  };

  const getOTPDOM = () => {
    return (
      <>
        <div className="form-group input-form-group">
          <input
            type="number"
            value={otp}
            onChange={handleOTPChange}
            className="form-control"
            placeholder={t("Enter OTP")}
          />
        </div>
        <div className="resend-otp-link">
          <Trans>
            <Text className="d-block">OTP has been sent to <Text weight="semiBold">{countryCode} {phoneNumber}.</Text></Text>
            <a href="javascript:void(0)" onClick={onConfirmationResultRemove}>
              Click here
            </a>
            <Text>&nbsp;to {otpSentActionText}.</Text>
          </Trans>
        </div>
        <div className="button-wrapper">
          <Button
            disabled={isOTPVerificationInProgress || isSubmitInProgress}
            isLoading={isOTPVerificationInProgress || isSubmitInProgress}
            onClick={onSubmitOTP}
            className="pendo_feature_firebase_auth_otp_submit"
          >
            Submit
          </Button>
          <Button
            type="primary"
            link
            disabled={isOTPSendInProgress}
            isLoading={isOTPSendInProgress}
            onClick={onSendOTP}
            className="pendo_feature_firebase_auth_re_send_otp_button"
          >
            Resend OTP
          </Button>
        </div>
      </>
    );
  };

  const errorMsg = useMemo(() => {
    if (!phoneNumber) {
      return "Please enter phone number";
    } else if (!isPhoneNumberValid()) {
      return "Please enter valid phone number";
    }
  }, [phoneNumber]);

  const getPhoneNumberDOM = () => {
    return (
      <>
        {modalInfoText && (
          <NoteCandy
            borderType="dashed"
            variant="default"
            type="default"
            wrapperClass="modal-info-text mb-4"
            clickableCandy={false}
            text={modalInfoText}
            useIconImg={false}
          />
        )}
        <div className="form-group input-form-group contact-number-form-group">
          <div>
            <span className="country-code">{countryCode}</span>
            <input
              type="number"
              value={phoneNumber}
              onKeyDown={onKeyDown}
              onChange={handlePhoneNumberChange}
              className="form-control"
              placeholder={t("Enter your contact number")}
            />
          </div>
          <div className="error-msg">{isShowError && errorMsg}</div>
        </div>
        <div className="button-wrapper">
          <Button
            disabled={isOTPSendInProgress}
            isLoading={isOTPSendInProgress}
            onClick={onSendOTP}
            block
            className="pendo_feature_firebase_auth_otp_send_button"
          >
            {signInButtonText}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="right-section">
      <div className="phone-auth-container">
        <div className="captcha-verifier-container" ref={recaptchaVerifierDOM} />
        {!confirmationResult ? getPhoneNumberDOM() : getOTPDOM()}
      </div>
    </div>
  );
};

Firebase.defaultProps = {
  doNotCloseOnError: false,
};

export default OSHOCWithUtilities(FirebasePhoneLogin, null, {
  fragment: true,
});
