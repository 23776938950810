import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _uniqWith from "lodash/uniqWith";
import _debounce from "lodash/debounce";
import classnames from "classnames";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { BaseClient, CreativeUploadService, InceptionService, OsPackageSvc } from "@onlinesales-ai/services-v2";
import { InputField, TagInput } from "@onlinesales-ai/form-components-v2";
import { FilterButton, FILTER_COMPONENT_TYPES } from "@onlinesales-ai/filter-v2";
import { Pill } from "@onlinesales-ai/pill-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { MediaMosaicView } from "@onlinesales-ai/media-mosaic-view-v2";
import { formatNumberInAbbreviations, validateValue, sanitizePayload, getNewDate, customMergeOS } from "@onlinesales-ai/util-methods-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { OverlayLoader } from "@onlinesales-ai/loader-v2";
import { Text } from "@onlinesales-ai/label-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { LabelValuePair } from "@onlinesales-ai/utils-components-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import TemplateSelection from "../../../templateSelection/wrapper";

import Upload from "../../upload";

import "./index.less";

const LIMIT = 20;

const statusDom = {
  UPLOADED: (
    <WithTooltip placement="top" title="Processing">
      <span className="statuc-icon icon icon-clock" />
    </WithTooltip>
  ),
  READY: (
    <WithTooltip placement="top" title="Approved">
      <span className="creative-approved-image">
        <AsyncImage
          imgClassName="approved-image"
          imgSrc="https://osads.gumlet.io/image/authenticated/s--K-YePsXW--/v1641393177/product/checkBadge.svg"
        />
      </span>
    </WithTooltip>
  ),
};

const inputValidations = [
  {
    type: "maxLength",
    value: 50,
    msg: "Please enter tags of length less than 50",
  },
];

const creativeReviewAPI = {
  TVING_VIDEO_CREATIVE_APPROVAL: "TVING_VIDEO_CREATIVE_APPROVAL",
};

const CreativeSelection = ({
  clientId,
  application,
  mediaType,
  defaultCreativeStatus,
  defaultDurationFilter,
  defaultCreativeLanguage,
  isSelectable,
  onSelect,
  selectedData,
  title,
  mediaConfig,
  uploadFile,
  filterListConfig: pFilterListConfig,
  defaultFilters,
  filtersToShow,
  subType,
  showToastMessage,
  height: pHeight,
  width: pWidth,
  uploadTags,
  creativeTag,
  useCustomCreativeFetch,
  showCreativeStatus,
  disalbedStatus,
  mosaicProps,
  validationMessage: pValidationMessage,
  enableTemplateSelection,
  beforeFileUpload,
  customStatusDom,
  enableAspectRatio,
  hideNoPreview,
  showMediaName,
  enableCreativeReview,
  tooltipInfoForApprovedCreative,
  creativeReviewAPIKey,
  extraPayloadForCreativeReview,
  showCreativeReviewStatus,
  showConfirmationModal,
  resetConfirmationModal,
  headerClassName,
  mediaFileConversionConstants,
}) => {
  const { maxFileSizeInMB, minFileSizeInMB } = uploadFile || {};
  const { ONE_MB_IN_BYTES = 1000000, ONE_KB_IN_BYTES = 1000 } = mediaFileConversionConstants || {};

  const tagInputRef = useRef();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mediaList, setMediaList] = useState([]);
  const [fetchState, setFetchState] = useState({
    isLoading: true,
    errorMsg: null,
    offset: 0,
    haveMore: true,
  });
  const isFirstTime = useRef(true);
  const [filterListConfig, setFilterListConfig] = useState(() => {
    const dataToRetn = {};
    filtersToShow.forEach((key) => {
      dataToRetn[key] = pFilterListConfig[key];
    });
    return dataToRetn;
  });
  const [searchQuery, setSearchQuery] = useState("");
  const timestamp = useRef(() => new Date().valueOf());
  const [tagsEditModalDetails, setTagsEditModalDetails] = useState({ sShow: false });
  const [isTagsSaveInProgress, setIsTagsSaveInProgress] = useState(false);

  const setInitialFilterValues = () => {
    const filtersToSet = { ...defaultFilters };

    if (mediaType) {
      filtersToSet.type = mediaType;
    }

    if (pWidth && pHeight) {
      filtersToSet.dimensions = `${pWidth} x ${pHeight}`;
    }

    if (maxFileSizeInMB || minFileSizeInMB) {
      filtersToSet.size = {};
    }

    if (maxFileSizeInMB) {
      filtersToSet.size.less_than = maxFileSizeInMB;
    }

    if (minFileSizeInMB) {
      filtersToSet.size.greater_than = minFileSizeInMB;
    }

    if (defaultCreativeStatus) {
      filtersToSet.status = defaultCreativeStatus;
    }

    if (defaultDurationFilter) {
      filtersToSet.duration = defaultDurationFilter;
    }

    if (defaultCreativeLanguage) {
      filtersToSet.language = [defaultCreativeLanguage];
    }

    return filtersToSet;
  };

  const [filters, setFilters] = useState(setInitialFilterValues);

  const fetchData = async (isFetchNextPage = false) => {
    try {
      const payload = {
        clientId,
        tag: "creative_user_upload",
        // type: filters?.type || mediaType,
        limit: LIMIT,
        sortBy: { createdAt: "desc" },
        offset: isFetchNextPage ? fetchState.offset : 0,
        customFilters: {},
      };

      // if (payload?.type === "MEDIA" |) {
      //   payload.type = ["IMAGE", "VIDEO"];
      // }

      if (searchQuery) {
        payload.userTags = { "$regex": searchQuery, $options: "$i" };
      }

      if (filters?.dimensions) {
        const dimensionSplit = filters.dimensions.split(" x ");
        if (dimensionSplit.length > 1) {
          payload.width = payload.width || dimensionSplit[0];
          payload.height = payload.height || dimensionSplit[1];
        }
      } else {
        delete payload.width;
        delete payload.height;
      }
      if (filters?.type) {
        if (!payload.type) {
          payload.type = {};
        }
        if (filters?.type === "MEDIA") {
          payload.type.$in = ["IMAGE", "VIDEO"];
        } else {
          payload.type.$in = filters?.type;
        }
      }

      if (filters?.size?.less_than) {
        if (!payload.sizeInBytes) {
          payload.sizeInBytes = {};
        }
        payload.sizeInBytes.$lte = filters?.size?.less_than * ONE_MB_IN_BYTES;
      }

      if (filters?.size?.greater_than) {
        if (!payload.sizeInBytes) {
          payload.sizeInBytes = {};
        }
        payload.sizeInBytes.$gte = filters?.size?.greater_than * ONE_MB_IN_BYTES;
      }

      if (!_isEmpty(filters?.status)) {
        payload.customFilters.status = { $in: filters?.status };
      }
      if (!_isEmpty(filters?.approvalStatus)) {
        payload.customFilters["metadata.approvalStatus"] = { $in: filters?.approvalStatus };
      }

      if (!_isEmpty(filters?.language)) {
        payload.customFilters["metadata.language"] = { $in: filters?.language };
      }

      if (filters?.duration?.less_than) {
        if (!payload.customFilters["metadata.duration"]) {
          payload.customFilters["metadata.duration"] = {};
        }
        payload.customFilters["metadata.duration"].$lte = filters?.duration?.less_than;
      }

      if (filters?.duration?.greater_than) {
        if (!payload.customFilters["metadata.duration"]) {
          payload.customFilters["metadata.duration"] = {};
        }
        payload.customFilters["metadata.duration"].$gte = filters?.duration?.greater_than;
      }

      if (Array.isArray(filters?.reviewStatus)) {
        payload.customFilters["metadata.approvalDetails.isAlreadyApproved"] = {
          $in: filters?.reviewStatus,
        };
      }

      setFetchState({
        offset: isFetchNextPage ? fetchState.offset : 0,
        haveMore: isFetchNextPage ? fetchState.haveMore : true,
        isLoading: true,
        errorMsg: null,
      });

      const customData = await CreativeUploadService.fetchCreativeImages(
        sanitizePayload({
          payload,
          filterKey: "customFilters",
          keysToInclude: ["width", "height", "sizeInBytes", "userTags", "type"],
        }),
        application,
        {
          abortId: `CREATIVE_LIBRARY_LIST_FETCH_${timestamp}`,
        },
      );

      isFirstTime.current = false;

      let updatedMediaList = [];

      if (isFetchNextPage) {
        updatedMediaList = [
          ...mediaList,
          ...(customData?.creatives || []),
        ];
      } else {
        updatedMediaList = [
          ...(customData?.creatives || []),
        ];
      }

      setMediaList(updatedMediaList);

      setFetchState((prev) => ({
        isLoading: false,
        errorMsg: null,
        offset: prev.offset + LIMIT,
        haveMore: customData?.creatives?.length === LIMIT,
      }));
    } catch (err) {
      if (!err.isAborted) {
        setFetchState({
          ...fetchState,
          isLoading: false,
          errorMsg: err.errorMsg,
        });
      }
    }
  };

  useEffect(() => {
    PlatformEventManager.on("REFRESH_CREATIVE_LIBRARY", fetchData);
    return () => {
      PlatformEventManager.off("REFRESH_CREATIVE_LIBRARY", fetchData);
    };
  }, [filters, searchQuery, clientId]);

  const fetchDataWithDebounce = useCallback(
    _debounce(fetchData, 200),
    [
      searchQuery,
      mediaType,
      filters,
      clientId,
    ],
  );

  const fetchUniqueDimensions = () => {
    const filtersToUse = [];
    if (mediaType) {
      if (mediaType === "MEDIA") {
        filtersToUse.push({
          column: "type",
          operator: "IN",
          values: ["IMAGE", "VIDEO"],
        });
      } else {
        filtersToUse.push({
          column: "type",
          operator: "IN",
          values: [mediaType],
        });
      }
    }
    const payload = {
      clientId,
      filters: filtersToUse,
    };

    return CreativeUploadService.groupByCreatives(payload);
  };

  const fetchFilterMetadata = () => {
    Object.keys(filterListConfig).forEach(async (key) => {
      const filterConfig = filterListConfig[key];
      switch (filterConfig.apiFetchKey) {
        case "UNIQUE_DIMENSIONS": {
          setFilterListConfig((old) => ({
            ...old,
            [key]: {
              ...filterConfig,
              isDataFetchInProgress: true,
            },
          }));

          try {
            const response = await fetchUniqueDimensions();

            const dimensionList = _uniqWith(
              response?.groupFilters,
              (val1, val2) => val1.height === val2.height && val1.width === val2.width,
            ).map((val) => {
              return {
                label: `${val.width} x ${val.height}`,
                value: `${val.width} x ${val.height}`,
                data: {
                  ...val,
                },
              };
            });

            setFilterListConfig((old) => ({
              ...old,
              [key]: {
                ...filterConfig,
                isDataFetchInProgress: false,
                componentMetaData: {
                  ...(filterConfig.componentMetaData || {}),
                  items: dimensionList,
                },
              },
            }));
          } catch (e) {
            setFilterListConfig((old) => ({
              ...old,
              [key]: {
                ...filterConfig,
                isDataFetchInProgress: false,
              },
            }));
          }
        }
          break;
        case "HS_CREATIVE_LANGUAGES": {
          setFilterListConfig((old) => ({
            ...old,
            [key]: {
              ...filterConfig,
              isDataFetchInProgress: true,
            },
          }));

          try {
            const res = await InceptionService.fetchCreativeUploadLanguageOptions({
              clientId,
            });

            const languageOptionConfig =
              res?.data?.map?.((val) => ({
                label: val?.name,
                value: val?.name,
              })) || [];

            setFilterListConfig((old) => ({
              ...old,
              [key]: {
                ...filterConfig,
                isDataFetchInProgress: false,
                componentMetaData: {
                  ...(filterConfig.componentMetaData || {}),
                  items: languageOptionConfig,
                },
              },
            }));
          } catch (e) {
            setFilterListConfig((old) => ({
              ...old,
              [key]: {
                ...filterConfig,
                isDataFetchInProgress: false,
              },
            }));
          }
        }
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    fetchFilterMetadata();
  }, []);

  useEffect(() => {
    isFirstTime.current = true;
    setFetchState({
      ...fetchState,
      isLoading: true,
    });
    setMediaList([]);
    fetchDataWithDebounce();
  }, [filters, searchQuery, clientId]);

  const onFilterValueChange = (values = {}) => {
    if (!_isEmpty(values)) {
      // when clicked on apply
      setFilters({ ...values });
    } else {
      // when clicked on clear All
      setFilters({ ...defaultFilters });
    }
  };

  const onEditUserTags = (e, mediaDetails, index) => {
    e.stopPropagation();
    e.preventDefault();
    setTagsEditModalDetails({
      isShow: true,
      index,
      mediaDetails,
    });
  };

  const mediaDimension = useMemo(() => {
    return filters?.dimensions;
  }, [filters, pHeight, pWidth]);

  const onTagsSave = async () => {
    const { mediaDetails = {}, index } = tagsEditModalDetails;
    const { userTags = [], _id } = mediaDetails;
    let userTagInputValue = null;

    if (tagInputRef?.current?.getInputValue) {
      userTagInputValue = tagInputRef?.current?.getInputValue();
      if (inputValidations?.length) {
        const validationValue = validateValue({
          validations: inputValidations,
          value: userTagInputValue,
          formValues: {},
          title: "",
        });

        if (!validationValue.isValid) {
          showToastMessage({
            type: "ERROR",
            messageToDisplay: t(validationValue.errorMsg),
            toastDuration: 8000,
          });
          return;
        }
      }
    }

    const userTagsToPost = [
      ...userTags,
      ...(userTagInputValue ? [userTagInputValue] : []),
    ];

    const payload = {
      clientId,
      id: _id,
      userTags: userTagsToPost,
    };

    try {
      setIsTagsSaveInProgress(true);
      await CreativeUploadService.updateCreative(
        payload,
        application,
      );

      const updatedMediaList = [
        ...mediaList,
      ];

      updatedMediaList[index] = {
        ...updatedMediaList[index],
        userTags: userTagsToPost,
      };

      setMediaList(updatedMediaList);
      setIsTagsSaveInProgress(false);
      setTagsEditModalDetails({
        isShow: false,
      });
    } catch (err) {
      showToastMessage({
        messageToDisplay: err.errorMsg || BaseClient.getErrorMessage(),
        type: "ERROR",
        toastDuration: 5000,
      });
      setIsTagsSaveInProgress(false);
    }
  };

  const onUserTagsUpdate = (tags = []) => {
    setTagsEditModalDetails({
      ...tagsEditModalDetails,
      mediaDetails: {
        ...(tagsEditModalDetails.mediaDetails || {}),
        userTags: tags,
      },
    });
  };

  const checkCardDisabled = (mediaObj) => {
    if (showCreativeStatus && mediaObj.status) {
      return disalbedStatus.includes(mediaObj.status);
    }

    return false;
  };

  const onUploadMedia = (url, mediaObj) => {
    if (isSelectable) {
      onSelect(url, { ...mediaObj, mediaObj });
    }
    setMediaList([
      mediaObj,
      ...mediaList,
    ]);
  };

  const postCreativeStatus = async (payload) => {
    if (creativeReviewAPIKey === creativeReviewAPI.TVING_VIDEO_CREATIVE_APPROVAL) {
      const response = await OsPackageSvc.postCreativeReviewStatus(payload);
      return response;
    }
    return null;
  };

  const approveCreative = async (payload) => {
    try {
      setIsLoading(true);
      const response = await postCreativeStatus(payload);
      const { result: newCreativeStatus } = response || {};
      if (newCreativeStatus?._id) {
        const updatedMediaList = mediaList.map((media) => {
          if (media._id === payload.id) {
            return {
              ...media,
              ...newCreativeStatus,
            };
          }
          return media;
        });
        setMediaList(updatedMediaList);
        showToastMessage({
          type: "SUCCESS",
          messageToDisplay: "Creative review status updated",
          toastDuration: 5000,
        });
      } else {
        showToastMessage({
          type: "ERROR",
          messageToDisplay: "Failed to mark creative as reviewed",
          toastDuration: 5000,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToastMessage({
        type: "ERROR",
        messageToDisplay: error.errorMsg,
        toastDuration: 5000,
      });
      uiAPIMonitor("SEV2", "MEDIA_LIBRARY_CREATIVE_SELECTION_APPROVE_CREATIVE", {
        payload,
        creativeReviewAPIKey,
        err: error,
      });
    }
  };

  const onClickMarkAsReview = ({ isAlreadyApproved, id }) => {
    let confirmationMessage = t(
      "Are you sure you want to mark this video as reviewed? It will be highlighted when reusing the video creative.",
    );
    if (isAlreadyApproved) {
      confirmationMessage = t(
        "Are you sure you want to unmark this video as reviewed? You will need to review it again during the order review process.",
      );
    }
    const preparedPayload = {
      clientId,
      id,
      approvalDetails: {
        isAlreadyApproved: !isAlreadyApproved,
        approvedFromAdmin: true,
      },
      ...(extraPayloadForCreativeReview || {}),
    };
    showConfirmationModal({
      isShow: true,
      title: confirmationMessage,
      rightBtnText: t("Cancel"),
      actionBtnText: t("Yes"),
      actionBtnCallback: () => {
        approveCreative(preparedPayload);
        resetConfirmationModal();
      },
      rightBtnCallback: () => {
        resetConfirmationModal();
      },
    });
  };

  const renderTagsEditModal = () => {
    const { mediaDetails = {}, isShow } = tagsEditModalDetails;
    const { userTags = [] } = mediaDetails;

    return (
      <ModalWindow
        isShow={isShow}
        onModalDestroy={() => setTagsEditModalDetails({ isShow: false })}
        containerClass="tags-edit-modal-wrapper"
        modalProps={{
          backdrop: "static",
        }}
      >
        <ModalWindow.Header closeButton>
          <span className="title"><Trans>Edit Tags</Trans></span>
        </ModalWindow.Header>
        <ModalWindow.Body className="tags-edit-modal-body-wrapper">
          <TagInput
            ref={tagInputRef}
            formValues={{ userTags }}
            dataKey="userTags"
            onChange={(v) => onUserTagsUpdate(v.userTags)}
            onError={() => {}}
            formErrors={{}}
            isEditable
            labelColumns={0}
            tagWrapperClassName="border-padding"
            placeholder="Type here and enter"
            inputValidations={inputValidations}
          />
        </ModalWindow.Body>
        <ModalWindow.Footer className="tags-edit-modal-footer-wrapper">
          <Button
            className="save-btn"
            isLoading={isTagsSaveInProgress}
            disabled={isTagsSaveInProgress}
            onClick={onTagsSave}
          >
            Save
          </Button>
        </ModalWindow.Footer>
      </ModalWindow>
    );
  };

  const getStatusDom = ({ status, metadata, tooltipInfoForApprovedCreative }) => {
    if (status === "FAILED") {
      return (
        <WithTooltip placement="top" title={metadata?.errorMessage || "Rejected"}>
          <span className="statuc-icon review icon icon-warning-filled" />
        </WithTooltip>
      );
    }
    if (status === "MARKED_AS_REVIEWED") {
      const approvalDetails = metadata?.approvalDetails || {};
      const approvalDetailsToShow = [];
      (tooltipInfoForApprovedCreative || [])?.forEach((info) => {
        if (info?.doNotShowIfNotValue && !approvalDetails?.[info?.dataKey]) {
          return;
        }
        approvalDetailsToShow.push({
          label: info?.label,
          value: approvalDetails?.[info?.dataKey] || info?.fallBackValue,
        });
      });
      return (
        <WithTooltip
          placement="top"
          title={
            <div>
              <Text weight="semiBold" size="medium" className="mb-1">
                Reviewed
              </Text>
              <LabelValuePair
                valueHighLighter
                list={approvalDetailsToShow}
                labelClassName="d-align-center"
                className="align-items-start"
                valueClassName="text-break text-wrap text-left"
                noMargin
                useText
                showEllipsis={false}
                size="small"
                separatorClass="lh0"
              />
            </div>
          }
        >
          <span className="creative-approved-image">
            <AsyncImage
              imgClassName="approved-image"
              imgSrc="https://osads.gumlet.io/image/authenticated/s--6a9rfiC0--/v1724759049/product/review1.svg"
            />
          </span>
        </WithTooltip>
      );
    }

    return statusDom[status];
  };

  const getMediaOptionsDOM = (
    { userTags, url, status, metadata, ...rest },
    index,
  ) => {
    let statusContent = null;
    const { isAlreadyApproved } = metadata?.approvalDetails || {};
    const { id } = rest || {};
    if (showCreativeStatus) {
      if (typeof customStatusDom === "function") {
        statusContent = customStatusDom({ status, metadata });
      } else {
        statusContent = getStatusDom({ status, metadata });
      }
    } else if (enableCreativeReview || showCreativeReviewStatus) {
      statusContent = getStatusDom({
        status: isAlreadyApproved && "MARKED_AS_REVIEWED",
        metadata,
        tooltipInfoForApprovedCreative,
      });
    }

    return (
      <div className="bottom-bar-with-content">
        {showCreativeStatus && statusContent ? (
          <div className="status-block">
            <div className="creative-status">{statusContent}</div>
            <div className="separator" />
          </div>
        ) : null}
        <div className="d-flex">
          {(showCreativeReviewStatus || enableCreativeReview) && statusContent && (
            <div className="creative-status">{statusContent}</div>
          )}
          {enableCreativeReview && (
            <Button
              link
              className="small ml-0 max-h-25 pr-0"
              onClick={() =>
                onClickMarkAsReview({
                  isAlreadyApproved,
                  id,
                })
              }
            >
              {isAlreadyApproved ? (
                <Text type="white" size="small" className="review-label unmarked-review">
                  Unmark as Reviewed
                </Text>
              ) : (
                <Text type="white" size="small" className="review-label marked-review">
                  Mark as Reviewed
                </Text>
              )}
            </Button>
          )}
        </div>
        {((showCreativeReviewStatus && statusContent) || enableCreativeReview) && (
          <div className="tags-separator vertical-separator small-height mx-2" />
        )}
        <div className="media-tags hide-scroll-bar">
          <Pill.List className="media-tags-list">
            {userTags.slice(0, 2).map((tag) => {
              return (
                <WithTooltip title={tag}>
                  <Pill variant="grey" textProps={{ className: "ellipsis-text pad-t-2" }}>{tag}</Pill>
                </WithTooltip>
              );
            })}
            {userTags?.length > 2 && (
              <span className="more-text">+ {userTags?.length - 2} More</span>
            )}
            {userTags.length ? (
              <div className="edit-tags">
                <span
                  className="icon icon-edit-2"
                  onClick={(e) => onEditUserTags(e, { userTags, ...rest, url }, index)}
                />
              </div>
            ) : (
              <Button link onClick={(e) => onEditUserTags(e, { userTags, ...rest, url }, index)}>
                Add Tags
              </Button>
            )}
          </Pill.List>
        </div>
      </div>
    );
  };

  const getMediaDataDOM = ({ height, width, sizeInBytes, metadata, name }) => {
    // Right now showing only in MB since filter is in MB, need to change at all places if this logic needs to be used
    const sizeToShow = (sizeInBytes / ONE_MB_IN_BYTES >= 0.1) ? `${formatNumberInAbbreviations(Number(sizeInBytes / ONE_MB_IN_BYTES), 2)}MB` : `${formatNumberInAbbreviations(Number(sizeInBytes / ONE_KB_IN_BYTES), 2)}KB`;
    return (
      <>
        {showMediaName && name ? (
          <WithTooltip title={name}>
            <div className="media-data-name ellipsis-text">{name}</div>
          </WithTooltip>
        ) : null}
        <div className="media-data-dom">
          {!!width && !!height && (
            <>
              <span>{`${width} x ${height}`}</span>
              <span className="separator" />
            </>
          )}
          <span>{sizeToShow}</span>
          {metadata?.language && (
            <>
              <span className="separator" />
              <span>{metadata?.language}</span>
            </>
          )}
        </div>
      </>
    );
  };

  let validationMessage = "";

  if (!pValidationMessage) {
    if (pWidth && pHeight && maxFileSizeInMB) {
      validationMessage = t(`dimension {{pWidth}} x {{pHeight}} px and size upto {{maxFileSizeInMB}}MB`, {
        pWidth,
        pHeight,
        maxFileSizeInMB,
      });
    } else if (pWidth && pHeight) {
      validationMessage = t("dimension {{pWidth}} x {{pHeight}} px", {
        pWidth,
        pHeight,
      });
    } else if (maxFileSizeInMB) {
      validationMessage = t("size upto {{maxFileSizeInMB}}MB", { maxFileSizeInMB });
    }
  }

  return (
    <div className="creative-selection-wrapper">
      {isLoading && <OverlayLoader />}
      <div className={classnames("header-section", headerClassName)}>
        {enableTemplateSelection && <TemplateSelection />}
        <div className="title-wrapper d-align-center flex-wrap flex-md-nowrap gap-10">
          <div className="left">
            {(validationMessage || pValidationMessage) && isSelectable ? (
              <>
                <div className="title">{t("Choose Creative")}</div>
                {pValidationMessage || (
                  <p>
                    {`${t("You can choose creative of {{validationMessage}}", {
                      validationMessage,
                    })}`}
                  </p>
                )}
              </>
            ) : null}
          </div>
          <div className="right">
            {!_isEmpty(filterListConfig) && (
              <FilterButton
                filterListConfig={filterListConfig}
                value={filters}
                onFilterValueChange={onFilterValueChange}
                className="pendo_feature_media_library_filter_button"
              />
            )}
            <InputField
              type="text"
              isEditable
              placeholder="Search Tags"
              value={searchQuery}
              onChange={setSearchQuery}
              inputWrapperClassName="border-padding creative-selection-search"
              isSearchInput
              errorClassName="d-none"
            />
            <Upload
              {...mediaConfig}
              tooltipMsg={
                pValidationMessage ||
                (validationMessage
                  ? t(`You can upload creative of {{validationMessage}}`, {
                      validationMessage,
                    })
                  : "")
              }
              uploadFile={uploadFile}
              clientId={clientId}
              onUploadMedia={onUploadMedia}
              creativeTag={creativeTag}
              useCustomCreativeFetch={useCustomCreativeFetch}
              uploadTags={uploadTags}
              beforeFileUpload={beforeFileUpload}
            />
          </div>
        </div>
      </div>
      {renderTagsEditModal()}
      <MediaMosaicView
        isSelectable={isSelectable}
        mediaList={mediaList}
        selectedData={selectedData}
        onSelect={onSelect}
        mediaDimension={mediaDimension}
        emptyMsg={t(`No ${title || "Creatives"} Found`)}
        mediaExtraParams={{ subType }}
        onFetchNextBatch={() => fetchData(true)}
        mediaOptionsDOM={getMediaOptionsDOM}
        mediaDataDOM={getMediaDataDOM}
        checkCardDisabled={checkCardDisabled}
        enableAspectRatio={enableAspectRatio}
        hideNoPreview={hideNoPreview}
        {...fetchState}
        {...mosaicProps}
      />
    </div>
  );
};

CreativeSelection.defaultProps = {
  showCreativeStatus: false,
  disalbedStatus: ["FAILED"],
  mosaicProps: {},
  enableTemplateSelection: false,
  enableCreativeReview: false,
  filtersToShow: [],
  filterListConfig: {
    dimensions: {
      key: "dimensions",
      apiFetchKey: "UNIQUE_DIMENSIONS",
      displayName: "Dimensions",
      type: FILTER_COMPONENT_TYPES.RADIO_BUTTON,
      isDataFetchInProgress: false,
      emptyMsg: "No dimensions found",
      layout: "two-column",
      componentMetaData: {
        items: [],
      },
    },
    type: {
      key: "type",
      displayName: "Type",
      type: FILTER_COMPONENT_TYPES.RADIO_BUTTON,
      componentMetaData: {
        items: [
          {
            label: "Image",
            value: "IMAGE",
          },
          {
            label: "Video",
            value: "VIDEO",
          },
        ],
      },
    },
    size: {
      key: "size",
      displayName: "Media Size",
      type: FILTER_COMPONENT_TYPES.RANGE_FILTER,
      minInputProps: {
        suffix: "MB",
      },
      maxInputProps: {
        suffix: "MB",
      },
      componentMetaData: {},
    },
    status: {
      key: "status",
      transformation: "status",
      displayName: "Status",
      type: FILTER_COMPONENT_TYPES.CHECKBOX,
      componentMetaData: {
        items: [
          {
            label: "Processing",
            value: "UPLOADED",
          },
          {
            label: "Approved",
            value: "READY",
          },
          {
            label: "Rejected",
            value: "FAILED",
          },
        ],
      },
    },
    duration: {
      key: "duration",
      displayName: "Duration",
      type: FILTER_COMPONENT_TYPES.RANGE_FILTER,
      minInputProps: {
        suffix: "Seconds",
      },
      maxInputProps: {
        suffix: "Seconds",
      },
      componentMetaData: {},
    },
    language: {
      key: "language",
      displayName: "Language",
      apiFetchKey: "HS_CREATIVE_LANGUAGES",
      type: FILTER_COMPONENT_TYPES.CHECKBOX,
      isDataFetchInProgress: false,
      emptyMsg: "No Languages found",
      layout: "two-column",
      componentMetaData: {
        items: [],
      },
    },
    reviewStatus: {
      key: "reviewStatus",
      displayName: "Review Status",
      type: FILTER_COMPONENT_TYPES.RADIO_BUTTON,
      componentMetaData: {
        items: [
          {
            label: "Reviewed",
            value: [true],
          },
          {
            label: "Not Reviewed",
            value: [false, null],
          },
        ],
      },
    },
  },
  defaultFilters: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    clientId: ownProps?.clientId || state.Application.clientId,
    mediaFileConversionConstants: state?.DomainConfig?.mediaFileConversionConstants,
  };
};

export default connect(mapStateToProps)(OSHOCWithUtilities(CreativeSelection));
