import BaseClient from "../baseClient";

const servicePrefix = "/osBulkActionsService/";
class OSBulkActionsService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      INVALID_PARAMETER_ERROR: "It seems some input parameters are not valid. Please update your inputs and try again.",
    };
  }

  static bulkAction(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkAction`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionRead(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionRead`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionClient(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkAction/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionEntity(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkAction/entity`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionClientRead(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionRead/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static startkBulkAction(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}startkBulkAction`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionsSchedule(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionSchedule/schedule/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionsScheduleForUser(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionSchedule/schedule/user`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static bulkActionsScheduleAdmin(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionSchedule/schedule/admin`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static getBulkActionsSchedule(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionSchedule/schedule/client/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getBulkActionsScheduleForUser(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionSchedule/schedule/user/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static getBulkActionsScheduleAdmin(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionSchedule/schedule/admin/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static bulkActionType(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionType/getByFilters/client`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static bulkActionTypeForClient(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionType/getByFilters/client/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static bulkActionTypeForUser(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionType/getByFilters/user/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static bulkActionTypeForAdmin(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionType/getByFilters/adminV2/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static bulkActionTypeAdmin(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkActionType/getByFilters/adminV2`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static systemActivity(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getBulkActions/client/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static systemActivityForUser(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getBulkActions/user/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static systemActivityAdmin(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getBulkActions/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getBulkActions(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getBulkActions/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getBulkActionsClient(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getBulkActions/client/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getBulkActionseEntity(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getBulkActions/entity/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchMasterGoal(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static saveMasterGoal(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchMasterGoalSettings(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal/settings`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchMasterGoalSettingsV2(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal/settings/paginate`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static saveMasterGoalSettings(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal/settings`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchMasterGoalCreativeLibrary(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal/creativeLibrary`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchMasterGoalAudienceLibrary(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal/audienceLibrary`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchMasterGoalDeviceLibrary(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}masterGoal/deviceLibrary`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchUrlToDownloadFile(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchUrlToDownloadFile`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }
}

export default OSBulkActionsService;
