import React from "react";
import { withTranslation } from "react-i18next";

import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { components } from "@onlinesales-ai/dropdown-v2";
import { Pill } from "@onlinesales-ai/pill-v2";

import Dropdown from "../dropdown";
import DropdownVirtualized from "../dropdownVirtualized";

import "./index.less";

const dropdownComponentMap = {
  DropdownVirtualized,
};

class DropdownWithBelowPills extends React.Component {
  constructor(props) {
    super(props);

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (newValue !== this.getValue()) {
      this.validate(newValue);
    }
  };

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title, errorTitle } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value || !value.length) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", (errorTitle || title).toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChange = (val) => {
    const { onChange, dataKey, formValues } = this.props;
    const existingValues = formValues[dataKey] || [];
    const newValues = val[dataKey] || [];
    let uniqValueToCheck = true;

    if (!_isEmpty(existingValues)) {
      existingValues.forEach((key) => {
        if (_isEqual(newValues[0], key)) {
          uniqValueToCheck = false;
          return null;
        }
      });
    }

    if (uniqValueToCheck) {
      onChange({
        [dataKey]: [...existingValues, ...newValues],
      });
    }
  };

  onClickRemove = (val) => {
    const { dataKey, onChange } = this.props;
    const values = this.getValue();

    let existIndex;

    for (let i = 0; i < values.length; i++) {
      if (_isEqual(values[i], val)) {
        existIndex = i;
        break;
      }
    }

    if (existIndex > -1) {
      values.splice(existIndex, 1);
    }

    onChange({
      [dataKey]: [...values],
    });
  };

  ValueContainer = ({ children, ...props }) => <components.MultiValueContainer {...props} />;

  customLabelFunction = () => {
    const { options, isDisabled, isEditable } = this.props;
    const values = this.getValue();

    const labelToShow = (values || [])?.map((val) => {
      for (let i = 0; i < options.length; i++) {
        if (_isEqual(options[i].value, val)) {
          return options[i];
        }
      }
    });

    return (
      <Pill.List className="email-list-pill-wrapper">
        {labelToShow.map(
          (v) =>
            v && (
              <Pill
                key={v.key}
                variant="grey"
                position="right"
                disabled={isDisabled}
                hidePillCloseClass={!isEditable}
                onClickRemove={
                  isEditable
                    ? () => {
                        this.onClickRemove(v.value);
                      }
                    : false
                }
              >
                <span>{v?.label}</span>
              </Pill>
            ),
        )}
      </Pill.List>
    );
  };

  renderDefaultPlaceholderTag = () => {
    const { defaultPlaceholderTag } = this.props;

    if (defaultPlaceholderTag) {
      return (
        <Pill.List className="email-list-pill-wrapper no-margin-on-top mt10">
          <Pill variant="grey" position="right" hidePillCloseClass>
            <span>{defaultPlaceholderTag}</span>
          </Pill>
        </Pill.List>
      );
    }

    return null;
  };

  renderPill = () => {
    const values = this.getValue();

    return !_isEmpty(values) && Array.isArray(values)
      ? this.customLabelFunction()
      : this.renderDefaultPlaceholderTag();
  };

  render() {
    const { t, options, customComponent, isDisabled, dropdownComponent, isEditable, formGroupInnerClassName, ...restProps } =
      this.props;
    const values = this.getValue();

    const optionsToShow = [];
    if (values?.length) {
      options.forEach((option) => {
        if (!values.includes(option?.value)) {
          optionsToShow.push(option);
        }
      });
    }

    const Component = dropdownComponentMap?.[dropdownComponent] || Dropdown;

    return (
      <Component
        {...restProps}
        isEditable={isEditable}
        formGroupInnerClassName={formGroupInnerClassName}
        isDisabled={isDisabled || !isEditable}
        showTooltiptoNonEdit={false}
        onChange={this.onChange}
        drodownProps={{
          ...(restProps.drodownProps || {}),
          value: [],
          valueComponent: () => {},
          components: { MultiValueContainer: this.ValueContainer, ...customComponent },
        }}
        options={values?.length ? optionsToShow : options}
        onError={() => {}}
        hideSelectedOptions
        customLabelFunction={this.renderPill}
        guidElement={this.renderPill()}
      />
    );
  }
}

DropdownWithBelowPills.defaultProps = {
  formGroupClassName: "form-component-dropdown multiselect-dropdown",
  labelColumns: 3,
  validations: [
    {
      type: "nonEmpty",
      msg: "Please select __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(DropdownWithBelowPills);
