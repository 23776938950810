import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";

import { AppStore } from "@onlinesales-ai/util-methods-v2";
import bilingReducer from "@onlinesales-ai/ott-common-v2/store/billing/reducer";
import reportingReducer from "@onlinesales-ai/reports-v2/store/reporting/reducer";

import applicationReducer from "./application/reducer";
import domainConfigReducer from "./domainConfig/reducer";
import onBoardingReducer from "./onBoarding/reducer";
import campaignsReducer from "./campaigns/reducer";
import sportsDashboardReducer from "./sportsDashboard/reducer";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  ...applicationReducer,
  ...domainConfigReducer,
  ...onBoardingReducer,
  ...campaignsReducer,
  ...bilingReducer,
  ...sportsDashboardReducer,
  ...reportingReducer,
  router: routerReducer,
});

const initialState = {};

const middlewares = [thunk, routerMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

AppStore.setStore(store);

export const history = createReduxHistory(store);

export default store;
