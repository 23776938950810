/* eslint-disable no-shadow */
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";

import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { ONBOARDINGSTATUS } from "@onlinesales-ai/constants-v2";
import { getDefaultRoute, isNullOrUndefined } from "@onlinesales-ai/util-methods-v2";
import { getEntityInfo } from "@onlinesales-ai/ott-common-v2";

import { redirectUrl as redirectUrlAction } from "src/store/application";
import { fetchOnBoardingData as fetchOnBoardingDataAction } from "src/store/onBoarding";
import { fetchSportsDashboardList as fetchSportsDashboardListAcrion } from "src/store/sportsDashboard"
import { setDomainConfig as setDomainConfigAction } from "src/store/domainConfig";
import { getDomainConfiguration } from "src/domainConfig/configuration";
import { routes } from "src/utilities/constants";
import { openRoutes } from "src/app/routeList";

const EntityLevelData = ({
  selectedEntityId,
  selectedEntityType,
  entityInfo,
  userInfo,
  fetchOnBoardingData,
  fetchSportsDashboardList,
  onBoardingData,
  location,
  redirectUrl,
  setDomainConfig,
  entitiesFetchInProgress,
}) => {
  const selectedEntityInfo = useMemo(() => {
    return getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);
  }, [entityInfo, selectedEntityId, selectedEntityType]);

  const updateDomainConfig = async ({ entityInfoOverride = {} } = {}) => {
    const entityInfoToUse = {
      ...selectedEntityInfo,
      ...entityInfoOverride,
    };

    try {
      const config = await getDomainConfiguration({});
      setDomainConfig(config, {
        entityInfo: _isEmpty(entityInfoToUse) ? undefined : entityInfoToUse,
      });
    } catch (err) {}
  };

  const onEntityChange = async () => {
    if (selectedEntityId && selectedEntityType) {
      fetchSportsDashboardList(updateDomainConfig);
      await fetchOnBoardingData(updateDomainConfig);
    }
  };

  useEffect(() => {
    const cb = () => {
      updateDomainConfig();
    };

    PlatformEventManager.on("UPDATE_DOMAIN_CONFIG", cb);

    return () => {
      PlatformEventManager.off("UPDATE_DOMAIN_CONFIG", cb);
    };
  }, []);

  useEffect(() => {
    const { finalStatus } = onBoardingData || {};
    const { pathname } = location || {};

    if (selectedEntityId && !isNullOrUndefined(onBoardingData)) {
      if (finalStatus === ONBOARDINGSTATUS.IN_PROGRESS &&
        !pathname.includes(routes.ONBOARDING.path)) {
        redirectUrl(routes.ONBOARDING.path);
      } else if (
        (finalStatus === ONBOARDINGSTATUS.ACTIVE || _isEmpty(onBoardingData)) &&
        pathname.includes(routes.ONBOARDING.path)
      ) {
        redirectUrl(getDefaultRoute() || routes.HOME?.path);
      }
    }
  }, [onBoardingData, selectedEntityInfo, selectedEntityId, location.pathname]);

  useEffect(() => {
    const { pathname } = location || {};

    if (
      !entitiesFetchInProgress &&
      !selectedEntityId &&
      !pathname.includes(routes.ONBOARDING.path) &&
      !pathname.includes(routes.CONTACT_DETAILS_UPDATE.path) &&
      !openRoutes?.[pathname]
    ) {
      redirectUrl(routes.ONBOARDING.path);
    }
  }, [selectedEntityId, entitiesFetchInProgress, location.pathname]);

  useEffect(() => {
    onEntityChange();
  }, [selectedEntityId, selectedEntityType]);

  useEffect(() => {
    updateDomainConfig();
  }, [userInfo]);

  return null;
};

const mapStateToProps = (state) => {
  return {
    selectedEntityId: state.Application.selectedEntityId,
    selectedEntityType: state.Application.selectedEntityType,
    entitiesFetchInProgress: state.Application.entitiesFetchInProgress,
    entityInfo: state.Application.entityInfo,
    onBoardingData: state.OnBoarding.data,
    userInfo: state.Application.userInfo,
    location: state.router?.location || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
      setDomainConfig: setDomainConfigAction,
      fetchOnBoardingData: fetchOnBoardingDataAction,
      fetchSportsDashboardList: fetchSportsDashboardListAcrion,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityLevelData);
