import { ONBOARDING_CARD_STATUS } from "@onlinesales-ai/constants-v2";

export const useOnboardingWidget = ({ isMobile = false, cards = [], cardsConfig = {} }) => {
  const shouldRenderCard = (config = {}) => {
    let shouldRender = false;
    const flagToCheck = isMobile ? "shouldBeRenderOnMobile" : "shouldBeRendered";

    if (config?.[flagToCheck] === true) {
      shouldRender = true;
    }

    return shouldRender;
  };

  const getCardByKey = (key) => {
    return cardsConfig[key] || null;
  };

  const initialCardsState = {};
  const initialCardsEnableState = {};

  const cardsToRender = cards
    .filter((key) => {
      return cardsConfig[key] && shouldRenderCard(cardsConfig[key]);
    })
    .map((key, index) => {
      initialCardsState[key] = {
        status: ONBOARDING_CARD_STATUS.DRAFT,
      };
      initialCardsEnableState[key] = !index;
      return {
        key,
        props: {
          ...(cardsConfig[key] || {}),
          getCardByKey,
        },
      };
    });

  return {
    shouldRenderCard,
    cardsToRender,
    initialCardsState,
  };
};
