import React, {useEffect, useState, forwardRef} from "react";

import "./index.less";

const NO_IMAGE_SRC = "https://via.placeholder.com/1500x1000?text=No%20Preview%20Available";

const AsyncImage = forwardRef(({
 imgClassName = "",
 imgSrc = "",
 doNotShowLoading,
 noImageUrl,
 className,
}, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentImgSrc, setCurrentImgSrc] = useState(null);

  const downloadImage = (imgUrl, onSuccess = () => {}, onError = () => {}) => {
    let downloadingImage = new Image();
    downloadingImage.onload = onSuccess;
    downloadingImage.onerror = onError;
    downloadingImage.src = imgUrl;
  };

  const onImageDownloadSuccess = (imageUrl) => {
    setCurrentImgSrc(imageUrl);
    setIsLoading(false);
  };

  const handleError = () => {
    downloadImage(noImageUrl, () => onImageDownloadSuccess(noImageUrl));
  }

  useEffect(() => {
    const imageUrl = imgSrc || noImageUrl;
    downloadImage(imageUrl, () => onImageDownloadSuccess(imageUrl), handleError);
  }, [imgSrc]);

  return (
    <div className={`os-async-img-container ${imgClassName}`}>
      {
        !doNotShowLoading && isLoading ? (
          <span className="os-async-img-loader"/>
        ):(
          <img src={currentImgSrc} className={`os-async-img ${className}`} alt="" ref={ref}/>
        )
      }
    </div>
  );
});

AsyncImage.defaultProps = {
  doNotShowLoading: false,
  noImageUrl: NO_IMAGE_SRC,
};

export default AsyncImage;
