/* eslint-disable no-undef */
import React from "react";
import { createRoot } from "react-dom/client";

import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { Sentry, sentryBaseConfig } from "@onlinesales-ai/error-catcher-v2";

import App from "./app";

if (ENABLE_SENTRY && NODE_ENV === "production") {
  try {
    Sentry.init({
      dsn: "https://37dcd1f3bee54330a1cbc8f5a4607e51@o1313655.ingest.sentry.io/4504734744838144",
      ...sentryBaseConfig,
      environment: NODE_ENV,
      release: RELEASE_TIME,
    });
  } catch (err) {}
}

const target = document.getElementById("root");

const root = createRoot(target);

root.render(<App />);

if (NODE_ENV === "production" && "serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    try {
      navigator.serviceWorker.register("/serviceWorker.js");

      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event?.data?.app === "OS" && event?.data?.type === "DEPLOYMENT_REFRESHED") {
          PlatformEventManager.emit("VERSION_OUTDATED");
        }
      });
    } catch (err) {}
  });
}
