import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { checkToken } from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { checkIfUserIsPartnerInternal } from "@onlinesales-ai/util-methods-v2";

import { fetchUser, fetchEntitiesData as fetchEntitiesDataAction, redirectUrl } from "src/store/application";
import { routes } from "src/utilities/constants";

const INFRA_ADMIN_ID = 4661;

const AppAndAuthData = ({
  fetchUserData,
  fetchEntitiesData,
  redirectUrlTo,
  userInfo,
  location,
  isInternalUser,
}) => {
  const isPartnerInternalUser = useMemo(() => {
    return checkIfUserIsPartnerInternal(userInfo);
  }, [userInfo]);

  const fetchInitData = () => {
    const isAuthenticated = checkToken();

    if (isAuthenticated) {
      fetchEntitiesData();
      fetchUserData(routes, redirectUrl, true /*doNotUpdateDomainConfig*/);
    } else {
      redirectUrlTo(routes.LOGIN.path);
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  useEffect(() => {
    const isAuthenticated = checkToken();

    if (!isAuthenticated) {
      redirectUrlTo(routes.LOGIN.path);
    }
  }, [location.pathname]);

  useEffect(() => {
    const { id } = userInfo || {};
    if (id && id === INFRA_ADMIN_ID) {
      PlatformEventManager.emit("INVALID_UA_TOKEN");
    }
  }, [userInfo]);

  useEffect(() => {
    const { id } = userInfo || {};
    const { pathname } = location || {};

    if (
      id &&
      !isInternalUser &&
      !isPartnerInternalUser &&
      !userInfo?.contactNo &&
      !pathname.includes(routes.CONTACT_DETAILS_UPDATE.path)
    ) {
      redirectUrlTo(routes.CONTACT_DETAILS_UPDATE.path);
    }
  }, [userInfo, location.pathname, isInternalUser]);

  return null;
};

const mapStateToProps = (state) => {
  const { userInfo, isInternalUser } = state.Application;

  return {
    userInfo,
    isInternalUser,
    location: state.router?.location || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUserData: fetchUser,
      fetchEntitiesData: fetchEntitiesDataAction,
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAndAuthData);
