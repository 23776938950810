/* eslint-disable prefer-const */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import _sortBy from "lodash/sortBy";
import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";

import { customMergeOSClosure } from "./customMergeOS";

const mergeIdentifierMapping = {
  OVERRIDE: "__OVERRIDE_PROPS__",
  REMOVE: "__REMOVE_PROPS__",
};

const customMergeOS = customMergeOSClosure({ mergeIdentifierMapping });


export const convertToArray = (
  replacerProps,
  config = {
    arrayConfigKey: "componentConfig",
    arrayOrderListKey: "componentToShow",
    arrayItemOrderKey: "order",
    arrayItemShowKey: "isShow",
  },
) => {
  const propKeys = Object.keys(replacerProps);
  if (
    propKeys?.includes(config.arrayOrderListKey) &&
    replacerProps[config.arrayOrderListKey]?.length > 0
  ) {
    // fallback support
    const result = [];
    replacerProps[config.arrayOrderListKey]?.forEach((key) => {
      if (!_isEmpty(replacerProps[config.arrayConfigKey]?.[key])) {
        result.push(replacerProps[config.arrayConfigKey]?.[key]);
      }
    });
    return result;
  } else {
    // sorting with filter and check if no order attribute is give it should work fine
    return _sortBy(Object.values(replacerProps[config.arrayConfigKey] || {}), [
      config.arrayItemOrderKey,
    ]).filter((c) => {
      const val = config.arrayItemShowKey ? c[config.arrayItemShowKey] : true;

      if (typeof config.checkForShow === "function") {
        return config.checkForShow(val, c);
      }

      return val;
    });
  }
};

export const configHocV2 = (Component) => {
  return React.forwardRef((props, ref) => {
    if (Component.defaultConfigProps && Component.defaultProps) {
      throw new Error("Can not use defaultConfigProps and defaultProps together.");
    }

    const { configDefinition } = useSelector((state) => state.DomainConfig || {});

    const configProps = useMemo(() => {
      let { defaultConfigDefinitionFromConfig, ...config } = Component.defaultConfigProps || {};
      const definationKey =
        props.configDefinitionKey || config?.configDefinitionKey;

      if (definationKey) {
        const configDefinitionToUse = props?.configDefinition || configDefinition;

        config = customMergeOS(
          {},
          config,
          defaultConfigDefinitionFromConfig?.[definationKey],
          configDefinitionToUse?.[definationKey],
        );
      }

      return config;
    }, [props.configDefinitionKey, props?.configDefinition, configDefinition]);

    const keysToOverride = useMemo(() => {
      const keys = [];
      Object.keys(configProps)?.forEach((key) =>
        keys.push(...[key, `${key}|${mergeIdentifierMapping.OVERRIDE}`, `${key}|${mergeIdentifierMapping.REMOVE}`]),
      );
      return keys;
    }, [configProps]);

    const propsOverrides = useMemo(() => {
      if (keysToOverride.length) {
        return customMergeOS({}, configProps, _pick(props, keysToOverride));
      }

      return {};
    }, [props, keysToOverride, configProps]);

    return (
      <Component
        ref={ref}
        {...props}
        {...propsOverrides}
        convertToArray={convertToArray}
      />
    );
  });
};
