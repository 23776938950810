import React from "react";
import { connect } from "react-redux";
import "src/i18n";

// import { changeLanguage } from "@onlinesales-ai/i18n-v2";
// import { routes } from "src/utilities/constants";

class InitLanguage extends React.Component {
  // constructor(props) {
  //   super(props);

  //   const { pathname, query = {} } = props.location || {};
  //   const shouldChangeLanguage = !query.hasOwnProperty("lng");
  //   if (shouldChangeLanguage) {}
  // }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
  };
};

export default connect(mapStateToProps)(InitLanguage);
