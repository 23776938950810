import BaseClient from "../baseClient";

const servicePrefix = "/creativeUploadService/v1/";

class CreativeUploadService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      TVING_API_MOLOCO_ERROR: "Looks like we are facing some internal issue. Please, retry after sometime.",
      TVING_INVALID_VIDEO_SIZE: "The video size is invalid. Please check the dimensions and try again.",
      TVING_UNSUPPORTED_VIDEO_RESOLUTION: "The video resolution is not supported. Please use a compatible resolution.",
      TVING_UNSUPPORTED_FILE_TYPE: "The file type is not supported. Please use a supported file format.",
      TVING_AUDIO_NOT_PRESENT: "No audio detected. Please ensure the file contains audio and try again.",
      TVING_INVALID_IMAGE_SIZE: "The image size is invalid. Please check the dimensions and try again.",
      TVING_UNSUPPORTED_IMAGE_RESOULTION: "The image resolution is not supported. Please use a compatible resolution.",
      TVING_INVALID_VIDEO_DURATION: "Minimum video duration should be 6 seconds.",
    };
  }

  static uploadImage(config = {}, application, useXHR = false, onProgress = undefined) {
    const formData = new FormData();

    if (config.mediaType) {
      formData.append("type", config.mediaType);
    }

    if (config.file) {
      formData.append("creative", config.file);
    }

    if (config.clientId) {
      formData.append("clientId", config.clientId);
    }

    if (config.height) {
      formData.append("height", config.height);
    }

    if (config.width) {
      formData.append("width", config.width);
    }

    if (config.tags && Array.isArray(config.tags)) {
      config.tags.forEach((tag) => {
        formData.append("tags[]", tag);
      });
    }

    if (config.userTags && Array.isArray(config.userTags)) {
      config.userTags.forEach((tag) => {
        formData.append("userTags[]", tag);
      });
    }

    if (config.uploadType) {
      formData.append("uploadType", config.uploadType);
    }

    if (config.name) {
      formData.append("name", config.name);
    }

    if (config?.language) {
      formData.append("metadata[language]", config?.language);
    }

    return this.apiCall(
      {
        url: `${servicePrefix}upload${config.clientId ? "" : "/user"}`,
        method: "POST",
        data: formData,
        useXHR,
        onProgress,
      },
      application,
    );
  }

  static fetchCreativeImages(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}search/withPagination/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static groupByCreatives(payload, application) {
    return this.apiCall(
      {
        url: `/creativeUploadService/v2/groupByfilter/wrapper`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static updateCreative(payload, application) {
    return this.apiCall(
      {
        url: `/creativeUploadService/v2/creatives`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static getHSPublicUrl(payload, application) {
    return this.apiCall(
      {
        url: `/creativeUploadService/v1/ht/preview`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }
}

export default CreativeUploadService;
