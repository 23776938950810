import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { Overlay, Popover } from "react-bootstrap";
import {
  isSameDay,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  differenceInDays,
  addDays,
  startOfDay,
  startOfQuarter,
  lastDayOfQuarter,
  subQuarters,
  startOfWeek,
} from "date-fns/esm";
import { useTranslation, Trans } from "react-i18next";
import _uniqueId from "lodash/uniqueId";
import _isNull from "lodash/isNull";
import _isUndefined from "lodash/isUndefined";

import {
  useOnClickOutside,
  format,
  pendoTrackEvent,
  getUTCToZonedDate,
  formattedDate,
  parsedSetLSItem,
} from "@onlinesales-ai/util-methods-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { ToggleButton } from "@onlinesales-ai/toggle-button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { DEFAULT_DATE_FORMAT } from "@onlinesales-ai/constants-v2";
import { getCurrentLanguage } from "@onlinesales-ai/i18n-v2";
import { ShadowScroll } from "@onlinesales-ai/utils-components-v2";

import "react-datepicker/dist/react-datepicker.css";

import "./index.less";
import { getLSValues, getPresetsDate, AVALIABLE_PRESET } from "./utils";

const getPresets = ({
  t,
  presetConfig,
  showTodaysDate,
  isTodayDisabled,
  showQuarterPresets,
  showThisWeekPreset,
  currentDate,
}) => {
  const defaultPreset = {
    [AVALIABLE_PRESET.TODAY]: {
      isShow: !!showTodaysDate,
    },
    [AVALIABLE_PRESET.YESTERDAY]: {
      isShow: true,
    },
    [AVALIABLE_PRESET.THIS_WEEK]: {
      isShow: !!showThisWeekPreset,
    },
    [AVALIABLE_PRESET.LAST_7_DAYS]: {
      isShow: true,
    },
    [AVALIABLE_PRESET.LAST_30_DAYS]: {
      isShow: true,
    },
    [AVALIABLE_PRESET.THIS_MONTH]: {
      isShow: true,
    },
    [AVALIABLE_PRESET.LAST_MONTH]: {
      isShow: true,
    },
    [AVALIABLE_PRESET.THIS_QUARTER]: {
      isShow: !!showQuarterPresets,
    },
    [AVALIABLE_PRESET.LAST_QUARTER]: {
      isShow: !!showQuarterPresets,
    },
    [AVALIABLE_PRESET.CUSTOM]: {
      isShow: true,
    },
  };

  const presets = Object.keys(defaultPreset)
    .filter((key) => defaultPreset[key].isShow)
    .map((preset) =>
      getPresetsDate(preset, { currentDate, presetConfig, showTodaysDate, isTodayDisabled }, t),
    );

  return presets;
};

const DateRangePicker = ({
  startDate,
  endDate,
  renderDayContents,
  compareStartDate,
  compareEndDate,
  showTodaysDate,
  onChange,
  dateFormat,
  className,
  datePickerProps,
  disabled,
  presets,
  position,
  customInput,
  customInputText,
  saveText,
  selectOnConfirm,
  enableborderbottom,
  placeholder,
  useOverlay,
  overlayProps,
  beforeOpen,
  ctaButtons,
  presetConfig,
  tabKey,
  isNewFeature,
  isComparisonEnabled,
  disabledComparisionToday,
  disableSelection,
  onMonthChange,
  onHoverDate,
  disableHoverOnSelection,
  rightComponent,
  showQuarterPresets,
  showThisWeekPreset,
  renderInPortal,
  popoverPosition,
  currentDate,
  onlySelectEndDate,
  dashboardKey,
  enabledLSDataStore,
  renderCalenderInline,
  containerClass,
  platformType,
  pendoDateEventName = "",
}) => {
  const { t } = useTranslation();
  const [selectedStartDate, setStartDate] = useState(null);
  const [selectedEndDate, setEndDate] = useState(null);
  const [selectedCompareStartDate, setCompareStartDate] = useState(null);
  const [selectedCompareEndDate, setCompareEndDate] = useState(null);
  const [selectedIsComparisonMode, setComparisonMode] = useState(false);
  const [activeMode, setActiveMode] = useState("CURRENT");
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const inputRef = useRef(null);
  const [triggeredOnSave, setTriggeredOnSave] = useState(false);
  const renderOnlyOnce = useRef(false);
  const isInitialDateSet = useRef(false);

  const [presetList, setPresetList] = useState(
    () =>
      presets ||
      getPresets({
        t,
        presetConfig,
        showTodaysDate,
        showQuarterPresets,
        showThisWeekPreset,
        currentDate,
      }),
  );
  const modalId = useMemo(() => _uniqueId("popoverDateRange_"), []);
  const currentLanguage = useMemo(() => getCurrentLanguage(), []);

  const closeDatePicker = () => {
    setIsOpen(false);
    setStartDate(startDate);
    setEndDate(endDate);
    setCompareStartDate(compareStartDate);
    setCompareEndDate(compareEndDate);
    setComparisonMode(!!(compareStartDate && compareEndDate));
    setActiveMode("CURRENT");
    setShowError(false);
  };

  const dropDownRef = useOnClickOutside(() => {
    closeDatePicker();
  }, inputRef);

  const { selectedStartdatePlusOne, selectedEndDateMinusOne } = useMemo(() => {
    return {
      selectedStartdatePlusOne: addDays(startOfDay(selectedStartDate), 1),
      selectedEndDateMinusOne: subDays(startOfDay(selectedEndDate), 1),
    };
  }, [selectedStartDate, selectedEndDate]);

  const { selectedCompareStartDatePlusOne, selectedCompareEndDateMinusOne } = useMemo(() => {
    return {
      selectedCompareStartDatePlusOne: addDays(startOfDay(selectedCompareStartDate), 1),
      selectedCompareEndDateMinusOne: subDays(startOfDay(selectedCompareEndDate), 1),
    };
  }, [selectedCompareStartDate, selectedCompareEndDate]);

  const highlightPrevDates = useMemo(() => {
    const prevDateArray = [];
    if (isComparisonEnabled) {
      if (selectedCompareStartDate < selectedEndDate) {
        let currentDate = selectedCompareStartDate;
        while (currentDate <= selectedCompareEndDate) {
          prevDateArray.push(new Date(currentDate));
          currentDate = addDays(currentDate, 1);
        }
      }
    }
    return prevDateArray;
  }, [selectedCompareStartDate, selectedCompareEndDate, selectedEndDate, isComparisonEnabled]);

  const highlightNextDates = useMemo(() => {
    const nextDateArray = [];
    if (isComparisonEnabled) {
      let currentDate = selectedStartDate;
      while (currentDate <= selectedEndDate) {
        nextDateArray.push(new Date(currentDate));
        currentDate = addDays(currentDate, 1);
      }
    }
    return nextDateArray;
  }, [selectedStartDate, selectedEndDate, isComparisonEnabled]);

  const disableComparision = useMemo(() => {
    if (disabledComparisionToday) {
      return {
        isDisabled:
          isSameDay(selectedStartDate, selectedEndDate) && isSameDay(getUTCToZonedDate(new Date()), selectedStartDate),
        tooltip: `Comparision feature is not available in "Today" date selection`,
      };
    }
    return {};
  }, [disabledComparisionToday, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setStartDate(startDate);
  }, [startDate]);

  useEffect(() => {
    setEndDate(endDate);
  }, [endDate]);

  useEffect(() => {
    if (isComparisonEnabled) {
      setCompareStartDate(compareStartDate);
    }
  }, [compareStartDate, isComparisonEnabled]);

  useEffect(() => {
    if (isComparisonEnabled) {
      setCompareEndDate(compareEndDate);
    }
  }, [compareEndDate, isComparisonEnabled]);

  useEffect(() => {
    if (isComparisonEnabled) {
      setComparisonMode(!!(compareStartDate && compareEndDate));
    }
  }, [compareStartDate, compareEndDate, isComparisonEnabled]);

  useEffect(() => {
    if (presets) {
      setPresetList(presets);
    } else {
      setPresetList(
        getPresets({
          t,
          presetConfig,
          showTodaysDate,
          selectedIsComparisonMode,
          showQuarterPresets,
          showThisWeekPreset,
          currentDate,
        }),
      );
    }
  }, [
    presets,
    showTodaysDate,
    presetConfig,
    selectedIsComparisonMode,
    showQuarterPresets,
    showThisWeekPreset,
  ]);

  useEffect(() => {
    if (isOpen && onMonthChange) {
      onMonthChange(
        selectedStartDate
          ? new Date(selectedStartDate)
          : startOfMonth(datePickerProps?.minDate || currentDate || new Date()),
      );
    }
  }, [isOpen]);

  const onDayMouseEnter = (date) => {
    if (onHoverDate) {
      if (disableHoverOnSelection && selectedStartDate && !selectedEndDate) {
        return null;
      } else {
        onHoverDate(date);
      }
    }
  };

  const getDateRangePendoInfo = (start, end) => {
    const selectedDateRanges = {
      platformType,
      startDate: format(start),
      endDate: format(end),
      dateDiffInDays: differenceInDays(end, start),
      ...(selectedIsComparisonMode && selectedCompareStartDate && selectedCompareEndDate && {
        compareStartDate: format(selectedCompareStartDate),
        compareEndDate: format(selectedCompareEndDate),
        compareDateDiffInDays: differenceInDays(selectedCompareEndDate, selectedCompareStartDate),
      }),
    };

    const eventName = `${platformType}${pendoDateEventName?.length ? `_${pendoDateEventName}` : ""}`;
    pendoTrackEvent(eventName, selectedDateRanges);
  };

  const onChangeDate = (dates, prevDates, { resetCompareMode, isCustom }) => {
    if (disableSelection) {
      return true;
    }

    let start = "";
    let end = "";

    if (onlySelectEndDate) {
      start = selectedStartDate;
      end = dates?.[0];
    } else {
      start = dates?.[0];
      end = dates?.[1];
    }
    const [startPrev, endPrev] = prevDates || [];

    if (Array.isArray(dates)) {
      setStartDate(start);
      setEndDate(end);

      if (
        isComparisonEnabled &&
        selectedIsComparisonMode &&
        !resetCompareMode &&
        activeMode === "CURRENT"
      ) {
        setCompareStartDate(null);
        setCompareEndDate(null);
        if (start && end && !startPrev && !endPrev) {
          setActiveMode("PREV");
        }
      }
    }

    if (
      isComparisonEnabled &&
      selectedIsComparisonMode &&
      !resetCompareMode &&
      Array.isArray(prevDates)
    ) {
      setCompareStartDate(startPrev);
      setCompareEndDate(endPrev);
    }

    if (isComparisonEnabled && resetCompareMode && selectedIsComparisonMode) {
      setComparisonMode(false);
      setCompareStartDate(null);
      setCompareEndDate(null);
      setActiveMode("CURRENT");
    }

    if (!isComparisonEnabled && !selectOnConfirm && onChange && start && end) {
      onChange({ startDate: start, endDate: end }, tabKey);
      try {
        getDateRangePendoInfo(start, end);
      } catch (error) {}
      closeDatePicker();
    }

    if (isCustom && start && end) {
      pendoTrackEvent("date_picker_custom_date_range_selected");
    }
  };

  const onToggleCompareMode = (isOn, triggerOnSave = false) => {
    setComparisonMode(isOn);
    setActiveMode("CURRENT");

    if (isOn) {
      if (selectedStartDate && selectedEndDate) {
        setCompareStartDate(
          subDays(
            new Date(selectedStartDate),
            differenceInDays(selectedEndDate, selectedStartDate) + 1,
          ),
        );
        setCompareEndDate(subDays(new Date(selectedStartDate), 1));
      }
    } else {
      setCompareStartDate(null);
      setCompareEndDate(null);
    }
    if (triggerOnSave) {
      setTriggeredOnSave(true);
    }
  };

  const toggleDatePicker = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (!disabled) {
      if (!isOpen) {
        try {
          if (beforeOpen) {
            await beforeOpen();
          }
          setIsOpen(!isOpen);
        } catch (err) {}
      } else {
        setIsOpen(!isOpen);
      }
    }
  };

  const onClickSave = () => {
    if (isComparisonEnabled) {
      if (!selectedStartDate || !selectedEndDate) {
        setShowError(true);
        return null;
      }

      if (selectedIsComparisonMode && (!selectedCompareStartDate || !selectedCompareEndDate)) {
        setShowError(true);
        return null;
      }
    }

    if (enabledLSDataStore) {
      parsedSetLSItem(`global_filter_date_range_${dashboardKey}`, {
        isComparisonModeON: selectedIsComparisonMode,
      });
    }

    if (isComparisonEnabled) {
      onChange(
        {
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          compareStartDate: selectedCompareStartDate,
          compareEndDate: selectedCompareEndDate,
        },
        { doNotConvert: selectedStartDate === selectedEndDate },
      );
    }

    try {
      if (isInitialDateSet.current) {
        getDateRangePendoInfo(selectedStartDate, selectedEndDate);
      }
    } catch (error) {}
    isInitialDateSet.current = true;
    closeDatePicker();
  };

  useEffect(() => {
    if (!enabledLSDataStore || renderOnlyOnce.current) {
      return;
    }
    const { isComparisonModeON } = getLSValues(dashboardKey);

    if (!_isUndefined(isComparisonModeON) && selectedStartDate && selectedEndDate) {
      onToggleCompareMode(isComparisonModeON, true);
      renderOnlyOnce.current = true;
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (triggeredOnSave) {
      onClickSave();
      setTriggeredOnSave(false);
    }
  }, [triggeredOnSave]);

  const getTotalSelectedDays = () => {
    if (selectedStartDate && selectedEndDate) {
      const totalDays = differenceInDays(selectedEndDate, selectedStartDate);

      return t("( {{totalDays}} Days Selected )", { totalDays: totalDays + 1 });
    }

    return null;
  };

  const renderToggleSection = () => {
    return (
      <div className="custom-date-range-input-wrapper">
        <div className="date-with-error-block">
          <div
            className={`current-date-range ${activeMode === "CURRENT" ? "active" : ""}`}
            onClick={() => {
              setActiveMode("CURRENT");
            }}
          >
            {`${selectedStartDate ? formattedDate(selectedStartDate, "date", { useFormat: true }) : t("Start Date")} - ${
              selectedEndDate ? formattedDate(selectedEndDate, "date", { useFormat: true }) : t("End Date")
            }`}
          </div>
          <div className="error-msg">
            {showError && (!selectedStartDate || !selectedEndDate) ? (
              <span><Trans>Please select dates</Trans></span>
            ) : null}
          </div>
        </div>

        <div className="separator">
          <Trans>Compare with</Trans>
          <ToggleButton
            isOn={selectedIsComparisonMode}
            isEnabled={!disableComparision.isDisabled}
            className={`${disableComparision.isDisabled ? "disabled" : ""} pendo_feature_date_comparision_toggle`}
            onToggleCallback={(event, val) => onToggleCompareMode(val)}
            tooltip={disableComparision.isDisabled ? disableComparision.tooltip : ""}
          />
        </div>
        <div className="date-with-error-block">
          <div
            className={`previous-date-range ${
              selectedIsComparisonMode &&
              !disableComparision.isDisabled &&
              selectedStartDate &&
              selectedEndDate
                ? `${activeMode === "PREV" ? "active" : ""}`
                : "disabled disable-selection"
            }`}
            onClick={() => {
              if (!disableComparision.isDisabled) {
                setActiveMode("PREV");
              }
            }}
          >
            {`${
              selectedCompareStartDate
                ? formattedDate(selectedCompareStartDate, "date", { useFormat: true })
                : t("Start Date")
            } - ${
              selectedCompareEndDate ? formattedDate(selectedCompareEndDate, "date", { useFormat: true }) : t("End Date")
            }`}
          </div>
          <div className="error-msg">
            {showError && (!selectedCompareStartDate || !selectedCompareEndDate) ? (
              <span><Trans>Please select dates</Trans></span>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderPresets = () => {
    return (
      <div className="custom-ranges-wrapper">
        {isComparisonEnabled && renderToggleSection()}
        <ShadowScroll containerClassName="preset-wrapper-element" verticalAlign>
          {presetList.map((rangeObj = {}, index) => {
            const {
              displayName,
              startDate: rangeStartDate,
              endDate: rangeEndDate,
              compareStartDate: rangeCompareStartDate,
              compareEndDate: rangeCompareEndDate,
              badgeLabel,
              resetCompareMode,
              isDisabled,
              tooltip,
              isCustom,
              className: pClassName = "",
            } = rangeObj;

            let isSelected = false;

            if (
              isSameDay(selectedStartDate, rangeStartDate) &&
              isSameDay(selectedEndDate, rangeEndDate)
            ) {
              isSelected = true;
            }

            if (isCustom && isCustomSelected) {
              isSelected = true;
            }

            return (
              <WithTooltip title={isDisabled ? tooltip : ""}>
                <div
                  className={`range-row ${isDisabled ? "disabled" : ""} ${
                    isSelected ? "selected" : ""
                  } ${pClassName}`}
                  key={`${displayName}-${index}-datepicker-preset`}
                  onClick={() => {
                    if (!isDisabled) {
                      if (isCustom) {
                        setActiveMode("CURRENT");
                        setIsCustomSelected(true);
                      } else {
                        setIsCustomSelected(false);
                      }
                      onChangeDate(
                        [rangeStartDate, rangeEndDate],
                        [rangeCompareStartDate, rangeCompareEndDate],
                        { resetCompareMode, isCustom: false },
                      );
                    }
                  }}
                >
                  <div className="range-display-name">
                    <Trans>{displayName}</Trans>
                  </div>
                  {badgeLabel && (
                    <span className="range-new-label">
                      <Trans>{badgeLabel}</Trans>
                    </span>
                  )}
                </div>
              </WithTooltip>
            );
          })}
        </ShadowScroll>
      </div>
    );
  };

  const renderContent = (props = {}) => {
    return (
      <div
        className={`date-range-output-wrapper ${containerClass} ${
          presetList.length ? "with-preset" : "without-preset"
        } ${isComparisonEnabled ? "compare-enabled" : ""} ${disableSelection ? "disabled-selection" : ""} ${currentLanguage || ""}`}
        ref={dropDownRef}
        {...props}
      >
        <div className="date-range-output-inner-wrapper">
          <div className="date-picker-calender override-default-font d-flex-column">
            {activeMode === "CURRENT" || !isComparisonEnabled ? (
              <DatePicker
                onChange={(params) => onChangeDate(params, null, { isCustom: true })}
                openToDate={selectedStartDate}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                renderDayContents={renderDayContents}
                selectsRange
                selectsEnd={onlySelectEndDate}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                monthsShown={2}
                inline
                {...datePickerProps}
                highlightDates={highlightPrevDates}
                dayClassName={(date) => {
                  if (isComparisonEnabled) {
                    let dayClass = "";
                    if (isSameDay(date, selectedStartdatePlusOne)) {
                      if (isSameDay(date, selectedEndDateMinusOne)) {
                        dayClass = "center-date-with-start-end";
                      } else if (isSameDay(date, selectedEndDate)) {
                        dayClass = "two-days-with-no-gap";
                      } else {
                        dayClass = "start-date-plus-one";
                      }
                    } else if (isSameDay(date, selectedEndDateMinusOne)) {
                      if (isSameDay(date, selectedCompareStartDate)) {
                        dayClass = "same-day-selected";
                      } else if (isSameDay(date, selectedStartDate)) {
                        dayClass = "two-days-with-no-gap";
                      } else {
                        dayClass = "end-date-minus-one";
                      }
                    } else if (isSameDay(date, selectedCompareStartDate)) {
                      if (isSameDay(date, selectedCompareEndDate)) {
                        dayClass = "highlighted-one-day";
                      } else {
                        dayClass = "highlighted-date-start";
                      }
                    } else if (isSameDay(date, selectedCompareEndDate)) {
                      dayClass = "highlighted-date-end";
                    }
                    if (isSameDay(date, new Date(date.getFullYear(), date.getMonth() + 1, 0))) {
                      if (isSameDay(date, selectedStartdatePlusOne)) {
                        dayClass = "start-date-plus-one last-day-of-the-month";
                      } else {
                        dayClass = "last-day-of-the-month";
                      }
                    }
                    return dayClass;
                  } else {
                    let dayClass = "";
                    // eslint-disable-next-line no-lonely-if
                    if (isSameDay(date, selectedStartdatePlusOne)) {
                      if (isSameDay(date, selectedEndDateMinusOne)) {
                        dayClass = "center-date-with-start-end";
                      } else {
                        dayClass = "start-date-plus-one";
                      }
                    } else if (isSameDay(date, selectedEndDateMinusOne)) {
                      if (isSameDay(date, selectedStartdatePlusOne)) {
                        dayClass = "center-date-with-start-end";
                      } else {
                        dayClass = "end-date-minus-one";
                      }
                    }
                    if (isSameDay(date, new Date(date.getFullYear(), date.getMonth() + 1, 0))) {
                      dayClass = "last-day-of-the-month";
                    }
                    return dayClass;
                  }
                }}
                onMonthChange={onMonthChange}
                onDayMouseEnter={onDayMouseEnter}
                calendarClassName={`current-date-picker ${datePickerProps.calendarClassName || ""}`}
              />
            ) : (
              <DatePicker
                onChange={(params) => onChangeDate(null, params, { isCustom: true })}
                openToDate={selectedCompareStartDate}
                startDate={selectedCompareStartDate}
                endDate={selectedCompareEndDate}
                selectsRange
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                monthsShown={2}
                dayClassName={(date) => {
                  let dayClass = "";
                  if (isSameDay(date, selectedCompareStartDatePlusOne)) {
                    if (isSameDay(date, selectedEndDate)) {
                      dayClass = "same-day-selected";
                    } else if (isSameDay(date, selectedCompareEndDate)) {
                      dayClass = "two-days-with-no-gap";
                    } else if (isSameDay(date, selectedCompareEndDateMinusOne)) {
                      dayClass = "center-date-with-start-end";
                    } else {
                      dayClass = "start-date-plus-one";
                    }
                  } else if (isSameDay(date, selectedCompareEndDateMinusOne)) {
                    if (isSameDay(date, selectedCompareStartDate)) {
                      dayClass = "two-days-with-no-gap";
                    } else {
                      dayClass = "end-date-minus-one";
                    }
                  } else if (isSameDay(date, selectedStartDate)) {
                    if (isSameDay(date, selectedEndDate)) {
                      dayClass = "highlighted-one-day";
                    } else {
                      dayClass = "highlighted-date-start";
                    }
                  } else if (isSameDay(date, selectedEndDate)) {
                    dayClass = "highlighted-date-end";
                  }
                  if (isSameDay(date, new Date(date.getFullYear(), date.getMonth() + 1, 0))) {
                    dayClass = "last-day-of-the-month";
                  }
                  return dayClass;
                }}
                inline
                highlightDates={highlightNextDates}
                {...datePickerProps}
                onDayMouseEnter={onDayMouseEnter}
                onMonthChange={onMonthChange}
                maxDate={subDays(selectedStartDate, 1)}
                calendarClassName={`prev-date-picker ${datePickerProps.calendarClassName || ""}`}
              />
            )}
            {selectOnConfirm ? (
              <div className="confirm-wrapper d-align-between">
                {isComparisonEnabled ? null : (
                  <span className="select-days">{getTotalSelectedDays()}</span>
                )}
                <div className="cta-wrapper d-align-end">
                  {ctaButtons && ctaButtons({ toggleDatePicker })}
                  <Button
                   onClick={onClickSave}
                   className="pendo_feature_date_comparison_apply_button"
                  >{saveText}</Button>
                </div>
              </div>
            ) : null}
          </div>
          {presetList && presetList.length ? renderPresets() : null}
          {rightComponent ? rightComponent() : null}
        </div>
      </div>
    );
  };

  if (renderCalenderInline) {
    return renderContent();
  }

  return (
    <div className={`os-daterangepicker ${position || ""} ${className || ""}`}>
      {isNewFeature && (
        <span className="new-feature-tag-2 red no-before-after animate-scale top-left round">
          <Trans>New</Trans>
        </span>
      )}
      {customInput ? (
        customInput({
          toggleDatePicker,
          startDate,
          endDate,
          dateFormat,
          inputRef,
        })
      ) : (
        <>
          <div
            className={`date-range-input-wrapper d-align-between form-controls ${
              enableborderbottom ? "borderbottom" : ""
            }`}
            onClick={toggleDatePicker}
            ref={inputRef}
          >
            <div className="date-range-input">
              {placeholder && !startDate && !endDate ? (
                <span className="placeholder">{t(placeholder)}</span>
              ) : (
                <>
                  {customInputText ? (
                    customInputText({
                      startDate,
                      endDate,
                      formatedStartDate: startDate ? formattedDate(startDate, "date", { useFormat: true }) : "",
                      formatedEndDate: endDate ? formattedDate(endDate, "date", { useFormat: true }) : "",
                    })
                  ) : (
                    <>
                      {startDate ? formattedDate(startDate, "date", { useFormat: true }) : t("Start Date")}
                      {endDate ? ` - ${formattedDate(endDate, "date", { useFormat: true })}` : ` - ${t("End Date")}`}
                    </>
                  )}
                </>
              )}
            </div>
            <img
              alt="daterange"
              className="datepicker-icon"
              src="https://osads.gumlet.io/image/authenticated/s--QF_p6Gt6--/v1627034581/product/monetize/calendar.svg"
            />
          </div>
          {isComparisonEnabled && compareStartDate && compareEndDate && (
            <div className="compare-date-div">
              <span>
                <Trans>Compare</Trans>{" "}
              </span>
              {compareStartDate ? formattedDate(compareStartDate, "date", { useFormat: true }) : t("Start Date")}
              {compareEndDate
                ? ` - ${formattedDate(compareEndDate, "date", { useFormat: true })}`
                : ` - ${t("End Date")}`}
            </div>
          )}
        </>
      )}
      {useOverlay ? (
        <Overlay
          target={inputRef.current}
          container={renderInPortal ? document.body : inputRef.current}
          show={isOpen}
          placement="auto"
          rootClose
          onHide={toggleDatePicker}
          {...overlayProps}
        >
          {({ popper, placement, arrowProps, show, ...props }) => (
            <Popover className={`data-range-popover-wrapper ${popoverPosition}`} id={modalId}>{renderContent(props)}</Popover>
          )}
        </Overlay>
      ) : (
        <>{isOpen ? renderContent() : null}</>
      )}
    </div>
  );
};

DateRangePicker.defaultProps = {
  dateFormat: DEFAULT_DATE_FORMAT,
  // All the props of date picker mentioned @ https://reactdatepicker.com/ should be passed here
  datePickerProps: {},
  disabled: false,
  enableborderbottom: false,
  position: "",
  saveText: "Apply",
  selectOnConfirm: false,
  useOverlay: false,
  overlayProps: {},
  isNewFeature: false,
  isComparisonEnabled: false,
  disabledComparisionToday: false, // #notlikeit
  disableHoverOnSelection: true,
  renderInPortal: false,
  dashboardKey: "dashboardKey",
  enabledLSDataStore: false,
  renderCalenderInline: false,
};

const mapStateToProps = (state) => {
  return {
    platformType: state?.DomainConfig?.platformType,
  };
};

export default connect(mapStateToProps)(DateRangePicker);
