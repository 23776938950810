export const entityTypeEnum = {
  AGENCY: "AGENCY",
  CLIENT: "CLIENT",
  MCC: "MCC",
};

export const businessDefinitionEnum = {
  AGENCY: "AGENCY",
  ADVERTISER: "ADVERTISER",
};

export const businessTypeEnum = {
  AGENCY: "AGENCY",
  ADVERTISER: "ADVERTISER",
};

export const entityTypeEnumForStore = {
  CLIENT: "clients",
  MCC: "mccs",
};

export const entityTypeBusineessNameEnum = {
  AGENCY: "Agency",
  CLIENT: "Brand",
  ADVERTISER: "Advertiser",
};

export const postpaidPaymentTypeEnum = {
  PREPAID: "PREPAID",
  POSTPAID_WITH_CREDIT_LIMIT: "POSTPAID_WITH_CREDIT_LIMIT",
  POSTPAID_WITH_RO: "POSTPAID_WITH_RO",
};

export const postPaidPaymentTypeLabelConfig = {
  PREPAID: "Prepaid",
  POSTPAID_WITH_CREDIT_LIMIT: "Postpaid With Credit Limit",
  POSTPAID_WITH_RO: "Postpaid With PDC",
};

export const CAMPAIGN_DELIVERY_STATUS = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  DRAFT: "DRAFT",
  UNDER_REVIEW: "UNDER_REVIEW",
  SCHEDULED: "SCHEDULED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  ARCHIVED: "ARCHIVED",
};

export const CAMPAIGN_STATUS = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  DRAFT: "DRAFT",
  ARCHIVED: "ARCHIVED",
  COMPLETED: "COMPLETED",
};

export const ADSET_DELIVERY_STATUS = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  UNDER_REVIEW: "UNDER_REVIEW",
  SCHEDULED: "SCHEDULED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  ARCHIVED: "ARCHIVED",
  CAMPAIGN_DRAFT: "CAMPAIGN_DRAFT",
  CAMPAIGN_PAUSED: "CAMPAIGN_PAUSED", // => PAUSED
  CAMPAIGN_ARCHIVED: "CAMPAIGN_ARCHIVED", // => ARCHIVED
};

export const MIDFLIGHT_STATUS = [
  ADSET_DELIVERY_STATUS.ACTIVE,
  ADSET_DELIVERY_STATUS.SCHEDULED,
  ADSET_DELIVERY_STATUS.REJECTED,
];

export const CREATIVE_STATUS_PROGRESS_CONFIG = [
  { key: "APPROVED", label: "Approved", marked: true, color: "#88D37B", type: "successLight" },
  { key: "UNDER_REVIEW", label: "Pending Review", type: "warningLight" },
  { key: "REJECTED", label: "Rejected", marked: true, color: "#EE4C4B", type: "error" },
];

export const ADSET_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  DRAFT: "DRAFT",
};

export const adSetStatusConfig = {
  [ADSET_STATUS.ENABLED]: {
    iconClassName: "icon-circle active-green",
    label: "Active",
    passiveName: "Activate",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Active",
      disabled: "{{entity}} Active",
    },
    className: "pendo_feature_adset_table_status_active_click",
  },
  [ADSET_STATUS.DISABLED]: {
    iconClassName: "icon-archive-outline archived-icon",
    label: "Archived",
    passiveName: "Archive",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Archived",
      disabled: "{{entity}} Archived",
    },
    className: "pendo_feature_adset_table_status_archive_click",
  },
  [ADSET_STATUS.DRAFT]: {
    iconClassName: "icon-draft",
    label: "Draft",
    passiveName: "Draft",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Draft",
      disabled: "{{entity}} Draft",
    },
    className: "pendo_feature_adset_table_status_draft_click",
  },
};

export const adSetDeliveryStatusConfig = {
  [ADSET_DELIVERY_STATUS.ACTIVE]: {
    iconClassName: "icon-circle active-green",
    label: "Active",
    passiveName: "Activate",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Active",
      disabled: "{{entity}} Active",
    },
    className: "pendo_feature_adset_table_status_active_click",
  },
  [ADSET_DELIVERY_STATUS.CAMPAIGN_DRAFT]: {
    iconClassName: "icon-draft",
    label: "Draft",
    passiveName: "Draft",
    showLabelWithIcon: true,
    tooltipText: {
      default: "Campaign Draft",
      disabled: "Campaign Draft",
    },
    className: "pendo_feature_adset_table_status_campaign_draft_click",
  },
  [ADSET_DELIVERY_STATUS.CAMPAIGN_PAUSED]: {
    iconClassName: "icon-circle paused-red",
    label: "Stop",
    passiveName: "Stop",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Stopped",
      disabled: "{{entity}} Stopped",
    },
    className: "pendo_feature_adset_table_status_paused_click",
  },
  [ADSET_DELIVERY_STATUS.DRAFT]: {
    iconClassName: "icon-draft",
    label: "Draft",
    passiveName: "Draft",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Draft",
      disabled: "{{entity}} Draft",
    },
    className: "pendo_feature_adset_table_status_draft_click",
  },
  [ADSET_DELIVERY_STATUS.UNDER_REVIEW]: {
    iconClassName: "icon-under-review",
    label: "Under Review",
    passiveName: "Under Review",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Under Review",
      disabled: "{{entity}} Under Review",
    },
    className: "pendo_feature_adset_table_status_under_review_click",
  },
  [ADSET_DELIVERY_STATUS.SCHEDULED]: {
    iconClassName: "icon-calendar-without-date",
    label: "Scheduled",
    passiveName: "Scheduled",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Scheduled",
      disabled: "{{entity}} Scheduled",
    },
    className: "pendo_feature_adset_table_status_scheduled_click",
  },
  [ADSET_DELIVERY_STATUS.COMPLETED]: {
    iconClassName: "icon-check delivered",
    label: "Delivered",
    passiveName: "Delivered",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Delivered",
      disabled: "{{entity}} Delivered",
    },
    className: "pendo_feature_adset_table_status_delivered_click",
  },
  [ADSET_DELIVERY_STATUS.ARCHIVED]: {
    iconClassName: "icon-archive-outline archived-icon",
    label: "Archived",
    passiveName: "Archive",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Archived",
      disabled: "{{entity}} Archived",
    },
    className: "pendo_feature_adset_table_status_archive_click",
  },
  [ADSET_DELIVERY_STATUS.CAMPAIGN_ARCHIVED]: {
    iconClassName: "icon-archive-outline archived-icon",
    label: "Archived",
    passiveName: "Archive",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Archived",
      disabled: "{{entity}} Archived",
    },
    className: "pendo_feature_adset_table_status_archive_click",
  },
  [ADSET_DELIVERY_STATUS.REJECTED]: {
    iconClassName: "icon icon-warning-filled icon-rejected",
    label: "Rejected",
    passiveName: "Rejected",
    showLabelWithIcon: true,
    tooltipText: {
      default: "{{entity}} Rejected",
      disabled: "{{entity}} Rejected",
    },
    className: "pendo_feature_adset_table_status_rejected_click",
  },
};

export const campaignDeliveryStatusConfig = {
  [CAMPAIGN_DELIVERY_STATUS.ACTIVE]: {
    iconClassName: "icon-circle active-green",
    label: "Active",
    passiveName: "Activate",
    showLabelWithIcon: true,
    tableCellClassName: "success-msg",
    tooltipText: {
      default: "{{entity}} currently running",
      disabled: "{{entity}} currently running",
    },
    className: "pendo_feature_campaign_table_status_active_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.PAUSED]: {
    iconClassName: "icon-circle paused-red",
    label: "Stopped",
    passiveName: "Stopped",
    showLabelWithIcon: true,
    tableCellClassName: "error-msg",
    tooltipText: {
      default: "{{entity}} manually stopped before completion.",
      disabled: "{{entity}} manually stopped before completion.",
    },
    className: "pendo_feature_campaign_table_status_paused_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.DRAFT]: {
    iconClassName: "icon-draft",
    label: "Draft",
    passiveName: "Draft",
    showLabelWithIcon: true,
    tableCellClassName: "default-msg",
    tooltipText: {
      default: "{{entity}} not yet launched or finalized.",
      disabled: "{{entity}} not yet launched or finalized.",
    },
    className: "pendo_feature_campaign_table_status_draft_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW]: {
    iconClassName: "icon-under-review",
    label: "Under Review",
    passiveName: "Under Review",
    showLabelWithIcon: true,
    tableCellClassName: "",
    tooltipText: {
      default: "{{entity}} undergoing evaluation from the Disney+ Hotstar team.",
      disabled: "{{entity}} undergoing evaluation from the Disney+ Hotstar team.",
    },
    className: "pendo_feature_campaign_table_status_under_review_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.SCHEDULED]: {
    iconClassName: "icon-calendar-without-date",
    label: "Scheduled",
    passiveName: "Scheduled",
    showLabelWithIcon: true,
    tableCellClassName: "success-msg",
    tooltipText: {
      default: "{{entity}} set to start at a scheduled date and time.",
      disabled: "{{entity}} set to start at a scheduled date and time.",
    },
    className: "pendo_feature_campaign_table_status_scheduled_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.COMPLETED]: {
    iconClassName: "icon-check delivered",
    label: "Delivered",
    passiveName: "Delivered",
    showLabelWithIcon: true,
    tableCellClassName: "success-msg",
    tooltipText: {
      default: "{{entity}} successfully completed.",
      disabled: "{{entity}} successfully completed.",
    },
    className: "pendo_feature_campaign_table_status_delivered_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.ARCHIVED]: {
    iconClassName: "icon-archive-outline archived-icon",
    label: "Archived",
    passiveName: "Archive",
    showLabelWithIcon: true,
    tableCellClassName: "default-msg",
    tooltipText: {
      default: "{{entity}} completed and saved for reference.",
      disabled: "{{entity}} completed and saved for reference.",
    },
    className: "pendo_feature_campaign_table_status_archive_click",
  },
  [CAMPAIGN_DELIVERY_STATUS.REJECTED]: {
    iconClassName: "icon icon-warning-filled icon-rejected",
    label: "Rejected",
    passiveName: "Rejected",
    showLabelWithIcon: true,
    tableCellClassName: "error-msg",
    tooltipText: {
      default: "{{entity}} not approved for launch.",
      disabled: "{{entity}} not approved for launch.",
    },
    className: "pendo_feature_campaign_table_status_rejected_click",
  },
};

export const campaignStatusConfig = {
  [CAMPAIGN_STATUS.ACTIVE]: {
    iconClassName: "icon-circle active-green",
    label: "Active",
    passiveName: "Activate",
    showLabelWithIcon: true,
    tableCellClassName: "success-msg",
    tooltipText: {
      default: "{{entity}} Active",
      disabled: "{{entity}} Active",
    },
    className: "pendo_feature_campaign_table_status_active_click",
  },
  [CAMPAIGN_STATUS.PAUSED]: {
    iconClassName: "icon-circle paused-red",
    label: "Stop",
    passiveName: "Stop",
    showLabelWithIcon: true,
    tableCellClassName: "error-msg",
    tooltipText: {
      default: "{{entity}} Stopped",
      disabled: "{{entity}} Stopped",
    },
    className: "pendo_feature_campaign_table_status_paused_click",
  },
  [CAMPAIGN_STATUS.DRAFT]: {
    iconClassName: "icon-draft",
    label: "Draft",
    passiveName: "Draft",
    showLabelWithIcon: true,
    tableCellClassName: "default-msg",
    tooltipText: {
      default: "{{entity}} Draft",
      disabled: "{{entity}} Draft",
    },
    className: "pendo_feature_campaign_table_status_draft_click",
  },
  [CAMPAIGN_STATUS.ARCHIVED]: {
    iconClassName: "icon-archive-outline archived-icon",
    label: "Archived",
    passiveName: "Archive",
    showLabelWithIcon: true,
    tableCellClassName: "default-msg",
    tooltipText: {
      default: "{{entity}} Archived",
      disabled: "{{entity}} Archived",
    },
    className: "pendo_feature_campaign_table_status_archive_click",
  },
};

export const ORDER_TYPE_FILTER = [
  {
    label: "Video Pre-Roll",
    value: "VIDEO_PREROLL",
  },
  {
    label: "Video Mid-Roll",
    value: "VIDEO_MIDROLL",
  },
];

export const ADSET_DELIVERY_STATUS_FILTER = [
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.ACTIVE].label,
    value: ADSET_DELIVERY_STATUS.ACTIVE,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.DRAFT].label,
    value: ADSET_DELIVERY_STATUS.DRAFT,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.UNDER_REVIEW].label,
    value: ADSET_DELIVERY_STATUS.UNDER_REVIEW,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.SCHEDULED].label,
    value: ADSET_DELIVERY_STATUS.SCHEDULED,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.COMPLETED].label,
    value: ADSET_DELIVERY_STATUS.COMPLETED,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.REJECTED].label,
    value: ADSET_DELIVERY_STATUS.REJECTED,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.CAMPAIGN_PAUSED].label,
    value: ADSET_DELIVERY_STATUS.CAMPAIGN_PAUSED,
  },
  {
    label: adSetDeliveryStatusConfig[ADSET_DELIVERY_STATUS.ARCHIVED].label,
    value: ADSET_DELIVERY_STATUS.ARCHIVED,
  },
];

export const CAMPAIGN_DELIVERY_STATUS_FILTER = [
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.ACTIVE].label,
    value: CAMPAIGN_DELIVERY_STATUS.ACTIVE,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.DRAFT].label,
    value: CAMPAIGN_DELIVERY_STATUS.DRAFT,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW].label,
    value: CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.SCHEDULED].label,
    value: CAMPAIGN_DELIVERY_STATUS.SCHEDULED,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.COMPLETED].label,
    value: CAMPAIGN_DELIVERY_STATUS.COMPLETED,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.REJECTED].label,
    value: CAMPAIGN_DELIVERY_STATUS.REJECTED,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.PAUSED].label,
    value: CAMPAIGN_DELIVERY_STATUS.PAUSED,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.ARCHIVED].label,
    value: CAMPAIGN_DELIVERY_STATUS.ARCHIVED,
  },
];

export const CAMPAIGN_DELIVERY_ADMIN_STATUS_FILTER = [
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.ACTIVE].label,
    value: CAMPAIGN_DELIVERY_STATUS.ACTIVE,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW].label,
    value: CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.SCHEDULED].label,
    value: CAMPAIGN_DELIVERY_STATUS.SCHEDULED,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.COMPLETED].label,
    value: CAMPAIGN_DELIVERY_STATUS.COMPLETED,
  },
  {
    label: campaignDeliveryStatusConfig[CAMPAIGN_DELIVERY_STATUS.REJECTED].label,
    value: CAMPAIGN_DELIVERY_STATUS.REJECTED,
  },
];

export const CAMPAIGN_TYPE_ALIAS = {
  LIVE: "Live",
  GEC: "Entertainment",
};

export const CAMPAIGN_TYPE_FILTER = [
  {
    label: CAMPAIGN_TYPE_ALIAS.LIVE,
    value: "LIVE",
  },
  {
    label: CAMPAIGN_TYPE_ALIAS.GEC,
    value: "GEC",
  },
];

export const CAMPAIGN_SUB_TYPE_ALIAS = {
  Spot: "Reach Plus",
  Audience: "Audience Plus",
  Bundle: "Impact Plus",
};

export const CAMPAIGN_DEFAULT_STATUS = [
  CAMPAIGN_DELIVERY_STATUS.ACTIVE,
  CAMPAIGN_DELIVERY_STATUS.DRAFT,
  CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW,
  CAMPAIGN_DELIVERY_STATUS.SCHEDULED,
  CAMPAIGN_DELIVERY_STATUS.COMPLETED,
  CAMPAIGN_DELIVERY_STATUS.REJECTED,
];

export const ADSET_DEFAULT_STATUS = [
  ADSET_DELIVERY_STATUS.ACTIVE,
  ADSET_DELIVERY_STATUS.DRAFT,
  ADSET_DELIVERY_STATUS.UNDER_REVIEW,
  ADSET_DELIVERY_STATUS.COMPLETED,
  ADSET_DELIVERY_STATUS.REJECTED,
  ADSET_DELIVERY_STATUS.SCHEDULED,
  ADSET_DELIVERY_STATUS.CAMPAIGN_DRAFT,
  ADSET_DELIVERY_STATUS.CAMPAIGN_PAUSED,
  ADSET_DELIVERY_STATUS.CAMPAIGN_ARCHIVED,
];

export const CAMPAIGN_ADMIN_DEFAULT_STATUS = [
  CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW,
  CAMPAIGN_DELIVERY_STATUS.SCHEDULED,
  CAMPAIGN_DELIVERY_STATUS.REJECTED,
  CAMPAIGN_DELIVERY_STATUS.ACTIVE,
];

export const ADSET_ADMIN_DEFAULT_STATUS = [
  ADSET_DELIVERY_STATUS.ACTIVE,
  ADSET_DELIVERY_STATUS.UNDER_REVIEW,
  ADSET_DELIVERY_STATUS.SCHEDULED,
  ADSET_DELIVERY_STATUS.REJECTED,
];

export const AD_SET_DELIVERY_STATUS_FILTER_MAP = {
  [ADSET_DELIVERY_STATUS.DRAFT]: [
    ADSET_DELIVERY_STATUS.DRAFT,
    ADSET_DELIVERY_STATUS.CAMPAIGN_DRAFT,
  ],
  [ADSET_DELIVERY_STATUS.ARCHIVED]: [
    ADSET_DELIVERY_STATUS.ARCHIVED,
    ADSET_DELIVERY_STATUS.CAMPAIGN_ARCHIVED,
  ],
};

export const CAMPAIGN_DELIVERY_STATUS_CELL_CONFIG = campaignDeliveryStatusConfig;

export const CAMPAIGN_STAUS_WISE_OPTION_CONFIG = {
  [CAMPAIGN_DELIVERY_STATUS.ACTIVE]: ["VIEW", "DOWNLOAD", CAMPAIGN_STATUS.PAUSED],
  [CAMPAIGN_DELIVERY_STATUS.PAUSED]: ["VIEW", "DOWNLOAD"],
  [CAMPAIGN_DELIVERY_STATUS.DRAFT]: ["EDIT", "DOWNLOAD", CAMPAIGN_STATUS.ARCHIVED],
  [CAMPAIGN_DELIVERY_STATUS.UNDER_REVIEW]: ["EDIT", "BLS", "DOWNLOAD", CAMPAIGN_STATUS.PAUSED],
  [CAMPAIGN_DELIVERY_STATUS.SCHEDULED]: ["EDIT", "BLS", "DOWNLOAD", CAMPAIGN_STATUS.PAUSED],
  [CAMPAIGN_DELIVERY_STATUS.COMPLETED]: ["EDIT", "DOWNLOAD"],
  [CAMPAIGN_DELIVERY_STATUS.REJECTED]: ["EDIT", "DOWNLOAD", CAMPAIGN_STATUS.PAUSED],
  [CAMPAIGN_DELIVERY_STATUS.ARCHIVED]: ["VIEW", "DOWNLOAD"],
};

export const AD_FORMAT_MAPPING = {
  VIDEO_PREROLL: {
    VIDEO_WITH_CTA: "VIDEO_WITH_CTA_PREROLL",
    VIDEO_WITH_CAROUSEL: "VIDEO_WITH_CAROUSEL_PREROLL",
    VIDEO_WITH_TAKEOVER: "VIDEO_WITH_TAKEOVER_PREROLL",
    VIDEO_WITH_WEBVIEW: "VIDEO_WITH_WEBVIEW_PREROLL",
  },
  VIDEO_MIDROLL: {
    VIDEO_WITH_CTA: "VIDEO_WITH_CTA_MIDROLL",
    VIDEO_WITH_CAROUSEL: "VIDEO_WITH_CAROUSEL_MIDROLL",
    VIDEO_WITH_TAKEOVER: "VIDEO_WITH_TAKEOVER_MIDROLL",
    VIDEO_WITH_WEBVIEW: "VIDEO_WITH_WEBVIEW_MIDROLL",
    VIDEO_MIDROLL: "VIDEO_WITH_WEBVIEW_MIDROLL",
  },
  BILLBOARD: {
    VIDEO: "BILLBOARD_VIDEO",
    DISPLAY: "BILLBOARD_DISPLAY",
    CTV_VIDEO: "BILLBOARD_CTV_VIDEO",
    CTV_DISPLAY: "BILLBOARD_CTV_DISPLAY",
  },
  FRAMES: {
    FRAME_DISPLAY: "FRAMES_DISPLAY",
  },
};

export const TARGETING_TYPES_CONFIG = {
  LANGUAGE: { label: "Content Language" },
  PLATFORM: { label: "Platform" },
  DEVICE_PRICE: { label: "Device Price" },
  AGE_RANGE: { label: "Age Range" },
  GENDER: { label: "Gender" },
  GEO_STATE: { label: "State" },
  GEO_CITY: { label: "City" },
  INTEREST_BASED: { label: "Interests" },
  SUBSCRIPTION_LEVEL: { label: "Subscription Level" },
  DEMOGRAPHIC: { label: "Demography" },
  GEO: { label: "Geography" },
  NCCS: { label: "Socio-Economic Status" },
  BREAK_NUMBER: { label: "Break Selection" },
  BREAK_SLOT: { label: "Ad Slot Selection" },
};

export const GEC_CONTENT_SELECTION_CUSTOM_CONFIG = {
  NO_CONTENT: { label: "Open Targeting" },
  GENRE: { label: "Genre" },
  CONTENT_TYPE: { label: "Content Category" },
  CHANNEL: { label: "Channel" },
  TOP_CONTENT: { label: "Top Content" },
  TITLE_LEVEL_CONTENT: { label: "Title" },
  IMPACT_TARGETING: { label: "Impact" },
};

export const CAMPAIGN_TYPE_OPTIONS = [
  {
    value: "LIVE",
    label: "LIVE",
  },
  {
    value: "GEC",
    label: "General Entertainment Content",
  },
];

export const SPORT_DASHBOARD_MAPPING = {
  SUMMARY_DASHBOARD: "summary_tab",
  TOURNAMENT_DASHBOARD: "tournament_tab",
  TARGETING_DASHBOARD: "targeting_tab",
  LEAD_GENERATION_DASHBOARD: "leadgen_cte_tab",
};
