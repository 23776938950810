import React from "react";

import {Trans} from "react-i18next";
import classnames from "classnames";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

import "./index.less";

const copyFallback = (text) => {
  return new Promise((resove, reject) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "-100px";
    textArea.style.left = "-100px";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let commandExecuted = false;

    try {
      commandExecuted = document.execCommand("copy");
    } catch (err) {}

    document.body.removeChild(textArea);

    if (commandExecuted) {
      resove();
    } else {
      reject();
    }
  });
};

export const copyToClipboardText = async (text) => {
  let copied = false;

  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(text);
      copied = true;
    }
  } catch (err) {}

  try {
    if (!copied) {
      await copyFallback(text);
      copied = true;
    }
  } catch (err) {}

  return copied;
};

const CopyToClipBoard = ({ text, showLabel, showToastMessage, children, className, childClassName }) => {
  const onClickCopy = async (event) => {
    event.stopPropagation();
    const copied = await copyToClipboardText(text);

    if (copied) {
      showToastMessage({
        type: "SUCCESS",
        toastDuration: 3000,
        messageToDisplay: "Text successfully copied to clipboard",
      });
    } else {
      showToastMessage({
        type: "ERROR",
        toastDuration: 3000,
        messageToDisplay: "Error in copy text to clipboard",
      });
    }
  };

  return (
    <div className={`copy-to-clipboard ${className}`}>
      {children ? (
        <div onClick={onClickCopy} className={classnames("inline d-inline-flex", childClassName)}>{children}</div>
      ) : (
        <>
          <span onClick={onClickCopy} className="icon-copy copy-to-clipboard-icon" />
          {showLabel && <span className="copy-text"><Trans>copy</Trans></span>}
        </>
      )}
    </div>
  );
};

CopyToClipBoard.defaultProps = {
  showLabel: true,
  className: "",
};

export default OSHOCWithUtilities(CopyToClipBoard);
