import React, { lazy, useMemo } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import { RouteBasedDrawer } from "@onlinesales-ai/drawer-v2";
import { loaderApp } from "@onlinesales-ai/loader-v2";
import { HelpLink } from "@onlinesales-ai/helplink-v2";

import { redirectUrl } from "src/store/application";

const DrawerChildren = lazy(() => import("src/pages/routeBasedDrawer/drawerChildren"));

const drawerComponentProps = {
  APP_HELP_PAGE: {
    size: "medium",
  },
  BLS: {
    size: "medium",
  },
  HELP_PAGE: {
    size: "medium",
  },
  MEDIA_LIBRARY_MOSAIC: {
    size: "medium",
  },
  CAMPAIGN_LAUNCHER: {
    size: "large",
  },
  CAMPAIGN_PLANNER: {
    size: "large",
  },
  TOP_UP_WITH_TRANSACTION: {
    size: "large",
  },
  TRANSACTIONS: {
    size: "large",
  },
  BUSINESS_DETAILS: {
    size: "medium",
  },
  PLACEMENT_DETAILS: {
    size: "medium",
  },
  ADVERTISER_ANALYTICS: {
    size: "large",
  },
  MANAGE_AD_CREDIT: {
    size: "large",
  },
};

const drawerSubComponentProps = {};

const RouteBasedDrawerWrapper = ({ redirectUrlTo = () => {}, routeBasedDrawerConfig }) => {
  const { component, subComponent } = useParams();
  const location = useLocation();

  const drawerProps = useMemo(() => {
    if (routeBasedDrawerConfig?.drawerSubComponentProps?.[component]?.[subComponent]) {
      return routeBasedDrawerConfig.drawerSubComponentProps[component][subComponent];
    }

    if (drawerSubComponentProps?.[component]?.[subComponent]) {
      return drawerSubComponentProps[component][subComponent];
    }

    if (routeBasedDrawerConfig?.drawerComponentProps?.[component]) {
      return routeBasedDrawerConfig.drawerComponentProps[component];
    }

    if (drawerComponentProps?.[component]) {
      return drawerComponentProps[component];
    }

    return {};
  }, [component, subComponent]);

  const headerRightContent = useMemo(() => {
    const componentProps = routeBasedDrawerConfig?.componentConfig?.[component] || {};
    let content = null;
    if (componentProps?.helpLinkKey) {
      content = <HelpLink helpLinkKey={componentProps?.helpLinkKey} />;
    }
    return content;
  }, [routeBasedDrawerConfig, component]);

  return (
    <RouteBasedDrawer
      componentKey={component}
      subComponentKey={subComponent}
      location={location}
      redirectUrl={redirectUrlTo}
      suspenseLoading={loaderApp()}
      DrawerChildren={DrawerChildren}
      drawerProps={{
        ...drawerProps,
        stopContentClickPropagation: false,
        headerRightContent,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    routeBasedDrawerConfig: state.DomainConfig.routeBasedDrawerConfig || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteBasedDrawerWrapper);
