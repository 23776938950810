import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import {
  formatValuesInThousands,
  getCurrencyDOM,
  getCurrencyClass,
} from "@onlinesales-ai/util-methods-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import Input from "./Input";

const EditInput = ({
  suffix,
  onChange,
  onType,
  value,
  validate,
  enableEdit,
  formatNumber,
  showCurrency,
  showTooltip,
  precision,
  enableEditOnHover,
  emptyInputForValue,
  icon,
  hideBorder,
  nonEditClassName,
  showSuffixInNotEditMode,
  editIconClass,
  ...props
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isShowEditIcon, setIsShowEditIcon] = useState(enableEditOnHover ? false : true);
  const [val, setVal] = useState();
  const inputRef = useRef();

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  const onClickCancel = () => {
    setVal(value);
    setIsEditable(false);
    showEditIcon(false);
  };

  useEffect(() => {
    if (isEditable && emptyInputForValue !== undefined && emptyInputForValue == val) {
      setVal("");
    }
  }, [isEditable]);

  const onClick = async () => {
    if (isEditable) {
      if (validate) {
        try {
          await validate(val);
        } catch (err) {
          if (!err) {
            onClickCancel();
          }
          return null;
        }
      }
      setIsEditable(false);
      showEditIcon(false);
      onChange(val);
    } else {
      setIsEditable(true);
    }
  };

  const showEditIcon = (flag) => {
    if (enableEditOnHover) {
      setIsShowEditIcon(flag);
    }
  };

  const renderSuffix = () => {
    return (
      <>
        {suffix && <span>{suffix}</span>}
        <span className="icon icon-enter cursor-pointer" onClick={onClickCancel} />
      </>
    );
  };

  const renderValue = () => {
    if (formatNumber) {
      return (
        <>
          {showCurrency ? getCurrencyDOM() : null}
          {formatValuesInThousands(value, precision)}
        </>
      );
    }

    return value;
  };

  return (
    <div className={`editable-header input-wrapper`}>
      {isEditable ? (
        <Input
          {...props}
          value={val}
          onChange={(newVal) => {
            setVal(newVal);
            if (onType) {
              onType(newVal);
            }
          }}
          suffix={renderSuffix()}
          onEnter={onClick}
          onBlur={onClick}
          currencyClass={showCurrency ? getCurrencyClass() : ""}
          ref={inputRef}
        />
      ) : (
        <>
          {enableEdit ? (
            <div
              className={classnames("fake-input-wrapper width-flex-1", nonEditClassName, {
                "border-bottom-0": hideBorder,
              })}
              onMouseEnter={() => showEditIcon(true)}
              onMouseLeave={() => showEditIcon(false)}
            >
              {showTooltip ? (
                <WithTooltip title={renderValue()}>
                  <span className="form-control fake-input ellipsis-text">{renderValue()}</span>
                </WithTooltip>
              ) : (
                <span className="form-control fake-input ellipsis-text">{renderValue()}</span>
              )}
              {showSuffixInNotEditMode && <span>{suffix}</span>}
              {isShowEditIcon && <span className={`icon ${icon} ${editIconClass}`} onClick={onClick} />}
            </div>
          ) : (
            <div className="fake-input-wrapper fake-input-min">
              {showTooltip ? (
                <WithTooltip title={renderValue()}>
                  <span className="fake-input">{renderValue()}</span>
                </WithTooltip>
              ) : (
                <>
                  <span className="fake-input">{renderValue()}</span>
                  {showSuffixInNotEditMode && <span>{suffix}</span>}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

EditInput.defaultProps = {
  enableEdit: true,
  enableEditOnHover: false,
  precision: 0,
  icon: "icon-edit-filled",
};

export default EditInput;
