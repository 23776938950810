import React, { useState } from "react";
import classnames from "classnames";
import { Accordion, Card } from "react-bootstrap";

import { Button } from "@onlinesales-ai/button-v2";

import "./index.less";

const CollapsibleCard = ({
  containerClass,
  isExpanded,
  expandable,
  cardHeader,
  cardBody,
  controlled,
  onCardExpandCollapse,
  showCollapsibleIcon,
  showIcon,
  showImage,
  showIconWeight,
  children,
  onClickCardHeader,
  id,
  collapsibleAsButton,
  contentPosition,
  defaultActiveKey,
  customCollapsibleIcon,
  noBackground,
  type,
  showAsCandy,
  showCollapseState,
  showCollapseIconAsButton,
  btnTextProps,
  cardHeaderClass,
  containerHeaderClass,
}) => {
  const [uncontrolledAccordionState, setUncontrolledAccordionState] = useState(false);
  const onSelect = (key) => {
    if (controlled && expandable) {
      onCardExpandCollapse(!!key);
    } else if (expandable) {
      setUncontrolledAccordionState(!uncontrolledAccordionState);
    }
  };

  return (
    <Accordion
      bsPrefix={`os-collapsible-card-container ${containerClass} ${!expandable ? "disabled" : ""} ${
        isExpanded || (!uncontrolledAccordionState && showCollapseState) ? "card-open" : "card-close"
      } ${collapsibleAsButton ? "collpasible-as-button" : ""} ${noBackground ? "no-background" : ""} ${type.toLowerCase()} ${showAsCandy ? "show-as-candy" : ""}`}
      onSelect={onSelect}
      defaultActiveKey={defaultActiveKey}
      activeKey={controlled ? (isExpanded ? "0" : null) : undefined}
      id={id}
    >
      <Card>
        {
          (cardBody || children) && contentPosition === "TOP" &&
          <Accordion.Collapse eventKey="0">
            <Card.Body bsPrefix="card-body collapsible-card-body">{cardBody || children}</Card.Body>
          </Accordion.Collapse>
        }
        <Accordion.Toggle
          bsPrefix={classnames("card-header collapsible-card-header d-align-between", containerHeaderClass, cardHeaderClass)}
          as={Card.Header}
          eventKey="0"
          onClick={onClickCardHeader}
        >
          {showImage && (
            <span>
              <img src={showImage} alt={showImage} />
            </span>
          )}
          {showIcon && (
            <span className={`icon ${showIcon} ${showIconWeight ? "iconweight" : ""}`} />
          )}
          {collapsibleAsButton ? <Button link>{cardHeader}<span className={`icon ml-2 ${(controlled ? isExpanded : uncontrolledAccordionState) ? "icon-angle-down" : "icon-angle-right"}`} /></Button> : cardHeader}
          {showCollapsibleIcon && <span className={`icon collapsible-icon ${customCollapsibleIcon ? customCollapsibleIcon : "icon-caret-right"} `} />}
          {showCollapseIconAsButton && <Button className={`pr-3 ${btnTextProps.className}`} {...btnTextProps}>{btnTextProps.text}</Button>}
        </Accordion.Toggle>
        {
          (cardBody || children) && contentPosition === "BOTTOM" &&
          <Accordion.Collapse eventKey="0">
            <Card.Body bsPrefix="card-body collapsible-card-body">{cardBody || children}</Card.Body>
          </Accordion.Collapse>
        }
      </Card>
    </Accordion>
  );
};

CollapsibleCard.defaultProps = {
  containerClass: "",
  isExpanded: false,
  expandable: true,
  controlled: true,
  showCollapsibleIcon: false,
  showImage: false,
  showIcon: false,
  showIconWeight: false,
  onCardExpandCollapse: () => {},
  onClickCardHeader: () => {},
  contentPosition: "BOTTOM",
  collapsibleAsButton: false,
  defaultActiveKey: "0",
  customCollapsibleIcon: "",
  noBackground: false,
  type: "",
  showAsCandy: false,
  showCollapseIconAsButton: false,
  showCollapseState: false,
};

export default CollapsibleCard;
