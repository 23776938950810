import React, { useMemo, useRef } from "react";
import { Trans } from "react-i18next";

import { format } from "@onlinesales-ai/util-methods-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { markDownToReact } from "@onlinesales-ai/html-v2";

const historyEntityEnum = {
  APPROVAL_CHANGE_LOG: "APPROVAL_CHANGE_LOG",
};

const historyStatusEnum = {
  UNDER_REVIEW: "Under Review",
  ON_HOLD: "On hold",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  BLOCKED: "Blocked",
};

const colorsMap = ["#1E555C", "#1a6a9a", "#A34F22", "#e0a42d", "#42918C"];

const Comment = ({
  commentDetails = {},
  user: commentUser = {},
  timestamp,
  hasMarkdownSupport,
  userInfo,
  labelConfig,
}) => {
  const userToColorMap = useRef({});

  const getUserNameAbbrevation = (userName) => {
    let userNameAbbr = "";

    if (userName) {
      const matches = userName.match(/\b(\w)/g); // ['J','S','O','N']
      const acronym = matches.join(""); // JSON
      userNameAbbr = acronym.toUpperCase();
    }

    let color = "#bcbcbc";
    if (userNameAbbr) {
      if (userToColorMap.current[userNameAbbr]) {
        color = userToColorMap.current[userNameAbbr];
      } else {
        const colorIndex = Object.keys(userToColorMap.current).length % colorsMap.length;
        color = colorsMap[colorIndex];
        userToColorMap.current[userNameAbbr] = color;
      }
    }

    return {
      userNameAbbr,
      color,
    };
  };

  const parsedMessage = useMemo(() => {
    return hasMarkdownSupport && commentDetails?.message
      ? markDownToReact(commentDetails?.message)
      : commentDetails?.message;
  }, [hasMarkdownSupport, commentDetails?.message]);
  let logMessage = `Status of this ${labelConfig[historyEntityEnum.APPROVAL_CHANGE_LOG]} has been changed`;
  logMessage += historyStatusEnum[commentDetails?.previousStatus] ? ` from **${historyStatusEnum[commentDetails?.previousStatus]}**` : "";
  logMessage += ` to **${historyStatusEnum[commentDetails?.status]}** by ${commentUser?.name} on ${format(new Date(timestamp), "dd MMM yy, hh:mm aa")}`;

  if (commentDetails?.type === historyEntityEnum.APPROVAL_CHANGE_LOG) {
    return (
      <div className="log-activity reset-css">
        {markDownToReact(logMessage)}
      </div>
    );
  }

  if (commentDetails?.message && commentUser?.userId && commentUser?.email) {
    const userName = commentUser.name || commentUser?.email.split("@")[0];
    const { userNameAbbr, color } = getUserNameAbbrevation(userName);
    const isSelf = userInfo?.email === commentUser?.email;
    const isSelfFlag = isSelf ? "right" : "left";

    let userSubTitle = "";

    if (isSelf) {
      userSubTitle = "You";
    // } else if (commentUser?.isAdmin) {
    //   userSubTitle = "Reviewer";
    } else {
      userSubTitle = userName;
    }

    return (
      <>
        <div className={`comment-info ${isSelfFlag}`}>
          <div className={`comment-info-tile ${isSelfFlag}`}>
            <WithTooltip title={commentUser?.name || userName}>
              <div className="person-tile" style={{ backgroundColor: color }}>
                <span>{userNameAbbr}</span>
              </div>
            </WithTooltip>
            <span className="sub-title">
              <Trans>{userSubTitle}</Trans>
            </span>
          </div>
          <div className="comment-message-wrapper">
            <div className="comment-message reset-css">{parsedMessage}</div>
          </div>
          <div className="comment-meta">
            <span>{format(timestamp, "dd MMM yy, hh:mm a")}</span>
            <div className={`comment-attachments ${isSelfFlag}`} />
          </div>
        </div>
        <div className="clearfix" />
      </>
    );
  }

  return null;
};

Comment.defaultProps = {
  labelConfig: {
    APPROVAL_CHANGE_LOG: "",
  },
};

export default Comment;
