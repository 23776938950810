import { Sentry } from "@onlinesales-ai/error-catcher-v2";
import {
  getCookie,
  populateEvent,
  AppStore,
  isBrowserWebView,
  pendoTrackEvent,
} from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import xhr, { AbortController as XHRAbortController } from "./xhr";
import APIFailException from "./apiFailException";

let uniqCounter = 0;

const retryConfig = {
  GET: {
    default: 0,
    TypeError: 1,
  },
  POST: {
    default: 0,
    TypeError: 0,
  },
};

const sentryIngnoreErrorCode = [
  "AD0000", // access error
  "UNAUTHORIZED", // access error
];

const regionWiseApi = {
  default: "https://osapi.onlinesales.ai",
  southAsia: "https://osapi-as1.onlinesales.ai",
};

const getUniqId = () => {
  uniqCounter += 1;
  return `api_${uniqCounter}`;
};

const getQueryString = (params) => {
  return Object.keys(params)
    .map((k) => {
      if (Array.isArray(params[k])) {
        return params[k]
          .map((val) => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
          .join("&");
      }

      if (typeof params[k] === "undefined") {
        return "";
      }

      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
    })
    .join("&");
};

class BaseClient {
  static getErrorMessages() {
    return {};
  }

  static getHandledErrorMessages() {
    return {};
  }

  static getErrorMessageTitle() {
    return {
      default: "Error while fetching data",
      UNAUTHORIZED: "Access Denied",
      AD0000: "Access Denied",
      ACCESS_DENIED: "Access Denied",
    };
  }

  static getAPIHeaders() {
    const headers = {
      "x-referer": "platform",
    };
    const uaToken = getCookie("UA_TOKEN", false);

    if (uaToken) {
      headers["x-ua-token"] = uaToken;
      headers["x-ubid"] = getCookie("ubid", false) || "unique12345";
    }

    return headers;
  }

  static getErrorMessage(code, method, url, options, isCheckForTitle = false) {
    const messages = isCheckForTitle
      ? this.getErrorMessageTitle({ code, method, url, options })
      : this.getErrorMessages({ code, method, url, options });

    if (messages?.[url]?.[method]?.[code]) {
      return messages[url][method][code];
    }

    if (messages?.[url]?.[method]?.default) {
      return messages[url][method].default;
    }

    if (messages?.[url]?.[code]) {
      return messages[url][code];
    }

    if (messages?.[url]?.default) {
      return messages[url].default;
    }

    if (messages?.[code]) {
      return messages[code];
    }

    if (messages?.default) {
      return messages.default;
    }

    return "Something went wrong, Please try again!";
  }

  static apiCall(
    {
      url,
      method,
      data,
      headers = {},
      params,
      errorCodeParse,
      useXHR: pUseXHR = false,
      sendErrorReport = true,
      ...restConfig
    },
    application,
    options = {},
  ) {
    const useXHR = pUseXHR || !window.AbortController;

    // for abort fetch call
    const controller = useXHR ? new XHRAbortController() : new AbortController();
    const { signal } = controller;

    const uniqueId = getUniqId();

    if (!application) {
      console.warn(
        "Argument for application name is missing in the method used for service call. Hence, it cannot be aborted.",
      );
      application = "default";
    }

    if (!BaseClient.fetchMap[method]) {
      BaseClient.fetchMap[method] = {};
    }

    if (!BaseClient.fetchMap[method][application]) {
      BaseClient.fetchMap[method][application] = {};
    }

    const urlKey = url.split("?")[0];
    if (!BaseClient.fetchMap[method][application][urlKey]) {
      BaseClient.fetchMap[method][application][urlKey] = {};
    }

    BaseClient.fetchMap[method][application][urlKey][uniqueId] = controller;

    if (options?.abortId) {
      if (BaseClient.abortMap[options.abortId]) {
        BaseClient.abortMap[options.abortId].abort();
      }

      BaseClient.abortMap[options.abortId] = controller;
    }

    let fnRef = useXHR ? xhr : fetch;

    if (BaseClient?.proxyMap?.[url]) {
      fnRef = BaseClient.proxyMap[url];
    }

    if (params) {
      url = `${url}?${getQueryString(params)}`;
    }

    const startTimeStamp = new Date().getTime();

    const apiHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...this.getAPIHeaders(headers),
      ...headers,
    };

    if (data instanceof FormData) {
      delete apiHeaders["Content-Type"];
    }

    let apiCount = -1;

    const onAPIComplete = () => {
      const completedInTime = new Date().getTime() - startTimeStamp;
      populateEvent(
        "APPS",
        "APPS||SERVICE_TIME_RECORD",
        {
          type: method,
          url,
          dataType: "json",
          headers: apiHeaders,
          timeTake: completedInTime,
        },
        {
          endpoint: urlKey,
          utm_medium: url,
          utm_content: completedInTime,
        },
      );

      if (BaseClient.fetchMap[method]?.[application]?.[urlKey]?.[uniqueId]) {
        delete BaseClient.fetchMap[method][application][urlKey][uniqueId];
      }

      if (BaseClient.abortMap[options.abortId]) {
        delete BaseClient.abortMap[options.abortId];
      }
    };

    const getErrorPayload = () => {
      let payload = method === "GET" ? params : data;

      try {
        payload = JSON.parse(payload);
      } catch (e) {
      }

      return payload;
    };

    const parseError = (err) => {
      // Do not handle anything for abort error
      if (
        err?.name === "AbortError" ||
        err?.message === "The user aborted a request." || // chrome
        err?.message === "Fetch is aborted" || // safari
        err?.message === "The operation was aborted." // firefox
      ) {
        return { isAborted: true };
      }

      const errorCode = errorCodeParse
        ? errorCodeParse(err)
        : err?.data?.exception?.error?.code || err?.data?.error?.code || err?.data?.code;
      const errorMsg = this.getErrorMessage(errorCode, method, url, options);
      const errorMsgTitle = this.getErrorMessage(errorCode, method, url, options, true);

      let authErrorMsg =
        err?.data?.error?.exception?.error?.message ||
        err?.data?.exception?.error?.message ||
        err?.data?.message ||
        err?.data?.error ||
        "";

      if (typeof authErrorMsg === "string") {
        authErrorMsg = authErrorMsg.toLowerCase();
      } else {
        authErrorMsg = "";
      }

      if (
        errorCode === "UNAUTHORIZED" ||
        authErrorMsg.includes("application can not be null/empty") ||
        authErrorMsg.includes("hades authorization failed") ||
        authErrorMsg.includes("user authentication failed")
      ) {
        const uaToken = getCookie("UA_TOKEN", false);
        if (uaToken) {
          PlatformEventManager.emit("INVALID_UA_TOKEN");
        }
      }

      let errorType = "";

      if (err?.name && err?.name?.includes?.("TypeError")) {
        errorType = "TypeError";
      } else if (err?.name && err?.name?.includes?.("NetworkError")) {
        errorType = "NetworkError";
      }

      return {
        err,
        errorMsg,
        errorMsgTitle,
        errorCode,
        isAborted: false,
        errorType,
        payload: getErrorPayload(),
      };
    };

    const handledErrorCodes = Object.keys(this.getHandledErrorMessages?.() || {}) || [];
    const allSentryIgnoreErrorCode = [...sentryIngnoreErrorCode, ...handledErrorCodes];

    const reportError = (parsedError) => {
      // populateEvent(
      //   "APPS",
      //   "APPS||SERVICE_ERROR",
      //   {},
      //   {
      //     endpoint: urlKey,
      //     serviceErrorCode: err?.res?.status ? errorCode : err?.message || errorMsg,
      //     errorCode: err?.res?.status,
      //     message: errorMsg,
      //     utm_source: url,
      //     utm_content: method,
      //   },
      // );
      if (allSentryIgnoreErrorCode.includes(parsedError?.errorCode)) {
        return false;
      }

      try {
        Sentry.withScope((scope) => {
          scope.setTags({
            method,
            url,
            osApi: true,
            endpoint: urlKey,
          });

          scope.setLevel("warning");
          scope.setExtra("error", parsedError.err);
          scope.setExtra("errorMsg", parsedError.errorMsg);
          scope.setExtra("errorCode", parsedError.errorCode);
          scope.setExtra("endpoint", urlKey);
          scope.setTag("errorType", parsedError.err?.name);
          scope.setTag("errorCode", parsedError.errorCode);

          if (apiCount) {
            scope.setTag("apiCount", apiCount);
          }

          if (["TypeError", "NetworkError"].includes(parsedError.errorType)) {
            scope.setLevel("info");
            scope.setTag("errorType", parsedError.errorType);
          } else {
            scope.setLevel("warning");
          }

          if (!options?.doNotSendData) {
            if (data) {
              scope.setExtra("data", data);
            }

            if (params) {
              scope.setExtra("params", params);
            }
          }

          Sentry.captureException(
            new APIFailException(
              `${parsedError.errorCode ? `${parsedError.errorCode}:` : ""}${urlKey}`,
            ),
          );
        });
      } catch (sentryError) {
        pendoTrackEvent("SENTRY_ERROR||API_FAIL", sentryError);
      }
    };

    const call = (resolve, reject) => {
      const storeState = AppStore.getState();
      const { isUseOSStagingAPI = false, serviceRegion } = storeState?.DomainConfig || {};
      apiCount += 1;
      let reqUrlPrefix = "";

      if (NODE_ENV === "production" && USE_OS_API) {
        reqUrlPrefix = regionWiseApi?.[serviceRegion] || regionWiseApi?.default;
      }

      if (USE_OS_STAGING_API || (isUseOSStagingAPI && NODE_ENV === "production")) {
        reqUrlPrefix = "https://os-staging.onlinesales.ai";
      }

      if (IS_STAGING_BOX) {
        try {
          const proxy = this.getProxyUrl(url);
          if (proxy?.target) {
            reqUrlPrefix = proxy.target;
          }
        } catch (e) {
          console.log("Error in getting proxy url", e);
        }
      }

      if (SKIP_PROXY || options.isUseOrigin) {
        reqUrlPrefix = "";
      }

      fnRef(`${reqUrlPrefix}${url}`, {
        // fnRef(url, {
        method,
        signal,
        body: data,
        headers: apiHeaders,
        onProgress: restConfig.onProgress,
        ...restConfig,
      })
        .then(async (res) => {
          let responseJson = null;

          if (useXHR) {
            responseJson = res;
          } else {
            try {
              responseJson = await res.json();
            } catch (e) {}
          }

          onAPIComplete();

          if (useXHR || res.ok) {
            return Promise.resolve(responseJson);
          } else {
            return Promise.reject({
              data: responseJson,
              res,
            });
          }
        })
        .then((res) => {
          if (restConfig.onProgress) {
            restConfig.onProgress({
              completed: 100,
              eta: 0,
            });
          }
          resolve(res);
        })
        .catch((err) => {
          const error = parseError(err);

          const retry = {
            ...(retryConfig?.[method] || {}),
            ...(options?.retryConfig || {}),
          };

          const totalRetryCount = retry?.[error.errorType] || retry?.default || 0;

          if (!error?.isAborted && apiCount < totalRetryCount) {
            call(resolve, reject);
          } else {
            if (sendErrorReport && !error?.isAborted) {
              reportError(error);
            }
            error.allSentryIgnoreErrorCode = allSentryIgnoreErrorCode;
            reject(error);
          }
        });
    };

    return new Promise(call);
  }

  static abortByAbortId = (abortId) => {
    const controller = BaseClient?.abortMap?.[abortId];
    if (controller && controller?.abort) {
      controller.abort();
    }
  };

  static abortAllUniqUrl = (reqs) => {
    Object.values(reqs).forEach((controller) => {
      if (controller && controller.abort) {
        controller.abort();
      }
    });
  };

  static abortByMethod = (method, options) => {
    const applicationObj = BaseClient.fetchMap[method] || {};
    const { excludeApplication = [] } = options || {};

    Object.keys(applicationObj)
      .filter((a) => !excludeApplication.includes(a))
      .forEach((applicationKey) => {
        const urlObj = BaseClient.fetchMap[method][applicationKey] || {};

        Object.keys(urlObj).forEach((urlKey) => {
          const reqs = BaseClient.fetchMap?.[method]?.[applicationKey]?.[urlKey];

          if (reqs) {
            BaseClient.abortAllUniqUrl(reqs);
            delete BaseClient.fetchMap[method][applicationKey][urlKey];
          }
        });
      });
  };

  static abortByApplication = (application) => {
    const methodObj = BaseClient.fetchMap || {};

    Object.keys(methodObj).forEach((methodKey) => {
      const urlObj = methodObj?.[methodKey]?.[application] || {};
      Object.keys(urlObj).forEach((urlKey) => {
        const reqs = BaseClient.fetchMap?.[methodKey]?.[application]?.[urlKey];

        if (reqs) {
          BaseClient.abortAllUniqUrl(reqs);
          delete BaseClient.fetchMap[methodKey][application][urlKey];
        }
      });
    });
  };

  static downloadFile = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = url;
    link.style.top = "-100px";
    link.style.left = "-100px";
    link.style.position = "fixed";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  static downloadFileSameWindow = ({ url, fileName, ...restParams }) => {
    return new Promise((resolve, reject) => {
      const isWebView = isBrowserWebView();
      if (isWebView) {
        window.open(url, "_self");
        resolve();
        return;
      }

      const headers = BaseClient.getAPIHeaders();

      fetch(url, {
        credentials: "include",
        headers,
        ...restParams,
      })
        .then(async (response) => {
          if (response.status !== 200) {
            const data = await response.json();
            return Promise.reject(data);
          }

          const contentDisposition = response.headers.get("content-disposition");
          let downloadableFileName = fileName;

          if (
            !downloadableFileName &&
            contentDisposition &&
            contentDisposition.includes("filename=")
          ) {
            downloadableFileName = contentDisposition.split("filename=")?.[1] || "file";
            downloadableFileName = downloadableFileName.replace(/"/g, "");
          }

          const blob = await response.blob();

          return {
            filename: downloadableFileName,
            blob,
          };
        })
        .then(({ filename, blob }) => {
          BaseClient.downloadFile(blob, filename);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  static setProxyConfig = (config, proxyKey) => {
    const proxy = config[proxyKey || STAGING_PROXY_KEY || "default"] || {};

    const keys = Object.keys(proxy).sort((a, b) => b.length - a.length);

    BaseClient.proxyConfig = {
      proxy,
      keys,
    };
  };

  static getProxyConfigForUrl = (url) => {
    const urlToUse = url.split("?")[0];

    const { proxy = {}, keys = [] } = BaseClient.proxyConfig || {};

    const matchedKey = keys.find((key) => urlToUse.startsWith(key));

    if (matchedKey) {
      return proxy[matchedKey];
    }

    return "";
  };

  // static replaceFirstSegment(url, replacement) {
  //   if (!replacement) {
  //     return url;
  //   }
  //   // Split the URL into parts based on `/`
  //   const parts = url.split("/");

  //   // If there are at least two parts, replace the second part
  //   if (parts.length > 1) {
  //     parts[1] = replacement;
  //   }

  //   // Join the parts back together with `/`
  //   return parts.join("/");
  // }

  static getProxyUrl(url) {
    const proxyConfig = this.getProxyConfigForUrl(url);

    if (proxyConfig?.target) {
      return {
        target: proxyConfig.target,
        // url: this.replaceFirstSegment(url, proxyConfig.replace),
      };
    }

    return {};
  }
}

BaseClient.fetchMap = {};
BaseClient.abortMap = {};
BaseClient.proxyMap = {};
BaseClient.proxyConfig = {};

export default BaseClient;
