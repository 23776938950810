import React, { useMemo, useEffect, useState } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import { InputText } from "@onlinesales-ai/form-components-v2";
import { InceptionService } from "@onlinesales-ai/services-v2";
import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import "./index.less";

const GstDetails = ({
  title,
  dataKey,
  regexForGSTNumber,
  formValues,
  formErrors,
  onError,
  gstInfoKeys,
  gstDetailsToPost,
  validations: pValidations,
  isEditable,
  ...props
}) => {
  const regex = new RegExp(regexForGSTNumber);
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    errorMsg: null,
  });
  const [data, setData] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [validations, setValidations] = useState(pValidations);

  const value = useMemo(() => {
    return _get(formValues, dataKey);
  }, [formValues, dataKey]);

  useEffect(() => {
    if (formValues?.entityId && value) {
      setValidations([
        {
          type: "nonEmpty",
          msg: "GST Number can not be empty, once it is added",
        }, ...validations,
      ]);
    }
  }, []);

  const validate = () => {
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (_isEmpty(value)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "regex":
          {
            if (!_isEmpty(value) && !regex.test(value)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "isVerified":
          {
            if (!_isEmpty(value) && !isVerified) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "isLoading":
          {
            if (fetchState?.isLoading) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }
      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }
    onError({ [dataKey]: errorMsg });
  };

  useEffect(() => {
    validate();
  }, [value, isVerified, fetchState?.isLoading, validations]);

  const fetchData = async () => {
    setFetchState({
      isLoading: true,
      errorMsg: null,
    });
    setIsVerified(false);
    const payload = {
      gstNumber: value,
    };

    let gstDetails = {};
    let errorMsg = null;

    try {
      const response = await InceptionService.fetchGstDetails(payload);
      const isGSTVerified = !!response?.data?.isValid;
      if (isGSTVerified) {
        gstDetails = response?.data?.gstDetails || {};
        setData(gstDetails);
      } else {
        setData({});
      }
      setIsVerified(isGSTVerified);
    } catch (error) {
      uiAPIMonitor("SEV2", "OTT_GST_DETAILS_FETCH_FAILED", { error });
      errorMsg = error?.errorMsg || "Error while fetching GST details";
      setIsVerified(false);
    } finally {
      const { onChange } = props;

      const gstToSave = {
        [dataKey]: value,
      };

      gstDetailsToPost.map((info) => {
        gstToSave[info.dataKey] = gstDetails[info.gstDataKey] || "";
      });

      onChange(gstToSave);

      setFetchState({
        isLoading: false,
        errorMsg,
      });
    }
  };

  useEffect(() => {
    if (value && regex.test(value)) {
      fetchData();
    } else {
      setData({});
    }
  }, [value]);

  const gstLoader = () => {
    return (
      <div className="details-row-loading">
        <div className="row">
          <div className="col-sm-4">
            <div className="label animated-bg" />
          </div>
          <div className="col-sm-8">
            <div className="value animated-bg" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="label animated-bg" />
          </div>
          <div className="col-sm-8">
            <div className="address animated-bg" />
          </div>
        </div>
      </div>
    );
  };

  const gstDetailsCom = () => {
    return (
      <>
        {fetchState?.isLoading || fetchState?.errorMsg ? (
          <FetchBanner
            {...fetchState}
            Loader={gstLoader()}
            errorTitle="Error while validating GST number"
          />
        ) : (
          <>
            <div className="gst-details">
              <div className="details-row">
                {gstInfoKeys?.map((info) => {
                  return (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>{info?.label}:</label>
                      </div>
                      <div className="col-sm-8">{data?.[info?.val] || "NA"}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            {isEditable && isVerified ? (
              <div className="guide-text mt-1">
                For any changes in the billing address, kindly reach out to us at
                adsmanager.support@hotstar.com, and please attach a supporting GST certificate as
                address proof.
              </div>
            ) : null}
          </>
        )}
      </>
    );
  };

  return (
    <InputText
      {...props}
      isEditable={isEditable}
      dataKey={dataKey}
      formValues={formValues}
      formErrors={formErrors}
      onError={() => {}}
      title={title}
      formGroupClassName="gst-details-wrapper"
      validations={[]}
      guidElement={gstDetailsCom()}
      alwaysShowGuide
    />
  );
};

GstDetails.defaultProps = {
  title: "GST",
  gstInfoKeys: [
    {
      label: "Legal Name",
      val: "legalName",
    },
    {
      label: "Address",
      val: "businessAddress",
    },
  ],
  gstDetailsToPost: [
    {
      dataKey: "metadata.businessLegalInfo.legalName",
      gstDataKey: "legalName",
    },
    {
      dataKey: "metadata.businessLegalInfo.businessAddress",
      gstDataKey: "businessAddress",
    },
    {
      dataKey: "metadata.businessLegalInfo.businessAddressLines",
      gstDataKey: "businessAddressLines",
    },
    {
      dataKey: "metadata.businessLegalInfo.businessAddressInfo",
      gstDataKey: "businessAddressInfo",
    },
  ],
};

export default GstDetails;
