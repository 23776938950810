import React, { useEffect, useRef, useCallback, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";

import { setMediaQueryDevice as setMediaQueryDeviceAction } from "@onlinesales-ai/app-v2/application";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import {
  OSHOCWithUtilities,
  swithToNewToast,
  swithToNewConfirmartion,
} from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { ToastContainer } from "@onlinesales-ai/toast-v2";
import { Sentry, startSentryReplay } from "@onlinesales-ai/error-catcher-v2";

import {
  DetectDevice,
  loadManifestFromDomainConfig,
  newFeatureTriggerCallback,
  AdBlockerNotification,
  HelpSectionIframe,
  GuideProcessor,
  ViewPortTracking,
  populateEvent,
  getUserInfo,
  TranslationErrorDetector,
} from "@onlinesales-ai/addons-handler-v2";
import { getEntityInfo } from "@onlinesales-ai/ott-common-v2";

swithToNewToast();
swithToNewConfirmartion();

const idConvertor = (id, environment) => {
  if (environment) {
    return `${environment}_${id}`;
  }
  return id;
};

const getOTTVisitorAccountObjAction = () => {
  return (dispatch, getState) => {
    const storeState = getState();

    const { Application, DomainConfig } = storeState || {};
    const { isInternalUser, agencyId, selectedEntityType, selectedEntityId, entityInfo } =
      Application;
    const { partnerType, agencyName, environment } = DomainConfig;

    const userInfo = getUserInfo(true);

    const isInternalUserToUse = isInternalUser || Application?.isInternalUser;

    let entity = {};

    if (selectedEntityType && selectedEntityId) {
      entity = getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);
    } else {
      entity = { entityId: `AGENCY_${agencyId}`, entityName: agencyName };
    }

    return {
      visitor: {
        id: idConvertor(userInfo?.id, environment),
        email: userInfo.email,
        full_name: userInfo.name,
        phone: userInfo.contactNo,
        isInternalUser: isInternalUserToUse,
        partnerType,
      },
      account: {
        id: idConvertor(entity?.entityId, environment),
        name: entity.entityName,
        agencyName,
        agencyId: idConvertor(agencyId, environment),
        businessDefinition: entity?.metadata?.businessDefinition,
      },
      disablePersistence: true,
      events: {
        guidesLoaded: () => {
          PlatformEventManager.emit("PENDO_GUIDE_LOADED");
        },
      },
    };
  };
};

const AddOnsHandler = ({
  isDetectDevice,
  userInfo,
  agencyId,
  domainConfig,
  isInternalUser,
  setMediaQueryDevice,
  redirectUrl,
  loginUrl,
  showConfirmationModal,
  resetConfirmationModal,
  children,
  showToastMessage,
  selectedEntityId,
  selectedEntityType,
  getOTTVisitorAccountObj,
}) => {
  const { t } = useTranslation();
  const isPendoInitialized = useRef(false);
  const isSentryReplayInitialized = useRef(false);
  const isClarityInitialised = useRef(false);
  const [helpDrawerConfig, setHelpDrawerConfig] = useState({
    isShow: false,
    iframeUrl: null,
    headerTitle: "",
  });

  const { newFeatureTagConfig, pageTitle, faviconUrl, customTheme } = domainConfig || {};

  const initializeClarityTags = () => {
    if (!isClarityInitialised.current && !_isEmpty(userInfo) && typeof window?.clarity === "function") {
      try {
        window.clarity("set", "userId", `${userInfo.id}`);
        window.clarity("set", "agencyId", `${agencyId}`);
        window.clarity("set", "isInternalUser", `${isInternalUser}`);
        window.clarity("set", "platformType", `${domainConfig?.platformType}`);
        if (selectedEntityType && selectedEntityId) {
          Sentry.setTag("entityId", `${selectedEntityType}_${selectedEntityId}`);
        } else {
          Sentry.setTag("entityId", null);
        }
        isClarityInitialised.current = true;
      } catch (e) {}
    }
  };

  useEffect(() => {
    if (pageTitle) {
      window.document.title = pageTitle;
    }
    if (faviconUrl) {
      const link = document.querySelector("link[rel~='icon']");
      link.href = faviconUrl;
    }
    if (customTheme) {
      const tempBody = document.getElementsByTagName("body")[0];
      tempBody.setAttribute("data-theme", customTheme);
    }
    loadManifestFromDomainConfig(domainConfig);
  }, [domainConfig]);

  useEffect(() => {
    if (!_isEmpty(userInfo)) {
      populateEvent("APP", "App.TrackerReady", {});
    }
  }, [userInfo]);

  useEffect(() => {
    initializeClarityTags();
    if (
      !isPendoInitialized.current &&
      !domainConfig?.disablePendoTracking &&
      window.pendo?.initialize
    ) {
      window.pendo.initialize(getOTTVisitorAccountObj());

      // Dismiss all previous guides to avoid peristance
      if (domainConfig?.disablePendoGuidePersistance) {
        try {
          window.pendo.onGuideDismissed();
        } catch (e) {}
      }
      isPendoInitialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (!domainConfig?.disablePendoTracking && !_isEmpty(userInfo)) {
      if (window.pendo?.updateOptions) {
        window.pendo.updateOptions(getOTTVisitorAccountObj());
      }
    }

    try {
      if (!_isEmpty(userInfo)) {
        Sentry.setUser({
          email: userInfo.email,
          userInfo,
          isInternalUser,
        });
        Sentry.setTag("isInternalUser", isInternalUser);
        Sentry.setTag("userId", userInfo.id);
        if (selectedEntityType && selectedEntityId) {
          Sentry.setTag("entityId", `${selectedEntityType}_${selectedEntityId}`);
        } else {
          Sentry.setTag("entityId", null);
        }
        Sentry.setTag("agencyId", agencyId);
        Sentry.setTag("domain", window?.location?.hostname);

        if (
          domainConfig?.enableSentryReplay &&
          ENABLE_SENTRY &&
          NODE_ENV === "production" &&
          userInfo?.id &&
          !isSentryReplayInitialized.current
        ) {
          startSentryReplay();
          isSentryReplayInitialized.current = true;
        }
      }
    } catch (err) {
      pendoTrackEvent("SENTRY_ERROR||SET_USER", err);
    }

    initializeClarityTags();
  }, [
    userInfo,
    domainConfig?.disablePendoTracking,
    isInternalUser,
    selectedEntityId,
    selectedEntityType,
  ]);

  const onUATokenInvalid = useCallback(() => {
    showConfirmationModal({
      isShow: true,
      title: t(`You have been logged out from all the devices. Please try logging in again.`),
      actionBtnText: "OK",
      rightBtnText: "",
      headerTitle: "Alert",
      actionBtnCallback: () => {
        resetConfirmationModal();
        redirectUrl(loginUrl);
      },
    });
  }, []);

  const onVersionOutdated = useCallback(() => {
    showConfirmationModal({
      isShow: true,
      title: t(
        `Hey There, we have upgraded our system. Please refresh the app for the seamless experience.`,
      ),
      actionBtnText: "Refresh",
      rightBtnText: "",
      headerTitle: "Alert",
      actionBtnCallback: () => {
        try {
          window.top.location.reload();
        } catch (error) {}
      },
    });
  }, []);

  const onOpenHelpDrawer = useCallback((data) => {
    setHelpDrawerConfig({
      isShow: true,
      ...data,
    });
  }, []);

  useEffect(() => {
    window._sokEvents = {
      populateEvent,
    };
    PlatformEventManager.on("INVALID_UA_TOKEN", onUATokenInvalid);
    PlatformEventManager.on("VERSION_OUTDATED", onVersionOutdated);
    PlatformEventManager.on("OPEN_HELP_DRAWER", onOpenHelpDrawer);
  }, []);

  useEffect(() => {
    // trigger function once initially and then retrigger on dom changes
    if (newFeatureTagConfig?.selectors?.length) {
      newFeatureTriggerCallback(newFeatureTagConfig);
    }
  }, []);

  useEffect(() => {
    let observer;
    if (newFeatureTagConfig?.selectors?.length) {
      const observedDOMNode = document.body;

      // Options for the observer (which mutations to observe)
      const config = { childList: true, subtree: true, attributes: true };

      // Create an observer instance linked to the callback function
      observer = new MutationObserver(() => {
        newFeatureTriggerCallback(newFeatureTagConfig);
      });

      // Start observing the target node for configured mutations
      observer.observe(observedDOMNode, config);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [newFeatureTagConfig]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={5}
        // closeButton={false}
      />
      <Drawer
        isOpen={helpDrawerConfig.isShow}
        onClickClose={() => {
          setHelpDrawerConfig({
            isShow: false,
            iframeUrl: null,
          });
        }}
      >
        <HelpSectionIframe {...helpDrawerConfig} />
      </Drawer>
      {isDetectDevice && <DetectDevice onChange={setMediaQueryDevice} />}
      {children}
      <GuideProcessor />
      <ViewPortTracking />
      <TranslationErrorDetector />
      {domainConfig.showAdBlockerNotification ? (
        <AdBlockerNotification showToastMessage={showToastMessage} />
      ) : null}
    </>
  );
};

AddOnsHandler.defaultProps = {
  isDetectDevice: true,
  loginUrl: "/login",
};

const mapStateToProps = (state) => {
  const { isMobile, userInfo, isInternalUser, selectedEntityId, selectedEntityType, agencyId } =
    state.Application;

  return {
    isMobile,
    userInfo,
    isInternalUser,
    selectedEntityId,
    selectedEntityType,
    agencyId,
    domainConfig: state.DomainConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setMediaQueryDevice: setMediaQueryDeviceAction,
      getOTTVisitorAccountObj: getOTTVisitorAccountObjAction,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(OSHOCWithUtilities(AddOnsHandler)));
