import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";
import _isEqual from "lodash/isEqual";
import { isAfter, isBefore, addDays, subDays } from "date-fns/esm";

import { format, formattedDate } from "@onlinesales-ai/util-methods-v2";
import { DatePicker as SingleDatePicker, UTCDatePicker as UTCSingleDatePicker, computeTime } from "@onlinesales-ai/datepicker-v2";

import FormWrapper from "../../FormWrapper";

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minDate: props.minDateConfig
        ? computeTime(props.minDateConfig.type, props.minDateConfig.value)
        : props.minDate,
      maxDate: props.maxDateConfig
        ? computeTime(props.maxDateConfig.type, props.maxDateConfig.value)
        : props.maxDate,
    };

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      validations,
      compareMaxDataKey,
      compareMinDataKey,
      addDaysToMinDate,
      subDaysFromMaxDate,
      minDate: pMinDate,
      maxDate: pMaxDate,
      allowEmptyDateValidation,
    } = this.props;
    const { minDate, maxDate } = this.state;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);
    const isCompareValueChanged = !_isEqual(_get(this.props.formValues, this.props.compareDataKey), _get(nextProps.formValues, nextProps.compareDataKey)) 

    if (newValue !== this.getValue() || !_isEqual(validations, nextProps.validations) || isCompareValueChanged) {
      this.validate(newValue, nextProps.validations, nextProps.formValues);
    }

    if (((minDate && pMinDate) || allowEmptyDateValidation) && minDate !== pMinDate) {
      this.setState({ minDate: pMinDate });
    }

    if (((maxDate && pMaxDate) || allowEmptyDateValidation) && maxDate !== pMaxDate) {
      this.setState({ maxDate: pMaxDate });
    }

    if (compareMinDataKey) {
      let compareMinDateValue = _get(
        nextProps.formValues,
        `${nextProps.dataKeyPrefix || ""}${nextProps.compareMinDataKey}`,
      );

      if (typeof compareMinDateValue === "string") {
        compareMinDateValue = new Date(compareMinDateValue).valueOf();
      }

      if ((minDate && compareMinDateValue) && minDate !== compareMinDateValue) {
        // eslint-disable-next-line max-len
        // Added this condition to not override minDate if compareMinDateValue is old [Lower than current date] (Active campaign case)
        if (isAfter(compareMinDateValue, new Date())) {
          this.setState({
            minDate: addDaysToMinDate
              ? addDays(compareMinDateValue, addDaysToMinDate)
              : compareMinDateValue,
          });
          return;
        }
        this.setState({
          minDate: addDaysToMinDate
            ? addDays(new Date(), addDaysToMinDate).valueOf()
            : new Date().valueOf(),
        });
      }
    }

    if (compareMaxDataKey) {
      let compareMaxDateValue = _get(
        nextProps.formValues,
        `${nextProps.dataKeyPrefix || ""}${nextProps.compareMaxDataKey}`,
      );

      if (typeof compareMaxDateValue === "string") {
        compareMaxDateValue = new Date(compareMaxDateValue).valueOf();
      }

      if ((maxDate && compareMaxDateValue) && maxDate !== compareMaxDateValue) {
        if (subDaysFromMaxDate) {
          this.setState({ maxDate: subDays(compareMaxDateValue, subDaysFromMaxDate) });
          return;
        }
        this.setState({ maxDate: compareMaxDateValue });
      }
    }
  }

  componentDidMount() {
    const { initialDateConfig } = this.props;
    const value = this.getValue();

    if (!value && initialDateConfig) {
      const { type, value: pValue } = initialDateConfig;
      const date = computeTime(type, pValue);

      this.onChangeDate(date);
    }
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  validate = (value, pValidations, formValues) => {
    const {
      onError, dataKey, validations = [], title, compareDataKey,
    } = this.props;
    let errorMsg = null;

    const validationsToUse = pValidations || validations;

    for (let i = 0; i < validationsToUse.length; i++) {
      if (!validationsToUse[i].type || !validationsToUse[i].msg) {
        continue;
      }

      switch (validationsToUse[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validationsToUse[i].msg;
            }
          }
          break;
        case "greaterThanFormValue":
          {
            if (value && _get(formValues, compareDataKey)) {
              if (isAfter(value, _get(formValues, compareDataKey))) {
                errorMsg = validationsToUse[i].msg;
              } else {
                onError({ [compareDataKey]: "" });
              }
            }
          }
          break;
        case "lessThanFormValue":
          {
            if (value && _get(formValues, compareDataKey)) {
              if (isBefore(value, _get(formValues, compareDataKey))) {
                errorMsg = validationsToUse[i].msg;
              } else {
                onError({ [compareDataKey]: "" });
              }
            }
          }
          break;
        case "nonEmptyForFormValue":
          {
            if (!value && _get(formValues, compareDataKey)) {
              errorMsg = validationsToUse[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChangeDate = (value) => {
    const { onChange, dataKey, saveDateFormat } = this.props;
    if (saveDateFormat && value?.valueOf) {
      onChange({
        [dataKey]: format(value, saveDateFormat),
      });
    } else {
      onChange({
        [dataKey]: value?.valueOf ? value.valueOf() : value,
      });
    }
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      contentInfoTooltip,
      formGroupInnerClassName,
      guidElementClass,
      errorClassName,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      enableTime,
      disableIcon,
      showCTAs,
      className,
      shouldCloseOnSelect,
      defaultValueToShow,
      popperPlacement,
      isRequired,
      icon,
      showEmptyTitle,
      labelDefinationKey,
      isUTCDate,
      allowFutureDateOnly,
      labelClassName,
      isClearable,
      defaultValueClassName = "",
      defaultTime = "",
      placeHolder,
      defaultHours,
      defaultMinutes,
      defaultSeconds,
      isErrorPossible,
      nonEditModeClassName,
      extraMinutesOptions,
    } = this.props;

    const {
      minDate, maxDate,
    } = this.state;

    const value = this.getValue();

    const disableOldDate = allowFutureDateOnly ? isBefore(new Date(value), new Date()) : false;

    const Component = isUTCDate ? UTCSingleDatePicker : SingleDatePicker;

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        icon={icon}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        contentInfoTooltip={contentInfoTooltip}
        showEmptyTitle={showEmptyTitle}
        isRequired={isRequired}
        labelDefinationKey={labelDefinationKey}
        labelClassName={labelClassName}
        formGroupInnerClassName={formGroupInnerClassName}
        guidElementClass={guidElementClass}
        errorClassName={errorClassName}
        isErrorPossible={isErrorPossible}
      >
        {isEditable ? (
          <Component
            className={className}
            value={value && new Date(value)}
            onChange={this.onChangeDate}
            disabled={disabled || disableOldDate}
            minDate={minDate}
            maxDate={maxDate}
            enableTime={enableTime}
            isClearable={isClearable}
            disableIcon={disableIcon}
            showCTAs={showCTAs}
            shouldCloseOnSelect={shouldCloseOnSelect}
            popperPlacement={popperPlacement}
            defaultTime={defaultTime}
            placeHolder={placeHolder}
            defaultHours={defaultHours}
            defaultMinutes={defaultMinutes}
            defaultSeconds={defaultSeconds}
            extraMinutesOptions={extraMinutesOptions}
          />
        ) : (
          <div
            className={`non-edit-mode-text ${nonEditModeClassName} ${
              !value ? defaultValueClassName : ""
            }`}
          >
            {value
              ? formattedDate(value, enableTime ? "dateTimeTz" : "date", {
                  convetToZone: isUTCDate,
                })
              : defaultValueToShow}
          </div>
        )}
      </FormWrapper>
    );
  }
}

DatePicker.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  defaultValueToShow: "-",
  formGroupClassName: "form-component-datepicker",
  className: "noborder-noshadow",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(DatePicker);
