const agencyAdvertiserDefaultConfig = {
  gstNumber: {
    componentType: "GstDetails",
    id: "gstNumber",
    props: {
      isCapitalize: true,
      regexForGSTNumber: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
      // isRequired: true,
      placeholder: "Enter GST",
      fallbackValue: "-",
      validations: [
        // {
        //   type: "nonEmpty",
        //   msg: "Please enter GST number",
        // },
        {
          type: "regex",
          msg: "Please enter valid GST number",
        },
        {
          type: "isLoading",
          msg: "Validation in progress",
        },
        {
          type: "isVerified",
          msg: "Please enter valid GST number",
        },
      ],
      dataKey: "metadata.businessLegalInfo.gstNumber",
      title: "GST",
      labelDefinationKey: "OTT_UI_ONBOARDING_GST",
      // inputWrapperClassName: "large-input",
      maxLength: 15,
    },
  },
  panNumber: {
    componentType: "InputText",
    id: "panNumber",
    props: {
      dataKey: "metadata.businessLegalInfo.panNumber",
      title: "PAN Number",
      labelDefinationKey: "OTT_UI_ONBOARDING_PAN_NUMBER",
      placeholder: "Enter PAN Number",
      maxLength: 10,
      fallbackValue: "-",
      isCapitalize: true,
      // isRequired: true,
      validations: [
        // {
        //   type: "nonEmpty",
        //   msg: "Please enter PAN number",
        // },
        {
          type: "regex",
          value: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
          msg: "Please enter valid PAN number",
          doNotValidateEmpty: true,
        },
      ],
    },
  },
  cinNumber: {
    componentType: "InputText",
    id: "cinNumber",
    props: {
      dataKey: "metadata.businessLegalInfo.cinNumber",
      title: "CIN Number",
      labelDefinationKey: "OTT_UI_ONBOARDING_CIN_NUMBER",
      placeholder: "Enter CIN Number",
      guidText: "For private or public company profiles, providing the CIN number is mandatory for verification. Not applicable if not a company.",
      isCapitalize: true,
      // isRequired: true,
      fallbackValue: "-",
      maxLength: 21,
      validations: [
        // {
        //   type: "nonEmpty",
        //   msg: "Please enter CIN number",
        // },
        {
          type: "regex",
          value: "^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$",
          msg: "Please enter valid CIN number",
          doNotValidateEmpty: true,
        },
      ],
    },
  },
  gstCertificate: {
    id: "gstCertificate",
    componentType: "MediaUpload",
    props: {
      shouldShowDirectUploadBtn: true,
      formGroupClassName: "media-upload-form-wrapper",
      showMediaLibrary: false,
      // isRequired: true,
      mediaType: "FILE",
      isStoreExtraDetails: true,
      fallbackValue: "Not Set",
      directUploadFileProps: {
        isValidateDimension: false,
        mediaType: "FILE",
        accept: ".pdf, image/*",
        overlayDomText: "Upload",
        containerClass: "small",
        maxFileSizeInMB: 5,
        fileExtToValidates: [".pdf", ".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp", ".svg", ".CR2", ".tiff", ".tif", ".jp2"], // all image type should accept
      },
      defaultFileType: "application/pdf",
      title: "GST Certificate",
      labelDefinationKey: "OTT_UI_ONBOARDING_GST_CERTIFICATE",
      nonEditableTooltipPopoverMsg: "",
      dataKey: "metadata.businessLegalInfo.gstCertificate.url",
      extraDataKeys: [{ dataKey: "metadata.businessLegalInfo.gstCertificate.fileName", creativeDataKey: "name" }, { dataKey: "metadata.businessLegalInfo.gstCertificate.lastUpdate", creativeDataKey: "lastUpdate" }, { dataKey: "metadata.businessLegalInfo.gstCertificate.fileType", creativeDataKey: "fileType" }],
      fileNameDataKey: "metadata.businessLegalInfo.gstCertificate.fileName",
      fileTypeDataKey: "metadata.businessLegalInfo.gstCertificate.fileType",
      fileNameClass: "ellipsis-text",
      guidText:
        "File type: .pdf or .jpeg or .jpg or .png; Maximum file size: 5 MB",
      isUploadOnCloudinary: true,
      validations: [],
      // validations: [
      //   {
      //     type: "nonEmpty",
      //     msg: "Please upload GST certificate",
      //   },
      // ],
    },
  },
  panCard: {
    id: "panCard",
    componentType: "MediaUpload",
    props: {
      shouldShowDirectUploadBtn: true,
      formGroupClassName: "media-upload-form-wrapper",
      showMediaLibrary: false,
      // isRequired: true,
      mediaType: "FILE",
      isStoreExtraDetails: true,
      fallbackValue: "Not Set",
      directUploadFileProps: {
        isValidateDimension: false,
        mediaType: "FILE",
        accept: ".pdf, image/*",
        overlayDomText: "Upload",
        containerClass: "small",
        maxFileSizeInMB: 5,
        fileExtToValidates: [".pdf", ".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp", ".svg", ".CR2", ".tiff", ".tif", ".jp2"], // all image type should accept
      },
      title: "PAN Card",
      labelDefinationKey: "OTT_UI_ONBOARDING_PAN_CARD_CERTIFICATE",
      defaultFileType: "application/pdf",
      dataKey: "metadata.businessLegalInfo.panCard.url",
      extraDataKeys: [{ dataKey: "metadata.businessLegalInfo.panCard.fileName", creativeDataKey: "name" }, { dataKey: "metadata.businessLegalInfo.panCard.lastUpdate", creativeDataKey: "lastUpdate" }, { dataKey: "metadata.businessLegalInfo.panCard.fileType", creativeDataKey: "fileType" }],
      fileNameDataKey: "metadata.businessLegalInfo.panCard.fileName",
      fileTypeDataKey: "metadata.businessLegalInfo.panCard.fileType",
      fileNameClass: "ellipsis-text",
      guidText:
        "File type: .pdf; Maximum file size: 5 MB",
      isUploadOnCloudinary: true,
      validations: [],
      nonEditableTooltipPopoverMsg: "",
      // validations: [
      //   {
      //     type: "nonEmpty",
      //     msg: "Please upload PAN card",
      //   },
      // ],
    },
  },
};

export const agencyDefaultConfig = {
  isSkippable: false,
  isFormViewOnly: false,
  labelColumns: 4,
  businessDefinition: "AGENCY",
  isBillingEntity: "true",
  title: "Agency Details",
  id: "agencyCreation",
  nextScrollId: "advertiserCreation",
  imgUrl:
    "https://osads.gumlet.io/image/upload/v1679461173/product/office-building.svg",
  description:
    "A vital partner that oversees and executes advertising campaigns for clients and brands.",
  componentToShow: ["agencyName", "gstNumber", "gstCertificate", "panNumber", "panCard", "cinNumber"],
  componentConfig: {
    agencyName: {
      componentType: "InputText",
      id: "agencyName",
      props: {
        dataKey: "entityName",
        title: "Agency Name",
        labelDefinationKey: "OTT_UI_ONBOARDING_AGENCY_NAME",
        placeholder: "Enter Agency Name",
        isRequired: true,
        // inputWrapperClassName: "large-input",
        maxLength: 50,
        validations: [
          {
            type: "nonEmpty",
            msg: "Please enter __FIELD_TITLE__",
          },
          {
            type: "specialCharacters",
            msg: "The __FIELD_TITLE__ contains certain characters that aren't allowed.",
          },
        ],
      },
    },
    ...agencyAdvertiserDefaultConfig,
  },
  candyConfig: {
    isShow: true,
    candyDescription: "Your account will remain unverified until you submit your business details and documents.",
  },
};

export const advertiserDefaultConfig = {
  businessDefinition: "ADVERTISER",
  isSkippable: true,
  isFormViewOnly: false,
  labelColumns: 4,
  id: "advertiserCreation",
  nextScrollId: "brandCreation",
  suggestionInfoText: "Don't worry you can change it later",
  title: "Add your first advertiser under agency",
  description:
    "The entity, be it an individual or company (with a valid GST number), purchasing advertising space to showcase their products or services.",
  imgUrl: "https://osads.gumlet.io/image/upload/v1679461173/product/businessman.svg",
  componentToShow: ["advertiserName", "advertiserLogo", "gstNumber", "gstCertificate", "panNumber", "panCard", "cinNumber"],
  componentConfig: {
    advertiserName: {
      id: "advertiserName",
      componentType: "InputText",
      props: {
        isRequired: true,
        dataKey: "entityName",
        title: "Advertiser Name",
        labelDefinationKey: "OTT_UI_ONBOARDING_ADVERTISER_NAME",
        guidText: "Please enter the name of the Advertiser for which you intend to set up the campaign.",
        maxLength: 50,
        placeholder: "Enter Advertiser Name",
        validations: [
          {
            type: "nonEmpty",
            msg: "Please enter __FIELD_TITLE__",
          },
          {
            type: "specialCharacters",
            msg: "The __FIELD_TITLE__ contains certain characters that aren't allowed.",
          },
        ],
      },
    },
    advertiserLogo: {
      id: "advertiserLogo",
      componentType: "MediaUpload",
      props: {
        shouldShowDirectUploadBtn: true,
        formGroupClassName: "media-upload-form-wrapper",
        showMediaLibrary: false,
        fallbackValue: "Not Set",
        directUploadFileProps: {
          overlayDomText: "Upload",
          containerClass: "small",
          dimensionValidation: {
            aspectRatio: {
              width: 1,
              height: 1,
            },
            height: 120,
            width: 120,
          },
          maxFileSizeInMB: 0.05,
        },
        title: "Advertiser Logo",
        dataKey: "metadata.logoUrl",
        fileNameClass: "ellipsis-text",
        guidText:
          "File type: .jpg or .png; Aspect ratio: 1:1(120px x 120px); Maximum file size: 50KB",
        isUploadOnCloudinary: true,
        validations: [],
        nonEditableTooltipPopoverMsg: "",
        // validations: [
        //   {
        //     type: "nonEmpty",
        //     msg: "Please upload __FIELD_TITLE__",
        //   },
        // ],
      },
    },
    ...agencyAdvertiserDefaultConfig,
  },
  candyConfig: {
    isShow: true,
    candyDescription: "Your account will remain unverified until you submit your business details and documents.",
  },
};

export const brandDefaultConfig = {
  isSkippable: true,
  isFormViewOnly: false,
  labelColumns: 4,
  businessDefinition: "CLIENT",
  title: "Add your first brand under advertiser",
  id: "brandCreation",
  suggestionInfoText: "Don't worry you can change it later",
  description:
    "Represents a distinct product or service actively advertised and promoted on the platform.",
  imgUrl: "https://osads.gumlet.io/image/upload/v1679461173/product/suitcase.svg",
  componentToShow: ["name", "logo", "brandWebsite", "description", "industry"],
  componentConfig: {
    name: {
      id: "name",
      componentType: "InputText",
      props: {
        dataKey: "entityName",
        title: "Brand Name",
        guidText: "Kindly provide the brand name for which you wish to set up the campaign.",
        maxLength: 50,
        isRequired: true,
        placeholder: "Enter Brand Name",
        validations: [
          {
            type: "nonEmpty",
            msg: "Please enter __FIELD_TITLE__",
          },
          {
            type: "specialCharacters",
            msg: "The __FIELD_TITLE__ contains certain characters that aren't allowed.",
          },
        ],
      },
    },
    logo: {
      id: "logo",
      componentType: "MediaUpload",
      props: {
        formGroupClassName: "media-upload-form-wrapper",
        shouldShowDirectUploadBtn: true,
        showMediaLibrary: false,
        title: "Brand Logo",
        dataKey: "metadata.logoUrl",
        isRequired: true,
        directUploadFileProps: {
          overlayDomText: "Upload",
          containerClass: "small",
          dimensionValidation: {
            aspectRatio: {
              width: 1,
              height: 1,
            },
            height: 120,
            width: 120,
          },
          maxFileSizeInMB: 0.05,
        },
        fileNameClass: "ellipsis-text",
        guidText:
          "File type: .jpg or .png; Aspect ratio: 1:1(120px x 120px); Maximum file size: 50KB",
        isUploadOnCloudinary: true,
        validations: [
          {
            type: "nonEmpty",
            msg: "Please upload __FIELD_TITLE__",
          },
        ],
        nonEditableTooltipPopoverMsg: "",
      },
    },
    description: {
      id: "description",
      componentType: "InputText",
      props: {
        title: "Short Description",
        placeholder: "Enter Short Description",
        dataKey: "metadata.description",
        validations: [
          {
            type: "maxLength",
            msg: "Maximum characters should be 1000",
            value: 1000,
          },
          {
            type: "specialCharacters",
            msg: "The __FIELD_TITLE__ contains certain characters that aren't allowed.",
          },
        ],
      },
    },
    industry: {
      id: "industry",
      componentType: "IndustrySelection",
      props: {
        title: "Industry",
        isRequired: true,
        placeholder: "Select Industry",
        dataKey: "metadata.industryId",
        nameDataKey: "metadata.industryName",
        options: [],
        drodownProps: {
          menuPosition: "fixed",
          menuPlacement: "top",
        },
        validations: [
          {
            type: "nonEmpty",
            msg: "Please select Industry",
          },
        ],
      },
    },
    brandWebsite: {
      id: "brandWebsite",
      componentType: "InputText",
      props: {
        isRequired: true,
        dataKey: "metadata.url",
        title: "Brand Website",
        placeholder: "Enter Brand Website",
        enablePrefixControl: true,
        prefixOptions: [
          {
            value: "https://",
            label: "https://",
          },
        ],
        validations: [
          {
            type: "nonEmpty",
            msg: "Please enter __FIELD_TITLE__",
          },
          {
            type: "url",
            msg: "Please enter valid URL, like https://example.com",
          },
          {
            type: "maxLength",
            msg: "Maximum characters should be 1000",
            value: 1000,
          },
        ],
      },
    },
  },
};

export const overrideByOption = {
  AGENCY: {
    stepsConfig: {
      ADVERTISER: {
        componentToShow: ["advertiserName", "advertiserLogo", "panNumber", "gstNumber", "gstCertificate", "panCard", "cinNumber"],
        candyConfig: {
          isShow: false,
        },
      },
    },
  },
  ADVERTISER: {
    stepsConfig: {
      ADVERTISER: {
        isSkippable: false,
        businessDefinition: "ADVERTISER",
        isBillingEntity: "true",
        title: "Advertiser Details",
        suggestionInfoText: null,
      },
    },
  },
};

export const newAgencyDefaultConfig = {
  defaultConfig: { ...agencyDefaultConfig },
  overideConfig: {
    jobDoneCTA: "Create Account",
    componentToShow: ["agencyName"],
    candyConfig: {
      isShow: false,
    },
    primaryCta: true,
    showHeader: false,
  },
};

export const newAdvertiserDefaultConfig = {
  defaultConfig: { ...advertiserDefaultConfig },
  overideConfig: {
    componentToShow: ["advertiserName"],
    componentConfig: {
      advertiserName: {
        props: {
          guidText: "",
        },
      },
    },
    suggestionInfoText: "",
    isSkippable: false,
    jobDoneCTA: "Create Account",
    candyConfig: {
      isShow: false,
    },
    primaryCta: true,
    showHeader: false,
  },
};
