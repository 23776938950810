import { initializeTranslations } from "@onlinesales-ai/i18n-v2";

import enTranslations from "./en/allTranslations";

const loadedTranslations = {
  en: enTranslations.translations,
};

const lazyTranslations = {};

initializeTranslations({
  languagesSupported: [...Object.keys(lazyTranslations), Object.keys(loadedTranslations)],
  loadedTranslations,
  lazyTranslations,
});
