import { formComponents } from "@onlinesales-ai/form-components-v2";
import { MediaLibraryForm as MediaUpload } from "@onlinesales-ai/media-library-v2";

import GstDetails from "./gstDetails";
import IndustrySelection from "./industrySelection";

export default {
  IndustrySelection,
  GstDetails,
  MediaUpload,
  ...formComponents,
};
