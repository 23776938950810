import React from "react";
import { withTranslation } from "react-i18next";
import _isEmpty from "lodash/isEmpty";

import { Input } from "@onlinesales-ai/input-v2";
import { DynamicField } from "@onlinesales-ai/utils-components-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import {
  formatNumberInAbbreviations,
  formatValuesInThousands,
  getCurrencyCode,
  getCurrencyIconCode,
  format,
  formatWithTZ,
  isNullOrUndefined,
  getCurrencyPosition,
} from "@onlinesales-ai/util-methods-v2";
import { Text } from "@onlinesales-ai/label-v2";

import FormWrapper from "../../FormWrapper";

const InputField = (props) => {
  const {
    // FormWrapper props
    labelColumns,
    formGroupClassName,
    formGroupInnerClassName,
    warningicon,
    guidText,
    guidElement,
    guidElementClass,
    hasError,
    apiError,
    warning,
    title,
    error,
    errorClassName,
    titleTooltip,
    tooltipProps,
    titleGuidText,
    infoTooltip,
    isRequired,
    errorWarning,
    contentInfoTooltip,
    labelBottomComponent,
    secondaryLabel,

    // input props
    isEditable,
    maxLength,
    showCurrency,
    precision,
    formatNumber,
    isFormatInThousands,
    placeholder,
    value,
    type,
    onChange,
    suffix,
    showSuffixInNotEditMode,
    prefix,
    t,
    inputWrapperClassName,
    doNotShowCharLimit,
    // this value will be shown when value is undefined, only to be used in non editable label mode
    fallbackValue,
    labelClassName,

    // dynamic field props
    enableDynamicField,
    dynamicOptions,
    dynamicTemplate,
    toolTip,
    placement,
    labelDefinationKey,
    isResetContent,
    contentClassName,
    nonEditModeClassName,
    noValueEditModeClassName,
    ifNoValue,
    alwaysShowGuide,
    defaultPlaceholder,
    dateFormat,
    icon,
    showEmptyTitle,
    formatTimezone,
    guideElementMarkup,
    isErrorPossible,
    customErrorClass,
    ...restProps
  } = props;
  let currencyClass = "";
  let characterLimit = null;
  let currencyPosition = getCurrencyPosition();

  if (showCurrency) {
    currencyClass = getCurrencyIconCode(getCurrencyCode());
  }

  if (!doNotShowCharLimit && maxLength && !suffix) {
    characterLimit = `${value ? value.length : 0}/${maxLength}`;
  }

  let valueToShow;

  if (type === "number" && formatNumber) {
    if (isFormatInThousands) {
      valueToShow = formatValuesInThousands(value || 0, precision);
    } else {
      valueToShow = formatNumberInAbbreviations(value || 0, precision);
    }
  } else if (dateFormat && !isNullOrUndefined(value)) {
    valueToShow = formatTimezone
      ? formatWithTZ(new Date(value), dateFormat)
      : format(new Date(value), dateFormat);
  } else if (!value && fallbackValue) {
    valueToShow = fallbackValue;
  } else {
    valueToShow = value;
  }

  const onSelectDynamicField = (param) => {
    onChange(DynamicField.valueReplacer(value, param));
  };

  const inputTag = (inputRef) => {    
    return (
      <Input
        ref={inputRef}
        type={type}
        value={value}
        currencyClass={currencyClass}
        currencyPosition={currencyPosition}
        maxLength={maxLength}
        characterLimit={characterLimit}
        precision={precision}
        formatNumber={formatNumber}
        isFormatInThousands={isFormatInThousands}
        onChange={onChange}
        placeholder={t(placeholder)}
        suffix={suffix}
        prefix={prefix}
        inputWrapperClassName={inputWrapperClassName}
        {...restProps}
        isResetContent={isResetContent}
      />
    );
  };

  const checkEmptyValue = (val) => {
    return (
      (typeof val === "string" && _isEmpty(val)) ||
      (typeof val === "number" && Number.isNaN(val)) ||
      isNullOrUndefined(val)
    );
  };

  const renderInput = ({ inputRef }) => {
    return (
      <>
        {toolTip ? (
          <WithTooltip title={toolTip} placement={placement}>
            {inputTag(inputRef)}
          </WithTooltip>
        ) : (
          inputTag(inputRef)
        )}
      </>
    );
  };

  return (
    <FormWrapper
      labelColumns={labelColumns}
      formGroupClassName={formGroupClassName}
      formGroupInnerClassName={formGroupInnerClassName}
      guidText={guidText}
      warningicon={warningicon}
      guidElement={guidElement}
      guidElementClass={guidElementClass}
      hasError={hasError}
      error={error}
      errorClassName={errorClassName}
      apiError={apiError}
      warning={warning}
      errorWarning={errorWarning}
      title={title}
      isEditable={isEditable}
      titleTooltip={titleTooltip}
      titleGuidText={titleGuidText}
      infoTooltip={infoTooltip}
      isRequired={isRequired}
      labelDefinationKey={labelDefinationKey}
      tooltipProps={tooltipProps}
      contentClassName={contentClassName}
      contentInfoTooltip={contentInfoTooltip}
      labelBottomComponent={labelBottomComponent}
      secondaryLabel={secondaryLabel}
      alwaysShowGuide={alwaysShowGuide}
      icon={icon}
      showEmptyTitle={showEmptyTitle}
      guideElementMarkup={guideElementMarkup}
      labelClassName={labelClassName}
      isErrorPossible={isErrorPossible}
      customErrorClass={customErrorClass}
    >
      {isEditable ? (
        <>
          {enableDynamicField ? (
            <DynamicField
              valueTemplate={dynamicTemplate}
              options={dynamicOptions}
              onSelect={onSelectDynamicField}
            >
              {renderInput}
            </DynamicField>
          ) : (
            renderInput({})
          )}
        </>
      ) : (
        <Text
          showEllipsis
          className={`non-edit-mode-text ${nonEditModeClassName || ""} ${
            !value ? noValueEditModeClassName : ""
          }`}
        >
          {checkEmptyValue(value) && ifNoValue ? (
            ifNoValue
          ) : (
            <>
              {currencyClass && currencyPosition === "LEFT" && (
                <>
                  <span className="currency-symbol d-align-center">
                    <span className={currencyClass} />
                  </span>{" "}
                </>
              )}
              <span>
                {valueToShow || defaultPlaceholder}
                {showSuffixInNotEditMode && suffix ? suffix : ""}
              </span>
              {currencyClass && currencyPosition === "RIGHT" && (
                <>
                  <span className="currency-symbol d-align-center">
                    <span className={currencyClass} />
                  </span>{" "}
                </>
              )}
            </>
          )}
        </Text>
      )}
    </FormWrapper>
  );
};

export default withTranslation()(InputField);
