import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";

import { Router } from "@onlinesales-ai/app-v2";
import { triggerMonitor, useTimeBasedMonitor } from "@onlinesales-ai/error-catcher-v2";

import { routes } from "src/utilities/constants";
import { redirectUrl as redirectUrlAction, changeEntityId as changeEntityIdAction } from "src/store/application";
import RouteBasedDrawer from "src/pages/routeBasedDrawer";
import RouteBasedModal from "src/pages/routeBasedModal";
import AppHeader from "src/components/header";

import AppAndAuthData from "./AppAndAuthData";
import EntityLevelData from "./EntityLevelData";
import { openRoutes, protectedRoutes } from "./routeList";

const AuthAndClientComp = () => (
  <>
    <AppAndAuthData />
    <EntityLevelData />
  </>
);

const Routes = ({
  entitiesFetchInProgress,
  entitiesFetchError,
  userFetchError,
  isOnboardingFetchInProgress,
  redirectUrl,
  changeEntityId,
  userInfo,
  pathname,
}) => {
  const isAppLoading = entitiesFetchInProgress || isOnboardingFetchInProgress;
  const hasAppError = userFetchError || entitiesFetchError;

  useEffect(() => {
    let timer;
    if (!_isEmpty(userInfo) && isAppLoading) {
      timer = setTimeout(() => {
        if (isAppLoading) {
          triggerMonitor("SEV2", "OTT_APP_LOADING_TIMEOUT", {
            error: "App is taking more than expected time to load.",
          });
        }
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [userInfo, isAppLoading]);

  useTimeBasedMonitor(
    {
      data: isAppLoading,
      checkCondition: () => !openRoutes?.[pathname] && !!userInfo?.id,
      dataShouldBe: false,
      dependancy: `${pathname}+${userInfo?.id}`,
    },
    10000,
    "SEV2",
    "APP_LEVEL_LOADING",
    {
      isAppLoading,
      isOnboardingFetchInProgress,
      entitiesFetchInProgress,
    },
  );

  useEffect(() => {
    if (hasAppError) {
      triggerMonitor("SEV2", "OTT_APP_LEVEL_ERROR", {
        hasAppError,
        userFetchError,
        entitiesFetchError,
      });
    }
  }, [hasAppError]);

  return (
    <Router
      components={{
        HEADER: AppHeader,
        AUTH_AND_CLIENT: AuthAndClientComp,
        ROUTE_BASED_DRAWER: RouteBasedDrawer,
        ROUTE_BASED_MODAL: RouteBasedModal,
      }}
      routes={routes}
      isAppLoading={isAppLoading}
      hasAppError={hasAppError}
      openRoutes={openRoutes}
      protectedRoutes={protectedRoutes}
      redirectUrl={redirectUrl}
      changeClientId={changeEntityId}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.Application.userInfo,
    entitiesFetchInProgress: state.Application.entitiesFetchInProgress,
    entitiesFetchError: state.Application.entitiesFetchError,
    userFetchError: state.Application.userFetchError,
    isOnboardingFetchInProgress: state.OnBoarding.isLoading,
    pathname: state.router?.location?.pathname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
      changeEntityId: changeEntityIdAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
