export const routes = {
  LOGIN: {
    path: "/login",
  },
  FORGOT_PASSWORD: {
    path: "/forgotPassword",
  },
  CREATE_PASSWORD: {
    path: "/createPassword",
  },
  SIGN_UP: {
    path: "/signUp",
  },
  SIGN_UP_CALLBACK: {
    path: "/signUpCallback",
  },
  DASHBOARD: {
    path: "/home",
  },
  HOME: {
    path: "/home",
  },
  SPORTS_SUMMARY: {
    path: "/sports/summary",
  },
  // SPORTS_TOURNAMENT: {
  //   path: "/sports/tournament",
  // },
  // SPORTS_TARGETING: {
  //   path: "/sports/targeting",
  // },
  // SPORTS_LEAD_GENERATION: {
  //   path: "/sports/leadGeneration",
  // },
  CAMPAIGNS: {
    path: "/campaigns",
  },
  REPORTS: {
    path: "/reports",
  },
  BILLING: {
    path: "/billing",
  },
  ONBOARDING: {
    path: "/onboarding",
  },
  DOWNTIME: {
    path: "/downtime",
  },
  PAYU_PAYMENT_WINDOW_CALLBACK: {
    path: "/payuPaymentWindowCallback",
  },
  ROUTE_BASED_DRAWER: {
    path: "/drawer",
  },
  ROUTE_BASED_MODAL: {
    path: "/modal",
  },
  ROUTE_BASED_PENDING_ACTION: {
    path: "/pending_action",
  },
  GOOGLE_CALLBACK: {
    path: "/googleCallback",
  },
  SETTINGS: {
    path: "/settings",
  },
  OAUTH_CALLBACK: {
    path: "/oauth_callback",
  },
  CONTACT_DETAILS_UPDATE: {
    path: "/updateContactDetails",
  },
};

export const settingComponentIdentifierEnum = {
  USER_DETAILS: "USER_DETAILS",
  BUSINESS_DETAILS: "BUSINESS_DETAILS",
  USER_ACCESS_MANAGEMENT: "USER_ACCESS_MANAGEMENT",
  ADVERTISER: "ADVERTISER",
  BRAND: "BRAND",
};

export const settingsTabsEnum = {
  USER_DETAILS: "USER_DETAILS",
  BUSINESS_DETAILS: "BUSINESS_DETAILS",
  USER_ACCESS_MANAGEMENT: "USER_ACCESS_MANAGEMENT",
  ADVERTISER: "ADVERTISER",
  BRAND: "BRAND",
};
