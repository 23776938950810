import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { MccCreation } from "@onlinesales-ai/ott-common-v2";

import {
  fetchEntityMetaData as fetchEntityMetaDataAction,
  postMccData as postMccDataAction,
} from "src/store/application";

const MccCreationWrapper = ({ ...props }) => {
  return <MccCreation {...props} />;
};

const mapStateToProps = (state) => {
  const { agencyId } = state.DomainConfig || {};
  const { entityInfo, entityMetadata } = state.Application || {};

  return {
    agencyId,
    entityInfo,
    entityMetadata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchEntityMetaData: fetchEntityMetaDataAction,
      postMccData: postMccDataAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MccCreationWrapper);
