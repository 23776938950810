import { checkIfUserIsInternal, checkIfUserIsPartnerInternal } from "@onlinesales-ai/util-methods-v2";
import { entityTypeEnum, businessTypeEnum, getEntityInfo, getEntityMetadata } from "@onlinesales-ai/ott-common-v2";

import {
  applyDomainConfigOverrides,
} from "@onlinesales-ai/domain-config-v2";

import Types from "./types";

export const setDomainConfig = (config, { entityInfo: pEntityInfo } = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      userInfo,
      entityInfo: allEntityInfo,
      entityMetadata: allEntityMetadata,
      selectedEntityId,
      selectedEntityType,
      entityBillingInfo,
    } = state.Application || {};
    const entityInfo = pEntityInfo || getEntityInfo(allEntityInfo, `${selectedEntityType}_${selectedEntityId}`);
    const entityMetadata = getEntityMetadata(allEntityMetadata, `${selectedEntityType}_${selectedEntityId}`);

    const isInternalUser = checkIfUserIsInternal(userInfo, config);
    const isPartnerInternalUser = checkIfUserIsPartnerInternal(userInfo, config);

    const domainConfigOverrides = [];
    const keysToBeProceed = [];

    if (config.internalUserOverride && isInternalUser) {
      domainConfigOverrides.push(config.internalUserOverride);
      keysToBeProceed.push(config.internalUserOverride);
    }

    if (config.partnerInternalUserOverride && isPartnerInternalUser) {
      domainConfigOverrides.push(config.partnerInternalUserOverride);
      keysToBeProceed.push(config.partnerInternalUserOverride);
    }

    if (config.agencyOverride && entityInfo.entityType === entityTypeEnum.MCC &&
        entityMetadata.businessDefinition === businessTypeEnum.AGENCY) {
      domainConfigOverrides.push(config.agencyOverride);
      keysToBeProceed.push(config.agencyOverride);
    }

    if (config.advertiserOverride && entityInfo.entityType === entityTypeEnum.MCC &&
      entityMetadata.businessDefinition === businessTypeEnum.ADVERTISER) {
      if (entityMetadata.isBillingEntity === "true") {
        domainConfigOverrides.push(config.advertiserWithBillingOverride);
        keysToBeProceed.push(config.advertiserWithBillingOverride);
      } else {
        domainConfigOverrides.push(config.advertiserOverride);
        keysToBeProceed.push(config.advertiserOverride);
      }
    }

    // Support for dynamicOverrides, Change this position to change the priority
    domainConfigOverrides.push("dynamicOverrides");
    config = applyDomainConfigOverrides(config, domainConfigOverrides, keysToBeProceed, {
      State: state,
      entityMetadata,
      entityInfo,
      entityBillingInfo,
    });

    dispatch({
      type: Types.SET_DOMAIN_CONFIG,
      config,
    });
  };
};