import _uniq from "lodash/uniq";

import { ADSET_STATUS } from "@onlinesales-ai/ott-common-v2";

import Types from "./types";
import OnBoardingTypes from "../onBoarding/types";
import ApplicationTypes from "../application/types";

const initialState = {
  campaign: {},
  adSet: {},
  campaignIsFetchInProgress: true,
  campaignError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ApplicationTypes.APP_SET_ENTITY_ID:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case OnBoardingTypes.APP_LOG_OUT:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_CAMPAIGNS_DATA_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          campaignIsFetchInProgress: action.isInProgress,
        };
      }
      break;
    case Types.SET_CAMPAIGNS_DATA_FETCH_ERROR:
      {
        state = {
          ...state,
          campaignError: action.error,
        };
      }
      break;
    case Types.SET_CAMPAIGNS_DATA:
      {
        state = {
          ...state,
          campaign: {
            ...state.campaign,
            ...action.campaignsData,
          },
        };
      }
      break;
    case Types.SET_AD_SET_DATA:
      {
        if (action?.entity) {
          state = {
            ...state,
            adSet: {
              ...(state?.adSet || {}),
              [action.id]: {
                ...(state.adSet?.[action.id] || {}),
                [action.entity]: action.replaceData
                  ? action.data
                  : {
                      ...(state.adSet?.[action.id]?.[action.entity] || {}),
                      ...action.data,
                    },
              },
            },
          };
        } else {
          state = {
            ...state,
            adSet: {
              ...(state?.adSet || {}),
              [action.id]: action.replaceData
                ? action.data
                : {
                    ...(state.adSet[action.id] || {}),
                    ...action.data,
                  },
            },
          };
        }

        state = {
          ...state,
          campaign: {
            ...state.campaign,
            [action.campaignId]: {
              ...state.campaign[action.campaignId],
              adSetIds: _uniq([
                ...(state.campaign[action.campaignId]?.adSetIds || []),
                action.id,
              ]).filter((id) => state.adSet?.[id]?.data?.status !== ADSET_STATUS.DISABLED),
            },
          },
        };
      }
      break;
    case Types.UPDATE_CAMPAIGNS_DATA:
      {
        if (action?.entity) {
          state = {
            ...state,
            campaign: {
              ...(state?.campaign || {}),
              [action.id]: {
                ...(state.campaign?.[action.id] || {}),
                [action.entity]: {
                  ...(state.campaign?.[action.id]?.[action.entity] || {}),
                  ...action.data,
                },
              },
            },
          };
        } else {
          state = {
            ...state,
            campaign: {
              ...(state?.campaign || {}),
              [action.id]: {
                ...(state.campaign[action.id] || {}),
                ...action.data,
              },
            },
          };
        }
      }
      break;
    case Types.SET_AD_SET_LIST:
      {
        state = {
          ...state,
          adSet: {
            ...(state?.adSet || {}),
            ...action?.adSetData,
          },
          campaign: {
            ...state.campaign,
            [action.campaignId]: {
              ...(state?.campaign?.[action.campaignId] || {}),
              adSetIds: _uniq([
                ...(state?.campaign?.[action.campaignId]?.adSetIds || []),
                ...Object.keys(action?.adSetData).map(Number),
              ]).filter((id) => state.adSet?.[id]?.data?.status !== ADSET_STATUS.DISABLED),
            },
          },
        };
      }
      break;
    case Types.SET_AD_SET_CREATIVE_DATA:
      {
        let newAds = [...(state.adSet?.[action.adSetId]?.ads || [])];

        if (newAds.find((ad) => ad.id === action.data.id)) {
          newAds = newAds.map((ad) => {
            if (ad.id === action.data.id) {
              return action.data;
            }
            return ad;
          });
        } else {
          newAds.unshift(action.data);
        }

        state = {
          ...state,
          adSet: {
            ...(state?.adSet || {}),
            [action.adSetId]: {
              ...(state.adSet?.[action.adSetId] || {}),
              ads: newAds.filter((ad) => ad.status !== "DISABLED"),
            },
          },
        };
      }
      break;
    case Types.SET_AD_SET_CREATIVE_LIST:
      {
        state = {
          ...state,
          adSet: {
            ...(state?.adSet || {}),
            [action.adSetId]: {
              ...(state.adSet?.[action.adSetId] || {}),
              ads: action.ads,
            },
          },
        };
      }
      break;
    case Types.SET_BUNDLE_AD_SET_DATA:
      {
        state = {
          ...state,
          adSet: {
            ...(state?.adSet || {}),
            [action?.impactOrderId]: {
              ...(state.adSet?.[action?.impactOrderId] || {}),
              ...(action?.impactData || {}),
            },
            [action?.audienceOrderId]: {
              ...(state.adSet?.[action?.audienceOrderId] || {}),
              ...(action?.audienceData || {}),
            },
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  CampaignStore: reducer,
};
