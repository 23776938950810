import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _sortBy from "lodash/sortBy";

import { Header } from "@onlinesales-ai/header-v2";
import { getDefaultRoute, convertToArray } from "@onlinesales-ai/util-methods-v2";

import { redirectUrl } from "src/store/application";

import { routes } from "src/utilities/constants";

import MultiLevelDropdown from "src/components/common/multiLevelDropdown";

import "./index.less";

const AppHeader = ({
  isInternalUser,
  isMobile,
  showNavigation,
  navLinks,
  redirect,
  location,
  shouldBeRendered,
  brandingLogo,
  mobileBrandingLogo,
  containerClass,
  selectedEntityId,
  selectedEntityType,
  brandingClass,
  multiLevelDropdownProps,
}) => {
  if (!shouldBeRendered) {
    return null;
  }

  let navConfig = navLinks[location.pathname] || navLinks.common;

  if (!Array.isArray(navConfig)) {
    navConfig = convertToArray(
      {
        linksConfig: Object.values(navLinks.common),
      },
      {
        arrayConfigKey: "linksConfig",
        arrayItemOrderKey: "order",
        arrayItemShowKey: "isShow",
      },
    );
  }

  const renderCustomEntityDropdown = () => {
    if (
      !selectedEntityId ||
      !selectedEntityType ||
      location.pathname.includes(routes.REPORTS?.path)
    ) {
      return null;
    }

    return <MultiLevelDropdown {...multiLevelDropdownProps} showEntityType isDarkMode />;
  };

  return (
    <Header
      homePageUrl={getDefaultRoute() || routes.DASHBOARD?.path}
      isMobile={isMobile}
      isInternalUser={isInternalUser}
      showClientList={false}
      showNavigation={showNavigation}
      navLinks={navConfig}
      redirectUrl={redirect}
      brandingLogo={brandingLogo}
      mobileBrandingLogo={mobileBrandingLogo}
      containerClass={containerClass}
      customComponents={null}
      customEntityRender={renderCustomEntityDropdown}
      showHeaderWithText
      brandingClass={brandingClass}
    />
  );
};

Header.defaultConfig = {
  showNavigation: true,
  multiLevelDropdownProps: {},
};

const mapStateToProps = (state) => {
  const { selectedEntityId, selectedEntityType, isInternalUser, isMobile } =
    state.Application || {};

  const { header } = state.DomainConfig || {};

  return {
    location: state.router.location,
    isMobile,
    isInternalUser,
    navLinks: [],
    selectedEntityId,
    selectedEntityType,
    ...header,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirect: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
