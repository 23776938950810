import Types from "./types";

const initialState = {
  widgetData: {},
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_REPORT_WIDGET_DATA:
      {
        const prepareWidgetData = {
          ...(state?.widgetData || {}),
        };
        Object.keys(action?.data || {}).forEach((widget) => {
          prepareWidgetData[widget] = {
            ...(prepareWidgetData?.[widget] || {}),
            ...(action?.data?.[widget] || {}),
          };
        });
        state = {
          ...state,
          widgetData: prepareWidgetData,
        };
      }
      break;
    case Types.SET_REPORT_WIDGET_FETCH_STATE:
      {
        const prepareWidgetData = {
          ...(state?.widgetData || {}),
        };
        (action.widgets || []).forEach((widget) => {
          prepareWidgetData[widget] = {
            ...(prepareWidgetData?.[widget] || {}),
            fetchState: action.fetchState,
          };
        });
        state = {
          ...state,
          widgetData: prepareWidgetData,
        };
      }
      break;
    case Types.SET_WIDGET_LIST:
      {
        state = {
          ...state,
          widgetList: {
            ...(state?.widgetList || {}),
            ...(action?.data || {}),
          },
        };
      }
      break;
    case Types.SET_DASHBOARD_LIST:
      {
        state = {
          ...state,
          sportsDashboardList: [...(action?.data || [])],
        };
      }
      break;
    // case Types.SET_DASHBOARD_LIST_FETCH_STATE:
    //   {
    //     state = {
    //       ...state,
    //       isLoading: action?.isLoading,
    //     };
    //   }
    //   break;
    default:
      break;
  }

  return state;
};

export default {
  SportsDashboard: reducer,
};
