import React, { lazy } from "react";

import Login from "src/pages/login";
import { oAuthCallBack } from "@onlinesales-ai/util-methods-v2";
import { Downtime } from "@onlinesales-ai/downtime-v2";
import { GoogleLoginCallback } from "@onlinesales-ai/login-v2";
import { routes } from "src/utilities/constants";

export const protectedRoutes = {
  [routes.HOME.path]: {
    component: lazy(() => import("src/pages/home")),
  },
  [routes.SPORTS_SUMMARY.path]: {
    component: lazy(() => import("src/pages/sportsDashboard/iframeWrapper")),
  },
  // [routes.SPORTS_TOURNAMENT.path]: {
  //   component: lazy(() => import("src/pages/sportsDashboard/tournamentDashboard")),
  // },
  // [routes.SPORTS_TARGETING.path]: {
  //   component: lazy(() => import("src/pages/sportsDashboard/targetingDashboard")),
  // },
  // [routes.SPORTS_LEAD_GENERATION.path]: {
  //   component: lazy(() => import("src/pages/sportsDashboard/leadGenerationDashboard")),
  // },
  [routes.CAMPAIGNS.path]: {
    component: lazy(() => import("src/pages/campaigns/campaignPage")),
  },
  [routes.REPORTS.path]: {
    component: lazy(() => import("src/pages/scheduleReport")),
  },
  [routes.BILLING.path]: {
    component: lazy(() => import("src/pages/commingSoon")),
  },
  [routes.ONBOARDING.path]: {
    component: lazy(() => import("src/pages/onboarding")),
  },
  [routes.SETTINGS.path]: {
    component: lazy(() => import("src/pages/settings")),
  },
  [routes.CONTACT_DETAILS_UPDATE.path]: {
    component: lazy(() => import("src/pages/contactDetailsUpdate")),
  },
};

export const openRoutes = {
  [routes.LOGIN.path]: {
    component: Login,
  },
  [routes.FORGOT_PASSWORD.path]: {
    component: lazy(() => import("src/pages/forgotPassword")),
  },
  [routes.CREATE_PASSWORD.path]: {
    component: lazy(() => import("src/pages/createPassword")),
  },
  [routes.SIGN_UP.path]: {
    component: lazy(() => import("src/pages/signUp")),
  },
  [routes.SIGN_UP_CALLBACK.path]: {
    component: lazy(() => import("src/pages/signUpCallback")),
  },
  [routes.DOWNTIME.path]: {
    component: Downtime,
  },
  [routes.PAYU_PAYMENT_WINDOW_CALLBACK.path]: {
    component: lazy(() =>
      import("@onlinesales-ai/billing-v2/src/paymentCallback/payu/paymentWindow"),
    ),
  },
  [routes.GOOGLE_CALLBACK.path]: {
    component: GoogleLoginCallback,
  },
  [routes.OAUTH_CALLBACK.path]: {
    component: oAuthCallBack,
  },
};
