export default {
  SET_CAMPAIGNS_DATA_FETCH_IN_PROGRESS: "SET_CAMPAIGNS_DATA_FETCH_IN_PROGRESS",
  SET_CAMPAIGNS_DATA_FETCH_ERROR: "SET_CAMPAIGNS_DATA_FETCH_ERROR",
  SET_CAMPAIGNS_DATA: "SET_CAMPAIGNS_DATA",
  SET_AD_SET_DATA: "SET_AD_SET_DATA",
  UPDATE_CAMPAIGNS_DATA: "UPDATE_CAMPAIGNS_DATA",
  SET_AD_SET_LIST: "SET_AD_SET_LIST",
  SET_AD_SET_CREATIVE_DATA: "SET_AD_SET_CREATIVE_DATA",
  SET_AD_SET_CREATIVE_LIST: "SET_AD_SET_CREATIVE_LIST",
  SET_BUNDLE_AD_SET_DATA: "SET_BUNDLE_AD_SET_DATA",
  SET_BUNDLE_AD_SET_CREATIVE_LIST: "SET_BUNDLE_AD_SET_CREATIVE_LIST",
};
