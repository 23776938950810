import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RouteBasedModal } from "@onlinesales-ai/app-v2";

import { redirectUrl } from "src/store/application";

const COMPONENT_MAPPING = {};

const RouteBasedModalWrapper = ({ redirectUrlTo = () => {} }) => {
  return <RouteBasedModal redirectUrl={redirectUrlTo} componentMapping={COMPONENT_MAPPING} />;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(RouteBasedModalWrapper);
