import onBoardingReducer from "@onlinesales-ai/app-v2/onBoarding/reducer";
import { mergeObject } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";
import types from "../application/types";

const initialState = {
  data: null,
  isLoading: true,
};

const reducer = (state = initialState, action) => {
  let hasChanged = true;
  switch (action.type) {
    case Types.APP_LOG_OUT: {
      state = {
        ...initialState,
      };
    }
      break;
    case Types.SET_ONBOARDING_DETAILS:
      {
        state = {
          ...state,
          data: action.data,
        };
      }
      break;
    case Types.SET_ONBOARDING_FETCH_STATE:
      {
        state = {
          ...state,
          isLoading: action.isLoading,
        };
      }
      break;
    default:
      hasChanged = false;
      break;
  }

  if (!hasChanged) {
    return onBoardingReducer(state, action, initialState);
  }
  return state;
};

export default {
  OnBoarding: reducer,
};
