import _isEmpty from "lodash/isEmpty";

import { InceptionService } from "@onlinesales-ai/services-v2";
import { getEntityInfo, SPORT_DASHBOARD_MAPPING } from "@onlinesales-ai/ott-common-v2";

import types from "./types";

// export const setSportDashboardListFetchInProgress = (isLoading) => ({
//   type: types.SET_DASHBOARD_LIST_FETCH_STATE,
//   isLoading,
// });

export const fetchSportsDashboardList = (updateDomainConfig) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { selectedEntityId, selectedEntityType, entityInfo, agencyId } = state.Application;

    const selectedEntityInfo = getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);

    if (selectedEntityInfo?.metadata?.businessDefinition === "ADVERTISER") {
      try {
        // dispatch(setSportDashboardListFetchInProgress(true));

        const payload = {
          entityId: selectedEntityId,
          entityType: selectedEntityType,
          agencyId,
        };

        const response = await InceptionService.fetchWidgetList(payload);

        dispatch({
          type: types.SET_DASHBOARD_LIST,
          data: Object.keys(response?.widgetConfiguration || {}).filter(
            (widget) => !_isEmpty(response?.widgetConfiguration?.[widget] || []),
          ),
        });
      } catch (e) {
        dispatch({
          type: types.SET_DASHBOARD_LIST,
          data: [SPORT_DASHBOARD_MAPPING.SUMMARY_DASHBOARD],
        });
      } finally {
        // dispatch(setSportDashboardListFetchInProgress(false));
        updateDomainConfig();
      }
    } else {
      dispatch({
        type: types.SET_DASHBOARD_LIST,
        data: [SPORT_DASHBOARD_MAPPING.SUMMARY_DASHBOARD],
      });
      // dispatch(setSportDashboardListFetchInProgress(false));
    }
  };
};

export const setReportData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_REPORT_WIDGET_DATA,
      data,
    });
  };
};

export const setWidgetFetchState = (fetchState, widgets) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_REPORT_WIDGET_FETCH_STATE,
      fetchState,
      widgets,
    });
  };
};

export const setWidgetList = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_WIDGET_LIST,
      data,
    });
  };
};

// export const setOnboardingDataFetchInProgress = (isLoading) => ({
//   type: types.SET_ONBOARDING_FETCH_STATE,
//   isLoading,
// });

export const fetchWidgetData = (payloadToSend, widgets) => {
  return async (dispatch) => {
    try {
      const payload = payloadToSend;
      dispatch(setWidgetFetchState({
        isLoading: true,
        fetchError: false,
      }, widgets));

      const response = await InceptionService.fetchWidgetReport(payload, "HOTSTAR_SPORTS_DASHBOARD", { abortId: widgets?.join("_") || "HOTSTAR_SPORTS_DASHBOARD_FETCH_CALL" });
      dispatch(setWidgetFetchState({
        isLoading: false,
        fetchError: false,
      }, widgets));

      dispatch(setReportData(response?.widgets));
    } catch (e) {
      dispatch(setWidgetFetchState({
        isLoading: false,
        fetchError: e.errorMsg,
      }, widgets));
    }
  };
};

export const fetchWidgetList = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedEntityId, selectedEntityType, agencyId } = state.Application;

    const payload = {
      entityId: selectedEntityId,
      entityType: selectedEntityType,
      agencyId,
      ...config,
    };

    const response = await InceptionService.fetchWidgetList(payload, "HOTSTAR_SPORTS_DASHBOARD", { abortId: "HOTSTAR_SPORTS_DASHBOARD_WIDGET_LIST_FETCH_CALL" });
    dispatch(setWidgetList(response?.widgetConfiguration || {}));
    return response;
  };
};
