import _uniq from "lodash/uniq";

import { Hades, ReviewService, BaseClient, InceptionService } from "@onlinesales-ai/services-v2";
import { getRedirectUrl } from "@onlinesales-ai/app-v2/application/actions";
import { getCookie, customMergeOS, SortAlphabeticalByKey } from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import {
  getEntityInfo,
  getEntityMetadata,
  parseProcessedEntityId,
  processEntityMetadataForAPI,
  generateProcessedEntityId,
  processEntityMetadataFromAPI,
  entityTypeEnum,
} from "@onlinesales-ai/ott-common-v2";
import { COOKIES } from "@onlinesales-ai/constants-v2";

import { routes } from "src/utilities/constants";

import Types from "./types";
import { setOnboardingData, setOnboardingDataFetchInProgress } from "../onBoarding/actions";

export { fetchUser } from "@onlinesales-ai/app-v2/application/actions";

export const redirectUrl = getRedirectUrl(routes);

const ENTITIES_FETCH_LIMIT = 1000;

export const updateEntityInfo = (entityInfoToUpdate) => ({
  type: Types.SET_APP_UPDATE_ENTITY_INFO,
  entityInfoToUpdate,
});

export const updateEntityBillingInfo = (entityBillingInfo) => ({
  type: Types.SET_APP_UPDATE_ENTITY_BILLING_INFO,
  entityBillingInfo,
});

const saveMccClientData = (mccData) => ({
  type: Types.INSERT_UPDATE_MCC_CLIENT,
  mccData,
});

const updateEntitiesFetchState = (entityId, entitiesFetchStateToUpdate) => ({
  type: Types.SET_APP_UPDATE_ENTITY_FETCH_STATE,
  entityId,
  entitiesFetchStateToUpdate,
});

const setEntitiesFetchInProgress = (entitiesFetchInProgress) => ({
  type: Types.SET_APP_ENTITIES_FETCH_IN_PROGRESS,
  entitiesFetchInProgress,
});

export const upsertEntityMetadata = (entityId, entityMetadata) => ({
  type: Types.UPSERT_ENTITY_METADATA,
  entityId,
  entityMetadata,
});

export const updateEntityMetadataList = (entityMetadata) => ({
  type: Types.UPSERT_ENTITY_METADATA_LIST,
  entityMetadata,
});

export const changeEntityId = (originalEntityId, entityType) => {
  return async (dispatch, getState) => {
    if (!originalEntityId || !entityType) {
      return;
    }

    const state = getState();
    const { entityInfo: allEntityInfo = {}, entityMetadata: allEntityMetadata = {} } = state.Application;

    if (
      originalEntityId !== state.Application.selectedEntityId ||
      entityType !== state.Application.selectedEntityType
    ) {
      BaseClient.abortByMethod("GET", { excludeApplication: ["application"] });
      BaseClient.abortByMethod("POST");
    }

    PlatformEventManager.emit("UPDATE_DOMAIN_CONFIG", {
      entityInfoOverride: {
        ...getEntityInfo(allEntityInfo, `${entityType}_${originalEntityId}`),
        ...getEntityMetadata(allEntityMetadata, `${entityType}_${originalEntityId}`),
      },
    });

    dispatch({
      type: Types.APP_SET_ENTITY_ID,
      entityId: originalEntityId,
      entityType,
    });
  };
};

export const parseEntityData = (entitiesData, parentId) => {
  const entityIds = [];
  let entityInfoToUpdate = {
    clients: {},
    mccs: {},
  };
  let entityMedataToUpdate = {
    clients: {},
    mccs: {},
  };

  entitiesData.forEach((entityObj) => {
    if (!entityObj.entityId || !entityObj.entityType) {
      return;
    }

    if (parentId && !entityObj.parentMCCId) {
      entityObj.parentMCCId = parentId;
    }

    entityObj.entityName = entityObj.entityAlias || entityObj.entityName;
    delete entityObj.entityAlias;

    entityObj.originalEntityId = entityObj.entityId;
    entityObj.entityId = `${entityObj.entityType}_${entityObj.entityId}`;

    if (entityObj.hierarchy?.length > 0) {
      entityObj.hierarchy.forEach((hierarchyObj) => {
        hierarchyObj.entityName = hierarchyObj.entityAlias || hierarchyObj.entityName;
        delete hierarchyObj.entityAlias;
      });

      const {
        entityInfoToUpdate: hierarchyEntityInfo,
        entityMedataToUpdate: hierarchyEntityMetadata,
      } = processHierarchyData(entityObj.hierarchy);

      entityInfoToUpdate = customMergeOS({}, entityInfoToUpdate, hierarchyEntityInfo);
      entityMedataToUpdate = customMergeOS({}, entityMedataToUpdate, hierarchyEntityMetadata);

      delete entityObj.hierarchy;
    }

    entityIds.push(entityObj.entityId);
    if (entityObj.entityType === entityTypeEnum.MCC) {
      entityInfoToUpdate.mccs[entityObj.entityId] = {
        ...(entityInfoToUpdate?.mccs?.[entityObj.entityId] || {}),
        ...entityObj,
      };
      if (entityObj?.metadata) {
        entityMedataToUpdate.mccs[entityObj.entityId] = processEntityMetadataFromAPI(entityObj.metadata);
      }
    } else if (entityObj.entityType === entityTypeEnum.CLIENT) {
      entityInfoToUpdate.clients[entityObj.entityId] = {
        ...(entityInfoToUpdate?.clients?.[entityObj.entityId] || {}),
        ...entityObj,
      };
      if (entityObj?.metadata) {
        entityMedataToUpdate.clients[entityObj.entityId] = processEntityMetadataFromAPI(entityObj.metadata);
      }
    }

    delete entityObj.entityMetadata;
    delete entityObj.metadata;
  });

  return {
    entityIds,
    entityInfoToUpdate,
    entityMedataToUpdate,
  };
};

const processHierarchyData = (data) => {
  const entitiesData = data || [];

  let currentParentId = null;
  entitiesData.forEach((entityObj) => {
    entityObj.parentMCCId = currentParentId;
    currentParentId = entityObj.entityId;
  });

  return parseEntityData(entitiesData);
};

export const fetchEntityHierarchy = (entityId, entityType) => {
  return async (dispatch, getState) => {
    if (!entityId) {
      return;
    }

    const state = getState();
    const { agencyId } = state.Application;

    const request = {
      agencyId,
      entityId,
      entityType,
      selectors: [
        "entityId",
        "entityType",
        "hierarchy",
      ],
      offset: 0,
      limit: 500,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await Hades.getEntityDataBySelectors(request, "application");
        const { entityIds, entityInfoToUpdate, entityMedataToUpdate } = processHierarchyData(response?.entity?.hierarchy);
        dispatch(updateEntityInfo(entityInfoToUpdate));
        dispatch(
          updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
        );
        resolve(entityIds);
      } catch (err) {
        if (err.isAborted) {
          resolve([]);
          return;
        }

        const errorMsg = err?.data?.exception?.error?.message;
        if (
          errorMsg === "UNAUTHORIZED" ||
          errorMsg === "application can not be null/empty" ||
          errorMsg === "Hades Authorization failed" ||
          errorMsg === "User Authentication failed"
        ) {
          dispatch(redirectUrl(routes?.LOGIN?.path));
        }

        reject(err);
      }
    });
  };
};

export const fetchEntitiesData = (mccId, { componentCallbackForEntityIds, limit, offset } = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { agencyId } = state.DomainConfig;
    const { entitiesFetchState = {}, selectedEntityId } = state.Application;
    const processedEntityId = generateProcessedEntityId(
      mccId ? entityTypeEnum.MCC : entityTypeEnum.AGENCY,
      mccId || agencyId,
    );
    const parentEntityFetchState = entitiesFetchState?.[processedEntityId] || {};

    if (parentEntityFetchState.isFetchInProgress) {
      return;
    }

    const request = {
      agencyId,
      mccId,
      selectors: [
        "entityId",
        "entityName",
        "entityAlias",
        "entityType",
        "statusType",
        "depth",
        "metadata.approvalStatus",
        "metadata.businessDefinition",
        "metadata.customLabel1",
      ],
      offset: typeof offset === "number" ? offset : parentEntityFetchState.offset || 0,
      limit: limit || ENTITIES_FETCH_LIMIT,
    };

    try {
      if (!componentCallbackForEntityIds) {
        dispatch(
          updateEntitiesFetchState(processedEntityId, {
            ...parentEntityFetchState,
            fetchErrorMsg: null,
            isFetchInProgress: true,
          }),
        );
      }

      const response = await Hades.getEntities(request, "application", {
        abortId: `get_entity_list_${processedEntityId}`,
      });

      const entitiesData = SortAlphabeticalByKey(response?.data, "entityAlias") || [];
      const { entityIds, entityInfoToUpdate, entityMedataToUpdate } = parseEntityData(entitiesData, mccId);

      const entitiesFetchStateToUpdate = {
        ...parentEntityFetchState,
        isAllFetched: entitiesData.length !== ENTITIES_FETCH_LIMIT,
        offset:
          (parentEntityFetchState?.offset || 0) +
          Math.min(entitiesData.length, ENTITIES_FETCH_LIMIT),
        children: _uniq([...(parentEntityFetchState?.children || []), ...entityIds]),
        isFetchInProgress: false,
      };

      dispatch(updateEntityInfo(entityInfoToUpdate));
      dispatch(
        updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
      );
      if (componentCallbackForEntityIds) {
        componentCallbackForEntityIds(entityIds);
      } else {
        dispatch(updateEntitiesFetchState(processedEntityId, entitiesFetchStateToUpdate));
      }

      // When loading the entities for the agency level, check for selected entity
      if (!mccId && !parentEntityFetchState?.offset && !selectedEntityId) {
        let isEntityIdFound = false;

        try {
          const cookieEntityIdStr = getCookie(COOKIES.SELECTED_ENTITY_ID, false);
          const { entityType: cookieEntityType, entityId: cookieEntityId } =
            parseProcessedEntityId(cookieEntityIdStr);
          if (cookieEntityId && cookieEntityType) {
            if (!entityIds.includes(cookieEntityIdStr)) {
              const respEntityIds = await dispatch(fetchEntityHierarchy(cookieEntityId, cookieEntityType));
              isEntityIdFound = respEntityIds.length > 0;
            } else {
              isEntityIdFound = true;
            }

            if (isEntityIdFound) {
              dispatch(changeEntityId(cookieEntityId, cookieEntityType));
            }
          }
        } catch (e) {}

        if (!isEntityIdFound && entityIds.length > 0) {
          const { entityType, entityId: originalEntityId } = parseProcessedEntityId(entityIds[0]);
          dispatch(changeEntityId(originalEntityId, entityType));
        }

        if (entityIds.length === 0) {
          dispatch(setOnboardingDataFetchInProgress(false));
          dispatch(redirectUrl(routes?.ONBOARDING?.path));
        }

        dispatch(setEntitiesFetchInProgress(false));
      }
    } catch (err) {
      if (err.isAborted) {
        return;
      }
      const errorMsg = err?.errorMsg;
      if (
        errorMsg === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "Hades Authorization failed" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes?.LOGIN?.path));
      } else if (!mccId && !parentEntityFetchState?.offset) {
        dispatch(redirectUrl(routes?.DOWNTIME?.path));
      } else {
        dispatch(
          updateEntitiesFetchState(processedEntityId, {
            ...parentEntityFetchState,
            fetchErrorMsg: errorMsg,
          }),
        );
      }
      dispatch(setEntitiesFetchInProgress(false));
    }
  };
};

export const onFilterEntityBySearchText = (payload, options) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Hades.getEntitiesBySearch(payload, "application", options);
        const entitiesData = response?.data || [];

        entitiesData.forEach((entityObj) => {
          if (entityObj?.hierarchy?.length >= 2) {
            const parentEntityInfo = entityObj.hierarchy[entityObj.hierarchy.length - 2];
            entityObj.parentMCCId =
              parentEntityInfo.entityType === entityTypeEnum.MCC ? parentEntityInfo.entityId : null;
          }
          entityObj.sortingDataKey = entityObj?.hierarchy?.map((i) => i?.entityAlias).join(" > ");
        });

        const sortedEntitiesData = SortAlphabeticalByKey(entitiesData, "sortingDataKey");

        const { entityIds, entityInfoToUpdate, entityMedataToUpdate } = parseEntityData(sortedEntitiesData);
        dispatch(updateEntityInfo(entityInfoToUpdate));
        dispatch(
          updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
        );

        resolve(entityIds);
      } catch (e) {
        // reject(e);
        if (!e.isAborted) {
          resolve([]);
        }
      }
    });
  };
};

export const postMccData = (config, isOnboardingData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { entityInfo: entityInfoInStore, agencyId } = state.Application || {};

    const storeEntityInfo = getEntityInfo(entityInfoInStore, `${entityTypeEnum.MCC}_${config?.entity?.entityId}`);
    const parentMCCData = getEntityInfo(
      entityInfoInStore,
      `${entityTypeEnum.MCC}_${config?.parentId}`,
    );

    const payload = {
      ...config,
      entity: {
        ...(config.entity || {}),
        metadata: processEntityMetadataForAPI({
          ...(config.entity.metadata || {}),
        }),
      },
    };

    if (!payload.entity?.entityId) {
      if (payload.entity?.entityName) {
        payload.entity.entityAlias = payload.entity.entityName;
        payload.entity.entityName = `${payload.entity.entityName}_${new Date().valueOf()}`;
      }
    } else if (payload.entity?.entityName) {
      payload.entity.entityAlias = payload.entity.entityName;
      delete payload.entity?.entityName;
    }

    const request = {
      entityType: entityTypeEnum.MCC,
      agencyId,
      workflow: "HOTSTAR_MCC_APPROVAL_FLOW",
      entitySvcRequestPayload: { ...payload },
    };

    const response = await ReviewService.postEntityData(request, "application");
    const parsedMetadata = processEntityMetadataFromAPI(response?.entity?.metadata || {});

    const fallbackMccId = payload?.parentEntityType !== "AGENCY" ? payload?.parentId : null;

    const entityInfo = {
      entityType: entityTypeEnum.MCC,
      entityId: `${entityTypeEnum.MCC}_${payload.entity.entityId || response?.entity?.entityId}`,
      parentMCCId: storeEntityInfo.parentMCCId || fallbackMccId,
      entityName: payload?.entity?.entityAlias || storeEntityInfo.entityName,
      depth: storeEntityInfo.depth || (parentMCCData?.depth || 0) + 1,
    };

    if (isOnboardingData) {
      dispatch(setOnboardingData(parsedMetadata?.onboardingData));
    }

    dispatch(saveMccClientData({ entityInfo, entityMetadata: parsedMetadata }));

    return {
      ...response,
      entity: {
        ...(response.entity || {}),
        metadata: parsedMetadata,
      },
    };
  };
};

export const postClientData = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { entityInfo: entityInfoInStore, agencyId } = state.Application || {};
    const storeEntityInfo = getEntityInfo(entityInfoInStore, `${entityTypeEnum.MCC}_${config?.entity?.entityId}`);
    const parentMCCData = getEntityInfo(
      entityInfoInStore,
      `${entityTypeEnum.MCC}_${config?.parentId}`,
    );

    const payload = {
      ...config,
      entity: {
        ...(config.entity || {}),
        metadata: processEntityMetadataForAPI({
          ...(config.entity.metadata || {}),
        }),
      },
    };

    if (!payload.entity?.entityId) {
      if (payload.entity?.entityName) {
        payload.entity.entityAlias = payload.entity.entityName;
        payload.entity.entityName = `${payload.entity.entityName}_${new Date().valueOf()}`;
      }
    } else if (payload.entity?.entityName) {
      payload.entity.entityAlias = payload.entity.entityName;
      delete payload.entity?.entityName;
    }

    const request = {
      entityType: entityTypeEnum.CLIENT,
      agencyId,
      workflow: "HOTSTAR_MCC_APPROVAL_FLOW",
      entitySvcRequestPayload: { ...payload },
    };

    const response = await ReviewService.postEntityData(request, "application");
    const parsedMetadata = processEntityMetadataFromAPI(response?.entity?.metadata || {});

    const fallbackMccId = payload?.parentEntityType !== "AGENCY" ? payload?.parentId : null;

    const entityInfo = {
      entityType: entityTypeEnum.CLIENT,
      entityId: `${entityTypeEnum.CLIENT}_${payload.entity.entityId || response?.entity?.entityId}`,
      parentMCCId: storeEntityInfo.parentMCCId || fallbackMccId,
      entityName: payload?.entity?.entityAlias || storeEntityInfo.entityName,
      depth: storeEntityInfo.depth || (parentMCCData?.depth || 0) + 1,
    };

    dispatch(saveMccClientData({ entityInfo, entityMetadata: parsedMetadata }));

    return {
      ...response,
      entity: {
        ...(response.entity || {}),
        metadata: parsedMetadata,
      },
    };
  };
};

export const fetchEntityMetaData = (config, extraEntityDetails = {}, abortId) => {
  return async (dispatch, getState) => {
    const payload = {
      ...config,
    };

    const data = await InceptionService.getEntityAndBillingData(payload, "application", { abortId });
    const response = {
      entity: data?.response?.entityData || {},
      entityBillingInfo: data?.response?.billingInfo || {},
    };

    const { metadata } = response.entity || {};

    const parsedMetadata = processEntityMetadataFromAPI(metadata);

    const { entityType, entityId } = payload;
    const entityInfo = {
      entityType,
      entityId: `${entityType}_${entityId}`,
      // @TODO - These fields are not available right now, fix if needed in future
      // parentMCCId: payload?.parentId || `${entityTypeEnum.MCC}_${agencyId}`,
      // depth: mccData?.depth || (parentMCCData?.depth ? (parentMCCData?.depth + 1) : 1),
      ...extraEntityDetails,
      entityBillingInfo: response.entityBillingInfo,
    };

    if (response?.entity?.entityAlias) {
      entityInfo.entityName = response?.entity?.entityAlias;
    }
    dispatch(saveMccClientData({ entityInfo, entityMetadata: parsedMetadata }));

    return response;
  };
};

export const fetchEntityChildren = (payload) => {
  return async (dispatch) => {
    const response = await Hades.getEntityChildren(payload, "settings", {
      abortId: `get_entity_children_${payload.entityId}`,
    });

    const { children } = response;

    const { entityInfoToUpdate, entityMedataToUpdate } = parseEntityData(
      children,
      payload.entityId,
    );

    dispatch(updateEntityInfo(entityInfoToUpdate));

    dispatch(
      updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
    );

    return response;
  };
};
