import React, { useEffect, useMemo, useState } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import { Dropdown } from "@onlinesales-ai/dropdown-v2";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";
import { Input } from "@onlinesales-ai/input-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { formatValuesInThousands } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const FrequencyCap = ({
  title,
  checkboxLabel,
  metricOptions,
  parentDataKey,
  impressionsDataKey,
  impressionMetricDataKey,
  defaultImpressionMetric,
  resetConfirmationModal,
  showConfirmationModal,
  labelDefinationKey,
  formValues,
  formErrors,
  isEditable,
  onChange,
  validations,
  onError,
  fallbackValue,
  titleGuidText,
  id,
  maxLength,
  formGroupInnerClassName,
  errorClassName,
  inputProps,
  dropdownProps,
  ...props
}) => {
  const impressions = useMemo(() => {
    return _get(formValues, impressionsDataKey);
  }, [formValues, impressionsDataKey]);

  const impressionsMetric = useMemo(() => {
    return _get(formValues, impressionMetricDataKey);
  }, [formValues, impressionMetricDataKey]);

  const [capChecked, setCapChecked] = useState(!!impressionsMetric);

  useMemo(() => {
    if (isEditable && defaultImpressionMetric && !impressionsMetric && capChecked) {
      onChange({
        [impressionMetricDataKey]: metricOptions.find((o) => o.value === defaultImpressionMetric)
          ?.value,
      });
    }
  }, [isEditable, capChecked]);

  const onMetricChange = (value) => {
    onChange({ [impressionMetricDataKey]: value });
  };

  const onImpressionChange = (value) => {
    onChange({ [impressionsDataKey]: value, [impressionMetricDataKey]: impressionsMetric });
  };

  const toggleCheckbox = () => {
    if (capChecked) {
      showConfirmationModal({
        isShow: true,
        title:
          "Are you sure you want to disable frequency capping? Your selected settings will be lost.",
        rightBtnText: "No",
        actionBtnText: "Yes",
        actionBtnCallback: () => {
          setCapChecked(false);
          onChange({ [parentDataKey]: undefined });
          onError({ [impressionsDataKey]: null });
          resetConfirmationModal();
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      // onChange({ [frequencyCapDataKey]: !capChecked });
      setCapChecked(true);
    }
  };

  const validate = () => {
    let errorMsg = null;

    const checkEmptyValue = (val) => {
      return (
        typeof val === "string" && _isEmpty(val)
      ) || (
        typeof val === "number" && Number.isNaN(val)
      ) || (
        typeof val === "undefined"
      );
    };

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (checkEmptyValue(impressions)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "minValue":
          {
            if (impressions < validations[i].value) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "maxValue":
          {
            if (impressions > validations[i].value) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }
    onError({ [impressionsDataKey]: errorMsg });
  };

  const renderNonEditText = () => {
    return (
      <div>
        <Text className="non-edit-mode-text">
          {formatValuesInThousands(impressions)} impressions per {impressionsMetric}
        </Text>
      </div>
    );
  };

  useEffect(() => {
    if (isEditable && capChecked) {
      validate();
    }
  }, [impressions, capChecked]);

  if (!isEditable && !impressions) {
    return (
      <FormWrapper title={title} labelColumns={12}>
        <span className="guide-text">{fallbackValue}</span>
      </FormWrapper>
    );
  }

  return (
    <div className="frequency-cap-wrapper">
      <FormWrapper
        title={title}
        // titleGuidText="(optional)"
        titleGuidText={titleGuidText}
        dataKey={impressionsDataKey}
        formValues={formValues}
        formErrors={formErrors}
        error={formErrors[impressionsDataKey]}
        hasError={props.showErrors && !!formErrors[impressionsDataKey]}
        labelColumns={12}
        labelDefinationKey={labelDefinationKey}
        formGroupInnerClassName={formGroupInnerClassName}
        errorClassName={errorClassName}
      >
        {!isEditable && impressions ? (
          renderNonEditText()
        ) : (
          <>
            <div className="cap-checkbox-wrapper mt10">
              <Checkbox
                id={id}
                checked={capChecked}
                label={checkboxLabel}
                onChange={toggleCheckbox}
              />
            </div>
            {capChecked && (
              <div className="cap-input-wrapper d-align-center">
                <Input type="number" value={impressions} onChange={onImpressionChange} getValAsNumber maxLength={maxLength} {...inputProps} />
                <Text className="mx-2">Impressions Per</Text>
                <Dropdown
                  type="select-dropdown"
                  options={metricOptions}
                  onChange={onMetricChange}
                  value={impressionsMetric}
                  {...dropdownProps}
                />
              </div>
            )}
          </>
        )}
      </FormWrapper>
    </div>
  );
};

FrequencyCap.defaultProps = {
  fallbackValue: "Not Set",
};

export default OSHOCWithUtilities(FrequencyCap);
