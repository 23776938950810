import React, { useEffect, useRef, useState } from "react";

import classnames from "classnames";

import { Button } from "@onlinesales-ai/button-v2";

import "./index.less";

const ShadowScroll = ({ children, verticalAlign, showScrollBar, containerClassName, size, showArrows, scrollAmount, ...props }) => {
  const wrapperRef = useRef();
  const [overflow, setOverflow] = useState(false);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const [shoTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  const showHideShadow = () => {
    if (verticalAlign) {
      const isDownScrolled =  Math.ceil(wrapperRef?.current?.scrollTop) + wrapperRef?.current?.offsetHeight >
      wrapperRef?.current?.scrollHeight;
      const difference = isDownScrolled
          ? (
              Math.ceil(wrapperRef?.current?.scrollTop) +
              wrapperRef?.current?.offsetHeight -
              wrapperRef?.current?.scrollHeight
            )
          : (
            wrapperRef?.current?.scrollHeight -
            (Math.ceil(wrapperRef?.current?.scrollTop) + wrapperRef?.current?.offsetHeight) 
          );
      if (wrapperRef?.current.scrollHeight > wrapperRef?.current.clientHeight) {
        setOverflow(true);
        if (wrapperRef?.current.scrollTop > 10) {
          setShowTopShadow(true);
        } else {
          setShowTopShadow(false);
        }
        if (difference > 1) {
          setShowBottomShadow(true);
        } else {
          setShowBottomShadow(false);
        }
      } else {
        setOverflow(false);
      }
    } else if (wrapperRef?.current.scrollWidth > wrapperRef?.current.clientWidth) {
      setOverflow(true);
      if (wrapperRef?.current.scrollLeft > 10) {
        setShowLeftShadow(true);
        if (
          wrapperRef?.current.scrollLeft + wrapperRef?.current.offsetWidth ===
          wrapperRef?.current.scrollWidth
        ) {
          setShowRightShadow(true);
        } else {
          setShowRightShadow(false);
        }
      } else {
        setShowLeftShadow(false);
      }
    } else {
      setOverflow(false);
    }
  };

  useEffect(() => {
    showHideShadow();
  }, [children]);

  const arrowButtonAction = (direction) => {
    const scrollOptions = {
      left: { behavior: "smooth", left: -scrollAmount },
      right: { behavior: "smooth", left: scrollAmount },
    };

    if (direction === "left") {
      wrapperRef.current.scrollBy(scrollOptions.left);
    } else if (direction === "right") {
      wrapperRef.current.scrollBy(scrollOptions.right);
    }
  };

  return (
    <div
      ref={wrapperRef}
      onScroll={showHideShadow}
      className={classnames("shadow-scroll-wrapper", containerClassName, size, {
        "hide-scroll-bar": !showScrollBar,
        "overflow": overflow,
      })}
      {...props}
    >
      {showLeftShadow && !verticalAlign && <div className="left-shadow" />}
      {showLeftShadow && !verticalAlign && showArrows && (
        <Button
          link
          onClick={() => arrowButtonAction("left")}
          icon="icon icon-angle-left d-center"
          className="shadow-icons left"
        />
      )}
      {shoTopShadow && verticalAlign && <div className="top-shadow" />}
      {children}
      {!showRightShadow && !verticalAlign && <div className="right-shadow" />}
      {!showRightShadow && !verticalAlign && showArrows && (
        <Button
          link
          onClick={() => arrowButtonAction("right")}
          icon="icon icon-angle-right d-center"
          className="shadow-icons right"
        />
      )}
      {showBottomShadow && verticalAlign && <div className="bottom-shadow" />}
    </div>
  );
};

ShadowScroll.defaultProps = {
  showScrollBar: false,
  scrollAmount: 200,
};

export default ShadowScroll;
