import React from "react";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";

import { inputValidationRegex, validateValue } from "@onlinesales-ai/util-methods-v2";
import InputField from "../inputField";

class InputText extends React.Component {
  constructor(props) {
    super(props);

    const { dataKey, formValues } = this.props;
    this.currentValue = _get(formValues, dataKey);

    this.state = {
      errorWarning: this.getFieldErrorWarning(this.currentValue),
      hasInputBlured: false,
      customErrorClass: null,
    };

    this.validate(this.currentValue);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { dataKey, formValues, validations } = this.props;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);
    const newErrorValue = _get(nextProps.formErrors, nextProps.dataKey);

    if (
      newValue !== _get(formValues, dataKey) ||
      (!newValue && newErrorValue !== this.getError()) ||
      !_isEqual(validations, nextProps.validations)
    ) {
      this.validate(newValue, () => {}, nextProps.validations);
      this.computeWarning(newValue);
    }
  };

  onChangeInput = (value) => {
    const { onChange, dataKey, resetUndefined, trimOnChange, type, getValAsFloat, getValAsNumber } =
      this.props;

    let valueToAdd = value;

    if (trimOnChange && typeof valueToAdd === "string") {
      valueToAdd = value.trim();
    }

    if (getValAsFloat && valueToAdd && type === "number") {
      valueToAdd = parseFloat(valueToAdd);
    }

    if (getValAsNumber && valueToAdd && type === "number") {
      valueToAdd = parseInt(valueToAdd, 10);
    }

    onChange({ [dataKey]: valueToAdd || (resetUndefined ? undefined : valueToAdd) });
  };

  getError = () => {
    const { dataKey, formErrors } = this.props;
    return _get(formErrors, dataKey);
  };

  getFieldErrorWarning = (value) => {
    const {
      errorWarning: pErrorWarning,
      enableDynamicField,
      dynamicFieldWraning,
      warningValidations,
      t,
    } = this.props;

    if (warningValidations?.length > 0) {
      const { isValid, errorMsg } = validateValue({ value, validations: warningValidations, t });
      if (!isValid) {
        return errorMsg;
      }
    }

    if (pErrorWarning) {
      return null;
    }

    if (enableDynamicField && inputValidationRegex.DYNAMIC_VERIABLE.test(value)) {
      return dynamicFieldWraning;
    }

    return null;
  };

  computeWarning = (value) => {
    const { errorWarning } = this.state;

    const warning = this.getFieldErrorWarning(value);

    if (warning) {
      this.setState({
        errorWarning: warning,
      });
    } else if (errorWarning) {
      this.setState({
        errorWarning: null,
      });
    }
  };

  validate = (value, callback, pValidations) => {
    const { onError, dataKey, validations = [], title, icon, formValues, t } = this.props;
    let errorMsg = null;

    const validationsToUse = pValidations || validations;

    const validationValue = validateValue({
      validations: validationsToUse,
      value,
      formValues,
      title,
      icon,
      t,
    });
    errorMsg = validationValue?.errorMsg;
    const customErrorClass = validationValue?.errorClassName;
    this.setState({
      customErrorClass,
    });

    onError({ [dataKey]: errorMsg }, callback);
    return !errorMsg;
  };

  onValidate = (callback) => {
    return this.validate(_get(this.props.formValues, this.props.dataKey), callback);
  };

  onBlur = () => {
    if (!this.state.hasInputBlured) {
      this.setState({
        hasInputBlured: true,
      });
    }
  };

  onEnter = () => {
    const { enableSubmitOnEnter, submitDataOnEnter, onEnter } = this.props;

    if (enableSubmitOnEnter) {
      submitDataOnEnter();
    }

    if (onEnter) {
      onEnter();
    }
  };

  render() {
    const {
      formValues,
      dataKey,
      showErrors,
      formErrors,
      type,
      errorWarning: pErrorWarning,
      showErrorOnBlur,
      labelDefinationKey,
      icon,
      secondaryLabel,
      formGroupInnerClassName,
      labelClassName,
      inputWrapperClassName,
      isDisabled,
      isErrorPossible,
      ...restProps
    } = this.props;
    const { errorWarning, hasInputBlured, customErrorClass } = this.state;

    const showError = (showErrorOnBlur && hasInputBlured) || showErrors;

    return (
      <InputField
        labelDefinationKey={labelDefinationKey}
        type={type}
        icon={icon}
        value={_get(formValues, dataKey, type === "text" ? "" : undefined)}
        error={formErrors[dataKey]}
        hasError={showError && formErrors[dataKey]}
        errorWarning={pErrorWarning || errorWarning}
        onBlur={this.onBlur}
        isErrorPossible={isErrorPossible}
        {...restProps}
        onChange={this.onChangeInput}
        onEnter={this.onEnter}
        secondaryLabel={secondaryLabel}
        formGroupInnerClassName={formGroupInnerClassName}
        labelClassName={labelClassName}
        inputWrapperClassName={inputWrapperClassName}
        customErrorClass={customErrorClass}
        disabled={isDisabled}
      />
    );
  }
}

InputText.defaultProps = {
  dataKey: null,
  title: "",
  resetUndefined: false,
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
  type: "text",
};

export default withTranslation()(InputText);
