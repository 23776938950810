/* eslint-disable no-nested-ternary */
import React from "react";
import { Trans } from "react-i18next";

import classnames from "classnames";

import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text } from "@onlinesales-ai/label-v2";

import "./index.less";

const LabelValuePair = ({
  list,
  spaceBetween,
  separator,
  isVertical,
  isTooltip,
  sameStyling,
  useText,
  size,
  showEllipsis,
  valueHighLighter,
  isLoading,
  className,
  noMargin,
  itemSeparator,
  labelClassName,
  valueClassName,
  ellipsisLines,
  separatorClass,
  secondaryStyling,
}) => {
  return (
    <>
      {list.map((item) => {
        const { label, value } = item;
        return (
          <div
            className={classnames("label-value-item", size, className, {
              "space-between": spaceBetween,
              "vertical-columns": isVertical,
              "same-styling": sameStyling,
              "value-highlighter": valueHighLighter,
              "secondary-styling": secondaryStyling,
              "my-0": noMargin,
            })}
          >
            <div className={classnames("label", labelClassName)}>
              <Trans>{label}</Trans>
              <div className={classnames("separator", separatorClass)}>{separator}</div>
            </div>
            {isLoading ? (
              <div className="animated-bg value-loader" />
            ) : (
              <>
                {useText ? (
                  <Text
                    className={classnames("value h100p", valueClassName)}
                    showEllipsis={showEllipsis}
                    ellipsisLines={ellipsisLines}
                    tooltipPosition="auto"
                  >
                    {value}
                  </Text>
                ) : isTooltip ? (
                  <WithTooltip title={value}>
                    <div className={classnames("value ellipsis-text", valueClassName)}>{value}</div>
                  </WithTooltip>
                ) : (
                  <div className={classnames("value", valueClassName)}>{value}</div>
                )}
              </>
            )}
            {itemSeparator ? <div className="vertical-separator small-height mx-2" /> : null}
          </div>
        );
      })}
    </>
  );
};

LabelValuePair.defaultProps = {
  spaceBetween: true,
  separator: ":",
  sameStyling: false,
  showEllipsis: true,
  valueHighLighter: false,
  isLoading: false,
  noMargin: false,
  itemSeparator: false,
  ellipsisLines: true,
};

export default LabelValuePair;
