import _uniq from "lodash/uniq";
import appReducer from "@onlinesales-ai/app-v2/application/reducer";
import { setCookie, mergeObject, customMergeOS } from "@onlinesales-ai/util-methods-v2";
import { parseProcessedEntityId, entityTypeEnum, entityTypeEnumForStore } from "@onlinesales-ai/ott-common-v2";
import { COOKIES } from "@onlinesales-ai/constants-v2";

import Types from "./types";

const initialState = {
  entityInfo: {
    clients: {
      // "6": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "CLIENT",
      //   "entityId": 6,
      //   "parentMCCId": 5,
      //   "level": 3,
      //   "name": "Sokrati <> HUL <> Dabur",
      //   "role": "READ"
      // },
      // "7": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "CLIENT",
      //   "entityId": 7,
      //   "parentMCCId": 5,
      //   "level": 3,
      //   "name": "Sokrati <> HUL <> Kissan",
      //   "role": "READ"
      // },
      // "9": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "CLIENT",
      //   "entityId": 9,
      //   "parentMCCId": 8,
      //   "level": 3,
      //   "name": "Nescafe",
      //   "role": "OPERATIONS"
      // },
      // "10": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "CLIENT",
      //   "entityId": 10,
      //   "parentMCCId": 8,
      //   "level": 3,
      //   "name": "Maggi",
      //   "role": "OPERATIONS"
      // }
    },
    mccs: {
      // "2": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "MCC",
      //   "entityId": 2,
      //   "parentMCCId": 1,
      //   "level": 1,
      //   "name": "Sokrati",
      //   "role": "ADMIN",
      //   "undefined": {}
      // },
      // "3": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "MCC",
      //   "entityId": 3,
      //   "parentMCCId": 1,
      //   "level": 1,
      //   "name": "Agency 2",
      //   "role": "OPERATIONS"
      // },
      // "4": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "MCC",
      //   "entityId": 4,
      //   "parentMCCId": 1,
      //   "level": 1,
      //   "name": "Agency 3",
      //   "role": "ANALYST"
      // },
      // "5": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "MCC",
      //   "entityId": 5,
      //   "parentMCCId": 2,
      //   "level": 2,
      //   "name": "Sokrati <> HUL",
      //   "role": "READ"
      // },
      // "8": {
      //   "id": 384652,
      //   "status": "ACTIVE",
      //   "entityType": "MCC",
      //   "entityId": 8,
      //   "parentMCCId": 2,
      //   "level": 2,
      //   "name": "Sokrati <> Nestle",
      //   "role": "OPERATIONS"
      // },
    },
  },
  entityMetadata: {
    clients: {},
    mccs: {},
  },
  selectedEntityId: null,
  selectedEntityType: null,
  userInfo: null,
  entitiesFetchInProgress: true,
  entitiesFetchError: false,
  entitiesFetchState: {
    // parentId1 : {
    //   children: [],
    //   offset: 0,
    //   isAllFetched: false,
    //   isFetchInProgress: false,
    // }
  },
  userFetchInProgress: true,
  userFetchError: false,
  UA_TOKEN: null,
  shopInfo: null,
  isMobile: false,
  pendingOrderDetails: [],
  isInternalUser: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_SET_ENTITY_ID: {
      state = {
        ...state,
        selectedEntityId: action.entityId,
        selectedEntityType: action.entityType,
      };
      setCookie(COOKIES.SELECTED_ENTITY_ID, `${state.selectedEntityType}_${state.selectedEntityId}`, 100 /*Expiry no of days*/);
    }
      break;
    case Types.SET_APP_UPDATE_ENTITY_INFO: {
      state = {
        ...state,
        entityInfo: mergeObject({}, state.entityInfo, action.entityInfoToUpdate),
      };
    }
      break;
    case Types.SET_APP_UPDATE_ENTITY_FETCH_STATE: {
      state = {
        ...state,
        entitiesFetchState: {
          ...state.entitiesFetchState,
          [action.entityId]: action.entitiesFetchStateToUpdate,
        },
      };
    }
      break;
    case Types.SET_APP_ENTITIES_FETCH_IN_PROGRESS: {
      state = {
        ...state,
        entitiesFetchInProgress: action.entitiesFetchInProgress,
      };
    }
      break;
    case Types.INSERT_UPDATE_MCC_CLIENT: {
      const {
        entityInfo: actionEntityInfo,
        entityMetadata: actionEntityMetadata,
      } = action?.mccData || {};
      const storeKey = entityTypeEnumForStore[actionEntityInfo?.entityType];

      const parentIdToUse =
        actionEntityInfo?.parentMCCId ?
          `${entityTypeEnum.MCC}_${actionEntityInfo?.parentMCCId}` :
          `${entityTypeEnum.AGENCY}_${state.agencyId}`;
      state = {
        ...state,
        entitiesFetchState: {
          ...state.entitiesFetchState,
          [parentIdToUse]: {
            ...state.entitiesFetchState[parentIdToUse],
            children: _uniq([
              actionEntityInfo?.entityId,
              ...(state.entitiesFetchState[parentIdToUse]?.children || []),
            ]),
          },
        },
      };

      state = {
        ...state,
        entityInfo: {
          ...state?.entityInfo,
          [storeKey]: {
            ...state?.entityInfo[storeKey],
            [actionEntityInfo?.entityId]: {
              ...state?.entityInfo[storeKey][actionEntityInfo?.entityId],
              ...actionEntityInfo,
            },
          },
        },
      };

      state = {
        ...state,
        entityMetadata: {
          ...state?.entityMetadata,
          [storeKey]: {
            ...state?.entityMetadata[storeKey],
            [actionEntityInfo?.entityId]: {
              ...state?.entityMetadata[storeKey][actionEntityInfo?.entityId],
              ...actionEntityMetadata,
            },
          },
        },
      };
    }
      break;
    case Types.UPSERT_ENTITY_METADATA: {
      const { entityId: combinedEntityId, entityMetadata } = action;
      const { entityId, entityType } = parseProcessedEntityId(combinedEntityId);
      const storeKey = entityType === entityTypeEnum.MCC ? "mccs" : "clients";

      if (entityId && entityType && storeKey) {
        state = {
          ...state,
          entityMetadata: {
            ...state?.entityMetadata,
            [storeKey]: {
              ...state?.entityMetadata?.[storeKey],
              [combinedEntityId]: {
                ...state?.entityMetadata?.[storeKey]?.[combinedEntityId],
                ...entityMetadata,
              },
            },
          },
        };
      }
    }
      break;
    case Types.UPSERT_ENTITY_METADATA_LIST: {
      Object.keys(action.entityMetadata).forEach((combinedEntityId) => {
        const { entityType } = parseProcessedEntityId(combinedEntityId);
        const storeKey = entityType === entityTypeEnum.MCC ? "mccs" : "clients";

        state = {
          ...state,
          entityMetadata: {
            ...state?.entityMetadata,
            [storeKey]: {
              ...state?.entityMetadata[storeKey],
              [combinedEntityId]: {
                ...state?.entityMetadata[storeKey][combinedEntityId],
                ...action.entityMetadata[combinedEntityId],
              },
            },
          },
        };
      });
    }
      break;
    case Types.SET_APP_UPDATE_ENTITY_BILLING_INFO: {
      state = {
        ...state,
        entityBillingInfo: action.entityBillingInfo,
      };
    }
      break;
    default: {
      return appReducer(state, action, initialState);
    }
  }

  return state;
};

export default {
  Application: reducer,
};
