import React, { useEffect, useRef } from "react";

import classnames from "classnames";

import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text, Label } from "@onlinesales-ai/label-v2";

import "./index.less";

const Checkbox = ({
  className,
  onChange,
  inputClass,
  labelClass,
  label,
  subLabel,
  id,
  children,
  tooltip,
  labelDefinationKey,
  disableLabelClick,
  labelProps,
  indeterminate,
  textProps,
  ...props
}) => {
  const checkboxInputRef = useRef();
  useEffect(() => {
    if (checkboxInputRef.current) {
      checkboxInputRef.current.indeterminate = indeterminate;
    }
  }, [checkboxInputRef, indeterminate]);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.checked, event);
    }
  };
  return (
    <WithTooltip title={tooltip}>
      <div
        className={`checkbox-wrapper ${className || ""} ${props.checked ? "selected" : ""} ${
          props.disabled ? "disabled" : ""
        }`}
      >
        {children ? (
          children({
            id,
            inputClass,
            label,
            labelClass,
            checkboxInputRef,
            ...props,
            onChange: handleChange,
          })
        ) : (
          <>
            <input
              id={`checkbox-${id}`}
              type="checkbox"
              className={`os-custom-checkbox ${inputClass}`}
              {...props}
              onChange={handleChange}
              ref={checkboxInputRef}
            />
            <Text
              className={classnames("os-custom-checkbox-label", labelClass, {
                "flex-column align-items-start with-sub-label": subLabel,
              })}
              tagProps={{
                htmlFor: disableLabelClick ? false : `checkbox-${id}`,
              }}
              {...textProps}
            >
              {label || ""}
              {subLabel && (
                <Text
                  type="primary"
                  size="small"
                  weight="regular"
                  className="checkbox-sub-label no-pointer-events"
                >
                  {subLabel}
                </Text>
              )}
            </Text>
            {(labelDefinationKey || labelProps) && (
              <Label
                parentTooltip={tooltip}
                {...labelProps}
                labelDefinationKey={labelDefinationKey}
              />
            )}
          </>
        )}
      </div>
    </WithTooltip>
  );
};

Checkbox.defaultProps = {
  name: "",
  label: "",
  inputClass: "",
  labelClass: "",
};

export default Checkbox;
