import React, { useEffect, useState } from "react";
import { Button } from "@onlinesales-ai/button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import "./index.less";

const SideCollapse = ({
  containerClass,
  withBg,
  direction,
  collapseIcon,
  maincontent,
  leftContent,
  controlled,
  isOpen,
  setIsOpen,
  isShowCloseButton,
  hideLeftSectionOnOpen,
  isFixed,
  collapseOpenTooltip,
  collapseCloseTooltip,
  collapseTooltipPlacement,
  isShowCollapseIcon,
}) => {
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    if (controlled) {
      setShow(isOpen);
    }
  }, [isOpen]);

  const onChange = controlled ? setIsOpen : setShow;

  return (
    <div
      className={`side-collapse-container ${containerClass} ${
        withBg ? "with-background" : ""
      } ${direction} ${isFixed ? "fixed" : ""}`}
    >
      {!(show && hideLeftSectionOnOpen) && (
        <div className="side-tabs">
          {isShowCollapseIcon && (
            <WithTooltip
              title={isOpen ? collapseOpenTooltip : collapseCloseTooltip}
              placement={collapseTooltipPlacement}
              enableBoxShadow
            >
              <Button
                onClick={() => {
                  onChange(!show);
                }}
                aria-controls="collapse-button"
                className="collapsible-button"
                aria-expanded={show}
                link
              >
                {collapseIcon ? (
                  <div>{collapseIcon}</div>
                ) : (
                  <span
                    className={`icon ${
                      direction === "left" ? "icon-angle-right" : "icon-angle-left"
                    }`}
                  />
                )}
              </Button>
            </WithTooltip>
          )}
          {leftContent && <div className="left-content">{leftContent()}</div>}
        </div>
      )}
      <div className={`side-tab-content ${show ? "show" : ""}`}>
        <div className="side-tab-content-inner">
          {maincontent()}
          {isShowCloseButton && (
            <span
              className="icon icon-close1 close-icon"
              onClick={() => {
                onChange(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SideCollapse.defaultProps = {
  withBg: true,
  isShowCloseButton: false,
  hideLeftSectionOnOpen: false,
  isShowCollapseIcon: true,
  direction: "left", // right
};

export default SideCollapse;
