import React, { useState, useEffect, useContext } from "react";

import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { DropdownVirtualized } from "@onlinesales-ai/form-components-v2";
import { InceptionService } from "@onlinesales-ai/services-v2";

const IndustrySelection = ({
  dataKey,
  fetchCatergoryData,
  formValues,
  isEditable,
  onChange,
  nameDataKey,
  ...props
}) => {
  const { showToastMessage } = useContext(GlobalContext);

  const [catergoryFetchInProgress, setCatergoryFetchInProgress] = useState(false);
  const [catergoryOptions, setCatergoryOptions] = useState([]);

  const fetchCatergoryOptions = async () => {
    setCatergoryFetchInProgress(true);

    try {
      const response = await InceptionService.fetchCategoryList({});

      const optionsToSet = response?.data?.map((category) => {
        return {
          label: category.name,
          value: `${category.id}`,
        };
      });
      setCatergoryOptions(optionsToSet);
      setCatergoryFetchInProgress(false);
    } catch (err) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: "Error occured while fetching list of industry.",
        actionButtonLabel: null,
        toastDuration: 5000,
      });
      setCatergoryFetchInProgress(false);
    }
  };

  useEffect(() => {
    fetchCatergoryOptions();
  }, []);

  const onChangeValue = (val) => {
    const industryValue = catergoryOptions?.find?.((c) => c.value === val[dataKey]);

    onChange({
      ...val,
      [nameDataKey]: industryValue?.label || "",
    });
  };

  return (
    <DropdownVirtualized
      {...props}
      formValues={formValues}
      isEditable={isEditable}
      dataKey={dataKey}
      options={catergoryOptions}
      isLoading={catergoryFetchInProgress}
      onChange={onChangeValue}
    />
  );
};

IndustrySelection.defaultProps = {
  onError: () => {},
  formErrors: [],
};

export default IndustrySelection;
