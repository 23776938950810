import React from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";
import { CheckboxGroup } from "@onlinesales-ai/checkbox-v2";

import FormWrapper from "../../FormWrapper";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValue = nextProps.getValue
      ? nextProps.getValue(nextProps)
      : _get(nextProps.formValues, nextProps.dataKey);

    if (!_isEqual(newValue, this.getValue())) {
      this.validate(newValue);
    }
  }

  getValue = () => {
    const { dataKey, formValues, getValue } = this.props;

    if (getValue) {
      return getValue(this.props);
    }

    return _get(formValues, dataKey);
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (_isEmpty(value)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChangeCheckbox = (value) => {
    const { onChange, dataKey } = this.props;
    onChange({
      [dataKey]: value,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      options,
      name,
      layout,
      nonEditableComponent,
      renderNonEditableChildren,
      isTabsEnabled,
      noOptionMessage,
      isRequired,
      checkboxRender,
      showContentWhildReadonly,
      idPrefix,
      size,
      doNotSetToLocalState,
      labelDefinationKey,
      errorClassName,
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        formGroupClassName={formGroupClassName}
        labelColumns={labelColumns}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        isRequired={isRequired}
        labelDefinationKey={labelDefinationKey}
        errorClassName={errorClassName}
      >
        {isEditable || showContentWhildReadonly ? (
          <CheckboxGroup
            idPrefix={idPrefix}
            name={name}
            groupValue={value}
            data={options}
            onChange={this.onChangeCheckbox}
            disabled={!isEditable || disabled}
            layout={layout}
            isTabsEnabled={isTabsEnabled}
            noOptionMessage={noOptionMessage}
            checkboxRender={checkboxRender}
            size={size}
            doNotSetToLocalState={doNotSetToLocalState}
          />
        ) : (
          <div className="non-edit-mode-text">
            {nonEditableComponent || (
              renderNonEditableChildren ? renderNonEditableChildren(value) : null
            ) || (
              value?.length ? value.join(", ") : ""
            )}
          </div>
        )}
      </FormWrapper>
    );
  }
}

Checkbox.defaultProps = {
  name: "form-checkbox",
  dataKey: null,
  title: "",
  labelColumns: 3,
  showContentWhildReadonly: false,
  formGroupClassName: "form-component-checkbox",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(Checkbox);
