import Types from "./types";

const initialState = {
  wallet: {
    walletBalance: 0,
    formattedWalletBalance: 0,
    formattedOutstandingBalance: 0,
    formattedCreditLimit: 0,
  },
  isWalletLoading: false,
  walletFetchError: null,
  walletFetchErrorCode: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_SET_ENTITY_ID: {
      state = {
        ...initialState,
      };
    }
      break;
    case Types.UPDATE_WALLET_DATA:
      {
        state = {
          ...state,
          wallet: {
            ...(state.wallet || {}),
            ...action.data,
          },
        };
      }
      break;
    case Types.SET_WALLET_FETCH_STATE:
      {
        state = {
          ...state,
          isWalletLoading: action.isLoading,
          walletFetchError: action.fetchError,
          walletFetchErrorCode: action.errorCode,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Billing: reducer,
};
