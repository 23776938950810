export default {
  APP_SET_ENTITY_ID: "APP_SET_ENTITY_ID",
  SET_APP_UPDATE_ENTITY_INFO: "SET_APP_UPDATE_ENTITY_INFO",
  SET_APP_UPDATE_ENTITY_FETCH_STATE: "SET_APP_UPDATE_ENTITY_FETCH_STATE",
  SET_APP_ENTITIES_FETCH_IN_PROGRESS: "SET_APP_ENTITIES_FETCH_IN_PROGRESS",
  INSERT_UPDATE_MCC_CLIENT: "INSERT_UPDATE_MCC_CLIENT",
  UPSERT_ENTITY_METADATA: "UPSERT_ENTITY_METADATA",
  UPSERT_ENTITY_METADATA_LIST: "UPSERT_ENTITY_METADATA_LIST",
  SET_DOMAIN_CONFIG: "SET_DOMAIN_CONFIG",
  SET_APP_UPDATE_ENTITY_BILLING_INFO: "SET_APP_UPDATE_ENTITY_BILLING_INFO",
};
